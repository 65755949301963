import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import NavBar from "../components/NavBar";

import { NavigateRoutes } from "../constants/routes";

import {
  tokenIsValid,
  getTokenExpirationDate,
  decode
} from "../utils/token";

import {  
  submitHelpRequest 
} from "../api/Help";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import { Button } from "react-bootstrap";

import Popup from 'reactjs-popup';

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const HelpPage = () => {

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const [formInfo, setFormInfo] = useState();

  // Hooks
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);
  const [requestQueued, setRequestQueued] = useState(false);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // state - store user data from cookie
  const [userData, setUserData] = useState();

  // state - track changes
  const [changeError] = useState(false);
  const [changeErrorMessage] = useState("");

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  // PREVIOUS VERSION OF FORMINFO
  // Form information
  // const formInfo = {
  //   "training": "GAV Training",
  //   "day_to_meet": getNextTwoWeeks()[0],
  //   "time_to_meet": "00:00 am"
  // }

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Profile";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();

      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - (1 * 10 * 1000)
      );

      const blankForm = () => {
        const item = {
          "subject": ""
        };
    
        return item;
      };

      setAuthTimeout(newTimeout);

      // load the user data and changed user data for future changes
      const decoded = decode(cookies["token"]);
      setUserData(decoded);
      
      const blank = blankForm();

      setFormInfo(blank);

      setPageDoneLoading(true);
    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Handler for updating the changedUserData and changesDetected state objects
   * @param {string} attribute
   * @param {*} value
   */
  const handleChangeUser = (attribute, value) => {
    console.log("Change has been made to");
    console.log(attribute);
    console.log(value);

    // PREVIOUS VERSION
    // formInfo[attribute] = value;

    const changes = structuredClone(formInfo);
    changes[attribute] = value;
    setFormInfo(changes);
  };

  const popupConfirmation = () => (
    <Popup trigger={<button> Trigger</button>} position="right center">
      <div>Popup content here !!</div>
    </Popup>
  );

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleSubmitChanges = () => {
    // This is where we need to record the information that
    // the user submitted for the form to then send the email

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Review your help issue:
      </h5>,
    ];

    newModalBody.push(<h5 key="training"><b>Subject:</b> {formInfo.subject}</h5>);
    newModalBody.push(<h5 key="header"><b>Message:</b> {formInfo.message}</h5>);

    setModalHeader("Confirm Entry");
    setModalBody(newModalBody)
    setModalBtnText("Confirm");
    setModalShow(true);
    setRequestQueued(true);
  };

  // const dummy = () => {
  //   console.log("I HAVE BEEN PRESSED");
  //   helperServerFunction();
  // };

  const handleConfirm = () => {
    console.log("new item");
    console.log(formInfo);
    console.log(formInfo.training);
    console.log(formInfo.day_to_meet);
    console.log(formInfo.time_to_meet);

    setShowModalClose(false);

    // TODO
    // Make sure to catch any errors
    submitHelpRequest(userData, formInfo)
      .then((res) => { 
                      // setTimeout(refreshPage, 2000); 
                      console.log("About to call popupConfirmation")
                      popupConfirmation();
                    })
      .catch((err) => console.log(err));
    
    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Help Issue has been successfully created.
      </p>
      <p>This page will now redirect to the home screen in five (5) seconds.</p>
    </Container>
    );
    setModalBtnText("Close");
    setTimeout(redirectHome, 5000);
    setModalShow(true);
    setShowModalClose(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Help Page</h1>
            <h4>If you encounter an issue, please let us know by filling out the form below!</h4>
            <h4>Please make sure to include the following things in your form:</h4>
            <div align="left">
              <p>
                <ol>
                  <li>What you were trying to do.</li>
                  <li>How the program responded.</li>
                  <li>What type of browser you're on (Chrome, Safari, etc).</li>
                  <li>As specific of details of what you did. (Outlining it in steps is most appreciated)</li>
                </ol>
              </p>
            </div>
            <h4>We will do our best to address the issue as quickly as we can. If necessary, we may reach out to you concerning the issue.</h4>
            <h4>Thank you so much for participating in helping improve our platform!</h4>
            <hr height={5} background-color={"black"} />
          </Container>
          <Container className={styles.sectionContainer}>
            {userData && (
              <Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Subject
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        handleChangeUser("subject", e.target.value)
                      }
                      className={styles.help}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Message
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                  <Container>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      onChange={(e) =>
                        handleChangeUser("message", e.target.value)
                      }
                      className={styles.help}
                    />
                  </Container>
                  </Container>
                </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="warning"
                  onClick={handleSubmitChanges}
                >
                  Submit Help Issue
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      backdrop={showModalClose ? undefined : "static"}
    >
      <Modal.Header closeButton={showModalClose}>
          <Modal.Title>{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {showModalClose && (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={
                requestQueued ? handleConfirm : () => setModalShow(false)
              }
            >
              {modalBtnText}
            </Button>
          </Modal.Footer>
        )}
    </Modal>
    </div>
  );
};

export default HelpPage;