import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";

import NavBar from "../components/NavBar";

import { AiOutlinePlus } from "react-icons/ai";
import { IoTrashBin } from "react-icons/io5";

import { TrainingOpt } from "../constants/scheduler.js";

import { NavigateRoutes } from "../constants/routes";

import {
  tokenIsValid,
  getTokenExpirationDate,
  decode
} from "../utils/token";

import { 
  submitEditSchedule 
} from "../api/Scheduler";

import { 
  getTraineeEventInfo,
  getTrainerEventInfo
} from "../api/Hub";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import redcap_styles from "../apps/redcap-perms/styles/Main.module.css";

import { Button } from "react-bootstrap";

import Popup from 'reactjs-popup';

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const EditPage = () => {
  // Need to connect it to some kind of state:
  // do so here:
  // https://www.robinwieruch.de/react-checkbox/
  const getNextTwoWeeks = (first_item) => {
    const dates = [];

    if(first_item){
      dates[0] = first_item;
    }

    var num_weeks = 3;

    var today = new Date();

    for(var i = 1; i <= 7 * num_weeks; i++){
        console.log(today.getMonth())

        var cur_month = today.getMonth() + 1
        var str_month = cur_month.toString()

        var cur_day = today.getDate().toString();
        var cur_year = today.getFullYear().toString();
        
        dates[i] = str_month.concat("/", cur_day, "/", cur_year);
        today.setDate(today.getDate() + 1);
    }

    return dates
  };

  const get24Hours = () => {
    const times = [];
    let idx = 0;
    for(var i = 0; i < 24; i++){
      for(var j = 0; j < 2; j++){
          let temp = i;
          let hour = "";
          let time_of_day = "am";
          let minutes = "00";

          if(j === 1){
            minutes = "30";
          }
    
          if(i > 12){
            time_of_day = "pm";
            temp -= 12;
          } else if (i === 12){
            time_of_day = "pm";
          }
    
          if(temp < 10){
            hour = "0".concat(temp.toString());
          } else {
            hour = temp.toString();
          }
    
          times[idx] = hour.concat(":", minutes, " ", time_of_day);
          idx += 1;
        }
      }

    return times;
  };

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  // static method - returns a new user entry corresponding to a row
  const getNewUserEmpty = () => {
    return {
      day: getNextTwoWeeks(null)[0],
      time: get24Hours()[0],
    };
  };

  const getNewUserEmptyTraining = () => {
    return {
      training: TrainingOpt["GAV"]
    };
  };

  const handleRowEnterDay = (e, id) => {
    // update the data object
    const currNewUserData = userDataMap;
    currNewUserData[id].day = e.target.value;
    setUserDataMap(currNewUserData);
  };

  const handleRowEnterTime = (e, id) => {
    // update the data object
    const currNewUserData = userDataMap;
    currNewUserData[id].time = e.target.value;
    setUserDataMap(currNewUserData);
  };

  const handleRowEnterTraining = (e, id) => {
    // update the data object
    const currNewUserData = userDataMapTraining;
    currNewUserData[id].training = e.target.value;
    setUserDataMapTraining(currNewUserData);
  };

  const handleAddRow = () => {
    const lastRowMap = tableRowMap;
    const counter = tableRowCounter;

    const newRow = (
      <EmptyRow key={"row_" + counter} id={counter} day={getNextTwoWeeks(null)[0]} time={get24Hours()[0]} />
    );
    lastRowMap[counter] = newRow;
    setTableRowMap(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplay(newRowsToDisplay);

    const currNewUserData = userDataMap;
    currNewUserData[counter] = getNewUserEmpty();
    setUserDataMap(currNewUserData);

    setTableRowCounter(counter + 1);
  };

  const handleAddRowTraining = () => {
    console.log("IN HANDLE AddRowTraining");
    const lastRowMap = tableRowMapTraining;
    const counter = tableRowCounterTraining;

    const newRow = (
      <EmptyRowTraining key={"row_" + counter} id={counter} training={TrainingOpt["GAV"]} options={TrainingOpt}/>
    );
    lastRowMap[counter] = newRow;
    setTableRowMapTraining(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplayTraining(newRowsToDisplay);

    const currNewUserData = userDataMapTraining;
    currNewUserData[counter] = getNewUserEmptyTraining();
    setUserDataMapTraining(currNewUserData);

    setTableRowCounterTraining(counter + 1);
  };

  const handleRemoveRow = (id) => {
    // remove entries in newUserData
    const lastRowMap = tableRowMap;
    const lastNewData = userDataMap;
    delete lastNewData[id];
    setUserDataMap(lastNewData);

    // remove entry in tableRowMap
    delete lastRowMap[id];
    setTableRowMap(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplay(newRowsToDisplay);
  };

  const handleRemoveRowTraining = (id) => {
    console.log("IN HANDLE RemoveRowTraining");
    // remove entries in newUserData
    const lastRowMap = tableRowMapTraining;
    const lastNewData = userDataMapTraining;
    delete lastNewData[id];
    setUserDataMapTraining(lastNewData);

    // remove entry in tableRowMap
    delete lastRowMap[id];
    setTableRowMapTraining(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplayTraining(newRowsToDisplay);
  };

  const EmptyRow = (props) => (
    <tr key={props.id}>
      <td>
        <div>
          <Form.Select value={props.selected_day} onChange={(e) => handleRowEnterDay(e, props.id)}>
              {Object.values(getNextTwoWeeks(props.selected_day)).map((opt) => (
                <option key={"day_to_meet_" + opt}>{opt}</option>
              ))}
          </Form.Select>
        </div>
      </td>
      <td>
        <div>
          <Form.Select value={props.selected_time} onChange={(e) => handleRowEnterTime(e, props.id)}>
            {Object.values(get24Hours()).map((opt) => (
              <option key={"time_to_meet_" + opt}>{opt}</option>
            ))}
          </Form.Select>
        </div>
      </td>
      <td>
        {!props.first && (
          <div>
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleRemoveRow(props.id)}
            >
              <IoTrashBin />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );

  const EmptyRowTraining = (props) => (
    <tr key={props.id}>
      <td>
        <div>
          <Form.Select value={props.selected_train} onChange={(e) => handleRowEnterTraining(e, props.id)}>
              {Object.values(TrainingOpt).map((opt) => (
                <option key={"training_opt_" + opt}>{opt}</option>
              ))}
          </Form.Select>
        </div>
      </td>
      <td>
        {!props.first && (
          <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => handleRemoveRowTraining(props.id)}
            >
              <IoTrashBin />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );

  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [eventID] = useState(queryParams.get("event"));
  const [source] = useState(queryParams.get("source"));

  const [formInfo, setFormInfo] = useState();

  // Hooks for table
  const [userDataMap, setUserDataMap] = useState({});

  const [userDataMapTraining, setUserDataMapTraining] = useState({});

  const [tableRowMap, setTableRowMap] = useState({}); // keeps track of display components for adding/removing
  const [tableRowMapTraining, setTableRowMapTraining] = useState({}); // keeps track of display components for adding/removing

  const [tableRowsToDisplay, setTableRowsToDisplay] = useState([]); // actual display components
  
  const [tableRowCounter, setTableRowCounter] = useState(0); // use to keep track of all new rows created (only increment)

  const [tableRowsToDisplayTraining, setTableRowsToDisplayTraining] = useState([]); // actual display components
  const [tableRowCounterTraining, setTableRowCounterTraining] = useState(0); // use to keep track of all new rows created (only increment)

  // Hooks
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);
  const [requestQueued, setRequestQueued] = useState(false);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // state - store user data from cookie
  const [userData, setUserData] = useState();

  // state - track changes
  const [changeError] = useState(false);
  const [changeErrorMessage] = useState("");

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Profile";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }


    if(source === "trainee"){
      getTraineeEventInfo(eventID).then(
        (res) => {
  
          console.log(source);
          console.log(typeof source);
  
          const rowMap = {};
          const rowMap_v2 = {};
          for (const [key, value] of Object.entries(res.data["day_times"])) {
            if(!value){
              continue
            }
  
            rowMap_v2[parseInt(key)] = {day: value["day"], time: value["time"]}
  
            if(parseInt(key) === 0){
              rowMap[key] = <EmptyRow key={"row_day_time_start"} first id={0} selected_day={value["day"]} selected_time={value["time"]} />
            } else {
              rowMap[key] = <EmptyRow key={"row_day_time"+key} id={key} selected_day={value["day"]} selected_time={value["time"]} />
            }
          }
          
          setUserDataMap(rowMap_v2);
  
          setTableRowMap(rowMap);
          setTableRowsToDisplay(Object.values(rowMap));
          
          const rowMapTraining = {};
          const rowMapTraining_v2 = {};
          
          for (const [key, value] of Object.entries(res.data["training_type"])) {
            if(!value){
              continue
            }
  
            rowMapTraining_v2[parseInt(key)] = {training: value}
  
            if(parseInt(key) === 0){
              rowMapTraining[key] = <EmptyRowTraining key={"row_training_start"} first id={0} selected_train={value} />
            } else {
              rowMapTraining[key] = <EmptyRowTraining key={"row_training"+key} id={key} selected_train={value} />
            }
          }
  
          setUserDataMapTraining(rowMapTraining_v2);
  
          setTableRowMapTraining(rowMapTraining);
          setTableRowsToDisplayTraining(Object.values(rowMapTraining));
  
          setTableRowCounter(res.data["day_times"].length);
          setTableRowCounterTraining(res.data["training_type"].length);
  
          setPageDoneLoading(true);
      });
    } else {
      getTrainerEventInfo(eventID).then(
        (res) => {
  
          console.log(source);
          console.log(typeof source);
  
          const rowMap = {};
          const rowMap_v2 = {};
          for (const [key, value] of Object.entries(res.data["day_times"])) {
            if(!value){
              continue
            }
  
            rowMap_v2[parseInt(key)] = {day: value["day"], time: value["time"]}
  
            if(parseInt(key) === 0){
              rowMap[key] = <EmptyRow key={"row_day_time_start"} first id={0} selected_day={value["day"]} selected_time={value["time"]} />
            } else {
              rowMap[key] = <EmptyRow key={"row_day_time"+key} id={key} selected_day={value["day"]} selected_time={value["time"]} />
            }
          }
          
          setUserDataMap(rowMap_v2);
  
          setTableRowMap(rowMap);
          setTableRowsToDisplay(Object.values(rowMap));
          
          const rowMapTraining = {};
          const rowMapTraining_v2 = {};
          
          for (const [key, value] of Object.entries(res.data["training"])) {
            if(!value){
              continue
            }
  
            rowMapTraining_v2[parseInt(key)] = {training: value}
  
            if(parseInt(key) === 0){
              rowMapTraining[key] = <EmptyRowTraining key={"row_training_start"} first id={0} selected_train={value["training"]} />
            } else {
              rowMapTraining[key] = <EmptyRowTraining key={"row_training"+key} id={key} selected_train={value["training"]} />
            }
          }
  
          setUserDataMapTraining(rowMapTraining_v2);
  
          setTableRowMapTraining(rowMapTraining);
          setTableRowsToDisplayTraining(Object.values(rowMapTraining));
  
          setTableRowCounter(res.data["day_times"].length);
          setTableRowCounterTraining(res.data["training"].length);
  
          setPageDoneLoading(true);
      });
    }

    

    // const newRow = {
    //   0: <EmptyRow key={"row_start"} first id={0} day={getNextTwoWeeks()[0]} time={get24Hours()[0]} />
    // };
    
    // const newRowTraining = {
    //   0: <EmptyRowTraining key={"row_start_training"} first id={0} training={TrainingOpt["GAV"]}/>
    // };
    
    // setTableRowMap(newRow);
    // setTableRowMapTraining(newRowTraining);

    // const newRowsToDisplay = Object.values(newRow);
    // setTableRowsToDisplay(newRowsToDisplay);

    // const newRowsToDisplayTraining = Object.values(newRowTraining);
    // setTableRowsToDisplayTraining(newRowsToDisplayTraining);

    // setTableRowCounter(1);
    // setTableRowCounterTraining(1);

    // setPageDoneLoading(true);

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      console.log(now);
      console.log(exp);
      console.log(exp - now);
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - (1 * 10 * 1000)
      );

      const blankForm = () => {
        const item = {
          "training": TrainingOpt["GAV"]
        };
    
        return item;
      };

      setAuthTimeout(newTimeout);

      // load the user data and changed user data for future changes
      const decoded = decode(cookies["token"]);
      setUserData(decoded);
      
      const blank = blankForm();

      setFormInfo(blank);

    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Handler for updating the changedUserData and changesDetected state objects
   * @param {string} attribute
   * @param {*} value
   */
  const handleChangeUser = (attribute, value) => {
    console.log("Change has been made to");
    console.log(attribute);
    console.log(value);

    // PREVIOUS VERSION
    // formInfo[attribute] = value;

    const changes = structuredClone(formInfo);
    changes[attribute] = value;
    setFormInfo(changes);
  };

  const popupConfirmation = () => (
    <Popup trigger={<button> Trigger</button>} position="right center">
      <div>Popup content here !!</div>
    </Popup>
  );

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleSubmitChanges = () => {
    // This is where we need to record the information that
    // the user submitted for the form to then send the email

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Review your changes before submitting Event:
      </h5>,
    ];

    newModalBody.push(<h5 key="training"><b>Training(s):</b></h5>);

    for (const [idx, entry] of Object.entries(userDataMapTraining)){
      newModalBody.push(
        <h6 key = {"training_data_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"training_" + idx}>
              {entry.training}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
    }

    newModalBody.push(<h5 key="header"><b>Day(s) and Time(s) to Meet (in CST)</b></h5>);
    // newModalBody.push(<h5 key="day_to_meet"><b>Day to Meet:</b> {formInfo.day_to_meet}</h5>);
    // newModalBody.push(<h5 key="time_to_meet"><b>Time to Meet (CST):</b> {formInfo.time_to_meet}</h5>);

    for (const [idx, entry] of Object.entries(userDataMap)){
      console.log(idx);
      console.log(entry);
      newModalBody.push(
        <h6 key = {"day_time_data_" - idx}>
          <ListGroup>
            <ListGroup.Item key={"day_time_" + idx}>
              {entry.day + " " + entry.time}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
      // newModalBody.push(<h5 key={"time_to_meet_" + idx}><b>Time to Meet (CST):</b> {entry.time}</h5>);
      // console.log("Day:", entry.day);
      // console.log("Time:", entry.time);
    }

    setModalHeader("Confirm Entry");
    setModalBody(newModalBody)
    setModalBtnText("Confirm");
    setModalShow(true);
    setRequestQueued(true);
  };

  const handleConfirm = () => {

    formInfo["day_times"] = new Array(tableRowCounter);
    formInfo["training"] = new Array(tableRowCounterTraining)

    for (const [idx, entry] of Object.entries(userDataMap)){
      formInfo["day_times"][idx] = entry
    }

    for (const [idx, entry] of Object.entries(userDataMapTraining)){
      formInfo["training"][idx] = entry
    }

    console.log("Final FormInfo");
    console.log(formInfo)


    setShowModalClose(false);

    // TODO
    // Make sure to catch any errors
    submitEditSchedule(userData, formInfo, eventID, source)
      .then((res) => { 
                      // setTimeout(refreshPage, 2000); 
                      console.log("About to call popupConfirmation")
                      popupConfirmation();
                    })
      .catch((err) => console.log(err));
    
    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Training Event has been successfully created.
      </p>
      <p>This page will now redirect to the home screen in five (5) seconds.</p>
    </Container>
    );
    setModalBtnText("Close");
    setTimeout(redirectHome, 5000); // TODO change back
    setModalShow(true);
    setShowModalClose(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Availability Editting Page</h1>
            <p>The following is a page to allow you to modify an availability you have submitted.</p>
          </Container>
          <Container className={styles.sectionContainer}>
            {userData && (
              <Container>
                
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={userData.name}
                      onChange={(e) => handleChangeUser("name", e.target.value)}
                    />
                  </Container>
                </Container>

                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={userData.email}
                      onChange={(e) =>
                        handleChangeUser("email", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>UT EID</Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={userData.uteid}
                      onChange={(e) =>
                        handleChangeUser("uteid", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <Container className={redcap_styles.container}>
                <Container className={redcap_styles.functionContainer}>
                  <h4>Select the Trainings you are interested in</h4>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Training</th>
                      </tr>
                    </thead>
                    <tbody>{tableRowsToDisplayTraining}</tbody>
                    <tfoot>
                    <tr>
                      <td colSpan={3}>
                        <div>
                          <Button
                            size="sm"
                            variant="dark"
                            className={redcap_styles.addRowBtn}
                            onClick={handleAddRowTraining}
                          >
                            <AiOutlinePlus />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                  </Table>
                </Container>
              </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={redcap_styles.container}>
              <Container className={redcap_styles.functionContainer}>
                <h4>Select Day and Time to meet</h4>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Day to Meet</th>
                      <th>Time to Meet (in CST)</th>
                    </tr>
                  </thead>
                  <tbody>{tableRowsToDisplay}</tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <div>
                        <Button
                          size="sm"
                          variant="dark"
                          className={redcap_styles.addRowBtn}
                          onClick={handleAddRow}
                        >
                          <AiOutlinePlus />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                </Table>
              </Container>
            </Container>
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="warning"
                  onClick={handleSubmitChanges}
                >
                  Submit Availability
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      backdrop={showModalClose ? undefined : "static"}
    >
      <Modal.Header closeButton={showModalClose}>
          <Modal.Title>{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {showModalClose && (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={
                requestQueued ? handleConfirm : () => setModalShow(false)
              }
            >
              {modalBtnText}
            </Button>
          </Modal.Footer>
        )}
    </Modal>
    </div>
  );
};

export default EditPage;