import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

// bootstrap imports
import Container from "react-bootstrap/Container";

import { Button } from "react-bootstrap";

import { Pagination } from "react-bootstrap"

// app-specific
import Table from "../components/Table";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from "react-bootstrap/ListGroup";
import NavBar from "../components/NavBar";
import { NavigateRoutes } from "../constants/routes";
import { DateOptions, DateLocale } from "../constants/dates";

import { 
  IoTrashBin, 
  IoInformationCircleOutline,
  IoPencilSharp 
  } from "react-icons/io5";

import { authorize } from "../api/Login";

import { TrainingOpt } from "../constants/scheduler.js";

// utils
import {
  tokenIsValid,
  decode, 
  getTokenExpirationDate 
} from "../utils/token";

import { 
  getTraineeLogs,
  getTrainerLogs,
  getConfirmedTrainings,
  getSchedulerStatus,
  getSchedulerConfirm,
  getSchedulerTrainer,
  getSchedulerTrainee,
  getTrainerTrainee,
  getTraineeTrainer,
  cancelEvent
} from "../api/Hub.js"

import {
  getIsTrainer
} from "../api/Trainer.js"


// custom page styling
import styles from "../styles/Admin.module.css";

/**
 * Page component for displaying the Admin page
 * @returns React.FC
 */
const HubPage = () => {
  // state - track new users
  const [isScheduler, setIsScheduler] = useState(false);
  const [isTrainer, setIsTrainer] = useState(false);
  const [getTrainerInfo, setGetTrainerInfo] = useState(false);

  const [schedulerStatusUpdated, setSchedulerStatusUpdated] = useState(false);
  
  // state - confirm logs
  const [confirmLogs, setConfirmLogs] = useState([]);
  const [confirmLogsLoading, setConfirmLogsLoading] = useState(false);
  const [confirmLogsCurPage, setConfirmLogsCurPage] = useState(1);
  const [confirmLogsTotalPage, setConfirmLogsTotalPage] = useState();

  const [traineeLogs, setTraineeLogs] = useState([]);
  const [traineeLogsLoading, setTraineeLogsLoading] = useState(false);
  const [traineeLogsCurPage, setTraineeLogsCurPage] = useState(1);
  const [traineeLogsTotalPage, setTraineeLogsTotalPage] = useState();
  
  const [trainerLogs, setTrainerLogs] = useState([]);
  const [trainerLogsLoading, setTrainerLogsLoading] = useState(false);
  const [trainerLogsCurPage, setTrainerLogsCurPage] = useState(1);
  const [trainerLogsTotalPage, setTrainerLogsTotalPage] = useState();

  const [trainerTrainee, setTrainerTrainee] = useState([]);
  const [trainerTraineeLoading, setTrainerTraineeLoading] = useState(false);
  const [trainerTraineeCurPage, setTrainerTraineeCurPage] = useState(1);
  const [trainerTraineeTotalPage, setTrainerTraineeTotalPage] = useState();

  const [traineeTrainer, setTraineeTrainer] = useState([]);
  const [traineeTrainerLoading, setTraineeTrainerLoading] = useState(false);
  const [traineeTrainerCurPage, setTraineeTrainerCurPage] = useState(1);
  const [traineeTrainerTotalPage, setTraineeTrainerTotalPage] = useState();
  
  const [traineeLoading, setTraineeLoading] = useState(false);
  
  // state - confirm logs scheduler
  const [schedulerConfirm, setSchedulerConfirm] = useState([]);
  const [schedulerConfirmLoading, setSchedulerConfirmLoading] = useState(false);
  const [schedulerConfirmCurPage, setSchedulerConfirmCurPage] = useState(1);
  const [schedulerConfirmTotalPage, setSchedulerConfirmTotalPage] = useState();
  const [schedulerConfirmFilters, setSchedulerConfirmFilters] = useState([]);


  const [schedulerTrainer, setSchedulerTrainer] = useState([]);
  const [schedulerTrainerLoading, setSchedulerTrainerLoading] = useState(false);
  const [schedulerTrainerCurPage, setSchedulerTrainerCurPage] = useState(1);
  const [schedulerTrainerTotalPage, setSchedulerTrainerTotalPage] = useState();
  const [schedulerTrainerFilters, setSchedulerTrainerFilters] = useState([]);


  const [schedulerTrainee, setSchedulerTrainee] = useState([]);
  const [schedulerTraineeLoading, setSchedulerTraineeLoading] = useState(false);
  const [schedulerTraineeCurPage, setSchedulerTraineeCurPage] = useState(1);
  const [schedulerTraineeTotalPage, setSchedulerTraineeTotalPage] = useState();
  const [schedulerTraineeFilters, setSchedulerTraineeFilters] = useState([]);

  const [schedulerLoading, setSchedulerLoading] = useState(false);

  // state - get user info
  const [userData, setUserData] = useState();

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [reauthorized, setReauthorized] = useState(false);
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // Modal information
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(false);
  const [schedulerCancel, setSchedulerCancel] = useState(false);

  const [eventToDelete, setEventToDelete] = useState("");
  const [database, setDatabase] = useState("");

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const handleCancellationScheduler = () => {
    cancelEvent(eventToDelete, database, userData)
      .then((res) => {
        console.log("Got the following response");
        console.log(res)
      })
      .catch((err) => console.log(err));
    
    let id_to_delete = -1;

    for (const [idx, entry] of Object.entries(schedulerConfirm)){
      console.log(idx);
      console.log(entry);

      if (entry.event_id === eventToDelete){
        id_to_delete = idx;
        break;
      }
    }

    const tempItems = schedulerConfirm;
    delete tempItems[id_to_delete];
    setSchedulerConfirm(tempItems);

    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Event has been successfully cancelled.
      </p>
    </Container>
    );
    
    setSchedulerCancel(true);
    setModalShow(true);
    setShowModalClose(false);
  }

  const handleCancellation = () => {
    cancelEvent(eventToDelete, database, userData)
      .then((res) => {
        console.log("Got the following response");
        console.log(res)
      })
      .catch((err) => console.log(err));
    
    let id_to_delete = -1;
    for (const [idx, entry] of Object.entries(confirmLogs)){
      console.log(idx);
      console.log(entry);

      if (entry.event_id === eventToDelete){
        id_to_delete = idx;
        break;
      }
    }

    const tempItems = confirmLogs;
    delete tempItems[id_to_delete];
    setConfirmLogs(tempItems);

    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Event has been successfully cancelled.
      </p>
    </Container>
    );
    
    setSchedulerCancel(false);
    setModalShow(true);
    setShowModalClose(false);
  };

  const cancelConfirmation = (info) => {
    console.log("IN DELETE CONFIRMATION");
    console.log(info);
    
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Double Check information before submitting the Cancellation.
      </h5>,
    ];
 
    newModalBody.push(<h5 key="Trainer_Info"><b>Trainer Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainer Name:&nbsp;</b></td>
          <td>{info.trainer.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainer.email}</td>
        </tr>
      </table>
    );

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Trainee_Info"><b>Trainee Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainee Name:&nbsp;</b></td>
          <td>{info.trainee.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainee.email}</td>
        </tr>
      </table>
    );

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event_Info"><b>Event Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Day to Meet: </b></td>
          <td>{info.day_to_meet}</td>
        </tr>
        <tr>
          <td><b>Time to Meet:&nbsp;</b></td>
          <td>{info.time_to_meet}</td>
        </tr>
      </table>
    );
    

    setEventToDelete(info.event_id);
    if(info.method_created === "Trainee Created"){
      setDatabase("confirmed-events");
    } else if(info.method_created === "Trainer Created"){
      setDatabase("confirmed-events-trainer");
    } else {
      setDatabase("confirmed-events-scheduler");
    }

    setModalHeader("Event Cancellation");
    setModalBody(newModalBody);
    setShowModalClose(true);
    setModalBtnText("Confirm Cancellation");
    setModalShow(true);
  };

  const infoSchedulerConfirm = (info) => {
    console.log("IN INFO SCHEDULER CONFIRM");
    console.log(info);

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<h5 key="Trainer_Info"><b>Trainer Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainer Name:&nbsp;</b></td>
          <td>{info.trainer.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainer.email}</td>
        </tr>
        <tr>
          <td><b>Trainer Phone Number:&nbsp;</b></td>
          <td>{info.trainer.phone}</td>
        </tr>
        <tr>
          <td><b>Trainer Zoom ID:&nbsp;</b></td>
          <td>{info.trainer.zoom_id}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Trainee_Info"><b>Trainee Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainee Name:&nbsp;</b></td>
          <td>{info.trainee.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainee.email}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event_Info"><b>Event Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Day to Meet: </b></td>
          <td>{info.day_to_meet}</td>
        </tr>
        <tr>
          <td><b>Time to Meet (in CST):&nbsp;</b></td>
          <td>{info.time_to_meet}</td>
        </tr>
        <tr>
          <td><b>Time Resolved:&nbsp;</b></td>
          <td>{new Date(
              info.timestamp.year,
              info.timestamp.month - 1,
              info.timestamp.day,
              info.timestamp.hour,
              info.timestamp.minute,
              info.timestamp.second,
            ).toLocaleDateString(
            DateLocale,
            DateOptions.LONG
          )}</td>
        </tr>
        <tr>
          <td><b>Event ID:&nbsp;</b></td>
          <td>{info.event_id}</td>
        </tr>
        <tr>
          <td><b>Method Created by:&nbsp;</b></td>
          <td>{info.method_created}</td>
        </tr>
      </table>
    );

    setModalHeader("Final Confirmation More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  const infoSchedulerTrainer = (info) => {
    console.log("IN INFO SCHEDULER TRAINER");
    console.log(info);

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<h5 key="Trainer_Info"><b>Trainer Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainer Name:&nbsp;</b></td>
          <td>{info.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.email}</td>
        </tr>
        <tr>
          <td><b>Trainer Language:&nbsp;</b></td>
          <td>{info.language}</td>
        </tr>
        <tr>
          <td><b>Trainer Zoom ID:&nbsp;</b></td>
          <td>{info.zoom_id}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="DayTime Info"><b>Availability Offered</b></h5>);
    
    for (const [idx, entry] of Object.entries(info.daytimes)){

      if (entry === null){
        continue;
      }

      if (info.resolved_times) {
        newModalBody.push(
          <h6 key = {"day_time_data_" + idx}>
            <ListGroup>
              <ListGroup.Item key={"day_time_" + idx}>
                {"AVAILABLE - " + entry.day + " at " + entry.time}
              </ListGroup.Item>
            </ListGroup>
          </h6>
        );
      } else {
        newModalBody.push(
          <h6 key = {"day_time_data_" + idx}>
            <ListGroup>
              <ListGroup.Item key={"day_time_" + idx}>
                {"CONFIRMED" + entry.day + " at " + entry.time}
              </ListGroup.Item>
            </ListGroup>
          </h6>
        );
      }
    }

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Availability_Info"><b>Event Info</b></h5>);
    newModalBody.push(
      <table>
        <tr>
          <td><b>Event Id:&nbsp;</b></td>
          <td>{info.event_id}</td>
        </tr>
        <tr>
          <td><b>Time Created:&nbsp;</b></td>
          <td>{new Date(
              info.timestamp.year,
              info.timestamp.month - 1,
              info.timestamp.day,
              info.timestamp.hour,
              info.timestamp.minute,
              info.timestamp.second,
            ).toLocaleDateString(
            DateLocale,
            DateOptions.LONG
          )}</td>
        </tr>
      </table>
    );

    setModalHeader("Final Confirmation More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  const infoSchedulerTrainee = (info) => {
    console.log("IN INFO SCHEDULER TRAINEE");
    console.log(info);

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<h5 key="Trainee_Info"><b>Trainee Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainee Name:&nbsp;</b></td>
          <td>{info.name}</td>
        </tr>
        <tr>
          <td><b>Trainee Email:&nbsp;</b></td>
          <td>{info.email}</td>
        </tr>
        <tr>
          <td><b>Trainee Language:&nbsp;</b></td>
          <td>{info.language}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="DayTime_Info"><b>Availability Inputted</b></h5>);
    

    for (const [idx, entry] of Object.entries(info.daytimes)){

      if (entry === null){
        continue;
      }

      newModalBody.push(
        <h6 key = {"day_time_data_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"day_time_" + idx}>
              {entry.day + " at " + entry.time}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
    }

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Availability_Info"><b>Event Info</b></h5>);
    newModalBody.push(
      <table>
        <tr>
          <td><b>Event Id:&nbsp;</b></td>
          <td>{info.event_id}</td>
        </tr>
        <tr>
          <td><b>Time Created:&nbsp;</b></td>
          <td>{new Date(
            info.timestamp.year,
            info.timestamp.month - 1,
            info.timestamp.day,
            info.timestamp.hour,
            info.timestamp.minute,
            info.timestamp.second,
            ).toLocaleDateString(
              DateLocale,
              DateOptions.LONG
              )}</td>
        </tr>
      </table>
    );
    
    setModalHeader("Trainee More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };
  
  const infoTrainee = (info) => {
    console.log("IN INFO TRAINEE");
    console.log(info);
    
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Training_Info"><b>Trainings Requested</b></h5>);
    
    let count = 0;
    for (const [idx, entry] of Object.entries(info.training)){

      if (entry === null){
        continue;
      }

      newModalBody.push(
        <h6 key = {"training_data_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"training_" + idx}>
              {info.training_resolved[count] + " - " + entry}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );

      count += 1;
    }
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Availability_Info"><b>Availabilities Submitted</b></h5>);
    
    count = 0;
    for (const [idx, entry] of Object.entries(info.daytimes)){

      if (entry === null){
        continue;
      }

      newModalBody.push(
        <h6 key = {"day_time_data_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"day_time_" + idx}>
              {info.daytimes_resolved[count] + " - " + entry.day + " at " + entry.time}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
      console.log(count);

      count += 1;
    }
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Availability_Info"><b>Additional Info</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Event Id:&nbsp;</b></td>
          <td>{info.event_id}</td>
        </tr>
        <tr>
          <td><b>Time Created:&nbsp;</b></td>
          <td>{new Date(
            info.timestamp.year,
            info.timestamp.month - 1,
            info.timestamp.day,
            info.timestamp.hour,
            info.timestamp.minute,
            info.timestamp.second,
            ).toLocaleDateString(
              DateLocale,
              DateOptions.LONG
              )}</td>
        </tr>
      </table>
    );

    setModalHeader("Trainee Availability More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  const infoTraineeTrainer = (info) => {
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Trainings_Info"><b>Trainings They Require and You Offer:</b></h5>);

    for (const [idx, entry] of Object.entries(info.trainee_match)){

      if (entry === null){
        continue;
      }

      newModalBody.push(
        <h6 key = {"trainee_match_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"trainee_match_" + idx}>
              {entry}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
    }

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Availability_Info"><b>Trainee Open Availabilities:</b></h5>);


    if(info.avail.length === 0){
      newModalBody.push(<h6>Has no trainings</h6>)
    } else {
      for (const [idx_i, entry] of Object.entries(info.avail)){

        if (entry === null){
          continue;
        }

        for (const[idx_j, info_entry] of Object.entries(entry.info)){
          
          console.log("PRINTING ENTRY");
          console.log(entry);
          console.log("PRINTING INFO ENTRY");
          console.log(info_entry);

          newModalBody.push(
            <h6 key = {"trainee_avail_" + idx_i}>
              <ListGroup>
                <ListGroup.Item key={"trainee_avail_i" + idx_i + "j" + idx_j}>
                  <p>{info_entry.training} - {info_entry.daytime} - <a href={info_entry.link}>Accept</a></p>
                </ListGroup.Item>
              </ListGroup>
            </h6>
          );
        }

        
      }
    }

    console.log(info.avail.length);

    setModalHeader("Trainee Availability More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  const infoTrainer = (info) => {
    console.log("IN INFO TRAINER");
    console.log(info);
    
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Availability_Info"><b>Availabilities Submitted</b></h5>);
    
    for (const [idx, entry] of Object.entries(info.daytimes)){

      if (entry === null){
        continue;
      }

      if(info.resolved[idx]){
        newModalBody.push(
          <h6 key = {"day_time_data_" + idx}>
            <ListGroup>
              <ListGroup.Item key={"day_time_" + idx}>
                {"CONFIRMED " + entry.day + " at " + entry.time}
              </ListGroup.Item>
            </ListGroup>
          </h6>
        );
      } else {
        newModalBody.push(
          <h6 key = {"day_time_data_" + idx}>
            <ListGroup>
              <ListGroup.Item key={"day_time_" + idx}>
                {"AVAILABLE " + entry.day + " at " + entry.time}
              </ListGroup.Item>
            </ListGroup>
          </h6>
        );
      }
    }
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Trainings"><b>Training Info</b></h5>);

    for (const [idx, entry] of Object.entries(info.trainings)){

      if (entry === null){
        continue;
      }

      if(entry !== "None"){
        newModalBody.push(
          <h6 key = {"trainings_data_" + idx}>
            <ListGroup>
              <ListGroup.Item key={"trainings_data_" + idx}>
                {entry}
              </ListGroup.Item>
            </ListGroup>
          </h6>
        );
      }
    }
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Trainings"><b>Additional Info</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Event Id:&nbsp;</b></td>
          <td>{info.event_id}</td>
        </tr>
        <tr>
          <td><b>Time Created:&nbsp;</b></td>
          <td>{new Date(
            info.timestamp.year,
            info.timestamp.month - 1,
            info.timestamp.day,
            info.timestamp.hour,
            info.timestamp.minute,
            info.timestamp.second,
            ).toLocaleDateString(
              DateLocale,
              DateOptions.LONG
              )}</td>
        </tr>
      </table>
    );

    setModalHeader("Trainer Availability More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  const infoConfirmed = (info) => {
    console.log("IN INFO CONFIRMED");
    console.log(info);
    
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];
    
    newModalBody.push(<h5 key="Trainer Info"><b>Trainer Information</b></h5>);
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainer Name:&nbsp;</b></td>
          <td>{info.trainer.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainer.email}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Trainee Info"><b>Trainee Information</b></h5>);
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainee Name:&nbsp;</b></td>
          <td>{info.trainee.name}</td>
        </tr>
        <tr>
          <td><b>Trainee Email:&nbsp;</b></td>
          <td>{info.trainee.email}</td>
        </tr>
      </table>
    );

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event Info"><b>Event Information</b></h5>);
    newModalBody.push(
      <table>
        <tr>
          <td><b>Training Type:&nbsp;</b></td>
          <td>{info.training}</td>
        </tr>
        <tr>
          <td><b>Day To Meet:&nbsp;</b></td>
          <td>{info.day_to_meet}</td>
        </tr>
        <tr>
          <td><b>Time to Meet (in CST):&nbsp;</b></td>
          <td>{info.time_to_meet}</td>
        </tr>
        <tr>
          <td><b>Your Role in the Event:&nbsp;</b></td>
          <td>{info.type}</td>
        </tr>
        <tr>
          <td><b>Method Created:&nbsp;</b></td>
          <td>{info.method_created}</td>
        </tr>
        <tr>
          <td><b>Time Resolved:&nbsp;</b></td>
          <td>{new Date(
            info.timestamp.year,
            info.timestamp.month - 1,
            info.timestamp.day,
            info.timestamp.hour,
            info.timestamp.minute,
            info.timestamp.second,
            ).toLocaleDateString(
              DateLocale,
              DateOptions.LONG
              )}</td>
        </tr>
        <tr>
          <td><b>Event ID:&nbsp;</b></td>
          <td>{info.event_id}</td>
        </tr>
      </table>
    );

    setModalHeader("Final Confirmation More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=HOME"),
    [navigate]
  );

  const redirectEditTrainee = (info) => {
    navigate(NavigateRoutes.EDITS + "?event=" + info["event_id"] + "&source=trainee")
  };

  const redirectEditTrainer = (info) => {
    navigate(NavigateRoutes.EDITS + "?event=" + info["event_id"] + "&source=trainer")
  };

  const redirectEditConfirmation = (event_id, source) => {
    navigate(NavigateRoutes.CONFIRMEDITS + "?event=" + event_id + "&source=" + source)
  };
  

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Hub Panel";

    if (!cookies["token"]) {
        // redirect to the login page
        setAuthorized(false);
    } else {
        authorize(cookies["firebaseToken"]).then((res) => {
          setCookie("token", res.data.token);
          setReauthorized(true);
        });
    }
    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This hook will be there in order to show the loading of the pages.
  useEffect(() => {
    if(reauthorized){
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      const decoded = decode(cookies["token"]);

      setUserData(decoded);

      getSchedulerStatus(decoded)
      .then((res) => {
        const logs = res.data;
        setIsScheduler(logs);
        setSchedulerStatusUpdated(true);
      })
      .catch((err) => console.log(err));

      getIsTrainer(decoded)
      .then((res) => {
        const logs = res.data;
        setIsTrainer(logs);
        setGetTrainerInfo(true);      
      })

    }
  }, [reauthorized, cookies]);

  useEffect(() => {
    if(reauthorized){
      // Now get the trainer info
      const decoded = decode(cookies["token"]);
      
      setTraineeLogsLoading(false);
      getTraineeLogs(decoded, traineeLogsCurPage, 10)
      .then((res) => {
        const logs = res.data.data;
        const size = res.data.totalPage;
        setTraineeLogs(logs);
        setTraineeLogsTotalPage(size);
        setTraineeLogsLoading(true);
      })
      .catch((err) => console.log(err));

    }
  }, [reauthorized, cookies, traineeLogsCurPage]);

  useEffect(() => {
    if(reauthorized){
      // Now get the trainer info
      const decoded = decode(cookies["token"]);
      
      setTrainerTraineeLoading(false);
      getTrainerTrainee(decoded, trainerTraineeCurPage, 10)
      .then((res) => {
        const logs = res.data.data;
        const size = res.data.totalPage;
        setTrainerTrainee(logs);
        setTrainerTraineeTotalPage(size);
        setTrainerTraineeLoading(true);
      })
      .catch((err) => console.log(err));

    }
  }, [reauthorized, cookies, trainerTraineeCurPage]);

  useEffect(() => {
    if(reauthorized){

      if(getTrainerInfo && isTrainer){
        // Now get the trainer info
        const decoded = decode(cookies["token"]);

        // get the trainer logs
        setTrainerLogsLoading(false);
        getTrainerLogs(decoded, trainerLogsCurPage, 10)
        .then((res) => {
          const logs = res.data.data;
          const size = res.data.totalPage;
          setTrainerLogs(logs);
          setTrainerLogsTotalPage(size);
          setTrainerLogsLoading(true);
        })
        .catch((err) => console.log(err));
      } else {
        setTrainerLogsLoading(true);
      }
    }
  }, [reauthorized, cookies, trainerLogsCurPage, getTrainerInfo, isTrainer]);

  useEffect(() => {
    if(reauthorized){

      if(getTrainerInfo && isTrainer){
        // Now get the trainer info
        const decoded = decode(cookies["token"]);

        // get the trainer logs
        setTraineeTrainerLoading(false);
        getTraineeTrainer(decoded, traineeTrainerCurPage, 10)
        .then((res) => {
          const logs = res.data.data;
          const size = res.data.totalPage;
          setTraineeTrainer(logs);
          setTraineeTrainerTotalPage(size);
          setTraineeTrainerLoading(true);
        })
        .catch((err) => console.log(err));
      } else {
        setTraineeTrainerLoading(true);
      }
    }
  }, [reauthorized, cookies, traineeTrainerCurPage, getTrainerInfo, isTrainer]);

  useEffect(() => {
    if(reauthorized){
      // Now get the trainer info
      const decoded = decode(cookies["token"]);

      // get the training logs
      setConfirmLogsLoading(false);
      getConfirmedTrainings(decoded, confirmLogsCurPage, 10)
      .then((res) => {
        const logs = res.data.data;
        const size = res.data.totalPage;
        setConfirmLogs(logs);
        setConfirmLogsTotalPage(size);
        setConfirmLogsLoading(true);
      })
      .catch((err) => console.log(err));

    }
  }, [reauthorized, cookies, confirmLogsCurPage]);

  useEffect(() => {
    if(schedulerStatusUpdated && isScheduler){
      // Now get the scheduler info
      const decoded = decode(cookies["token"]);

      setSchedulerConfirmLoading(false);
      getSchedulerConfirm(decoded, schedulerConfirmCurPage, 10, schedulerConfirmFilters)
        .then((res) => {
          const logs = res.data.data;
          const size = res.data.totalPage;
          setSchedulerConfirm(logs);
          setSchedulerConfirmTotalPage(size);
          setSchedulerConfirmLoading(true);
        })
        .catch((err) => console.log(err));
    }
  }, [schedulerStatusUpdated, isScheduler, cookies, schedulerConfirmCurPage, schedulerConfirmFilters]);

  useEffect(() => {
    if(schedulerStatusUpdated && isScheduler){
      // Now get the scheduler info
      const decoded = decode(cookies["token"]);

      setSchedulerTraineeLoading(false);
      getSchedulerTrainee(decoded, schedulerTraineeCurPage, 10,
        schedulerTraineeFilters)
        .then((res) => {
          const logs = res.data.data;
          const size = res.data.totalPage;
          setSchedulerTrainee(logs);
          setSchedulerTraineeTotalPage(size);
          setSchedulerTraineeLoading(true);
        })
        .catch((err) => console.log(err));
    }
  }, [schedulerStatusUpdated, isScheduler, cookies, schedulerTraineeCurPage, schedulerTraineeFilters]);

  useEffect(() => {
    if(schedulerStatusUpdated && isScheduler){
      // Now get the scheduler info
      const decoded = decode(cookies["token"]);

      setSchedulerTrainerLoading(false);
      getSchedulerTrainer(decoded, schedulerTrainerCurPage, 10, schedulerTrainerFilters)
        .then((res) => {
          const logs = res.data.data;
          const size = res.data.totalPage;
          setSchedulerTrainer(logs);
          setSchedulerTrainerTotalPage(size);
          setSchedulerTrainerLoading(true);
        })
        .catch((err) => console.log(err));
    }
  }, [schedulerStatusUpdated, isScheduler, cookies, schedulerTrainerCurPage, schedulerTrainerFilters]);

  useEffect(() => {
    if(isScheduler && schedulerConfirmLoading && schedulerTrainerLoading && schedulerTraineeLoading){
      setSchedulerLoading(true);
    }
  }, [
    isScheduler, 
    schedulerConfirmLoading,
    schedulerTrainerLoading,
    schedulerTraineeLoading
  ]);

  useEffect(() => {
    if(!isScheduler && traineeLogsLoading && trainerLogsLoading && confirmLogsLoading){
      setTraineeLoading(true);
    }
  }, [
    isScheduler, 
    traineeLogsLoading,
    trainerLogsLoading,
    confirmLogsLoading
  ]);

  useEffect(() => {
    if((!isScheduler && traineeLoading) || (isScheduler && traineeLoading && schedulerLoading)){
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );

      setAuthTimeout(newTimeout);
      setPageDoneLoading(true);
    }
  }, [
    isScheduler,
    traineeLoading,
    schedulerLoading,
    cookies
  ]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      console.log("REDIRECT LOGIN, authorized value:");
      console.log(authorized);
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  // =================================
  // -- Button handlers --
  // =================================

  /**
   * Handler for deleting a single user, shows modal to prompt user to confirm before proceeding
   * @param {object} user
   */

  /**
   * Assembles a table row with logged history of a REDCap Permissions submit request
   * @param {*} props
   * @returns Customized <tr /> component
   */

  // EDIT TO INCLUDE ALL INFO
  const TraineeRow = (props) => {
    const record = props.record;

    let string_trainings = [];

    for (const [entry] of Object.entries(record.training)){
      let temp = record.training[entry];

      string_trainings.push(<div>
        {temp}
      </div>)
    }

    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>
          {record.latest_time}
        </td>
        <td>{string_trainings}</td>
        <td>
          {
            <div>
            <Button
              variant="warning"
              size="sm"

              onClick={() => redirectEditTrainee(record)}
            >
              <IoPencilSharp />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoTrainee(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const TrainerRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>
          {record.latest_time}
        </td>
        <td>{record.event_id}</td>
        <td>
          {
            <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => redirectEditTrainer(record)}
            >
              <IoPencilSharp />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoTrainer(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const TrainerTraineeRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>{record.eventID}</td>
        <td>{record.trainer_name}</td>
        <td>
          {new Date(
            record.timestamp.year,
            record.timestamp.month - 1,
            record.timestamp.day,
            record.timestamp.hour,
            record.timestamp.minute,
            record.timestamp.second,
            ).toLocaleDateString(
              DateLocale,
              DateOptions.LONG
              )}
        </td>
        <td>{record.training_type}</td>
        <td>{record.daytime}</td>
        <td><a href={record.link}>Accept</a></td>
      </tr>
    );
  };

  const TraineeTrainerRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>{record.trainee_name}</td>
        <td>{record.trainee_email}</td>
        {
          (record.has_avail) && (
            <td>Yes</td>
          )
        }
        {
          (!record.has_avail) && (
            <td>No</td>
          )
        }
        <td>
        {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoTraineeTrainer(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const TrainingsRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>{record.type}</td>
        <td>{record.day_to_meet}</td>
        <td>{record.time_to_meet}</td>
        <td>{record.training}</td>
        <td>
          {new Date(
              record.timestamp.year,
              record.timestamp.month - 1,
              record.timestamp.day,
              record.timestamp.hour,
              record.timestamp.minute,
              record.timestamp.second,
            ).toLocaleDateString(
            DateLocale,
            DateOptions.LONG
          )}
        </td>
        <td>
          {
            <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => {
                let source_id = 0;
                if(record["method_created"] === "Trainee Created"){
                  source_id = 0;
                } else if(record["method_created"] === "Trainer Created"){
                  source_id = 1;
                } else {
                  source_id = 2;
                }
                redirectEditConfirmation(record["event_id"], source_id);
              }}
            >
              <IoPencilSharp />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setSchedulerCancel(false);
                cancelConfirmation(record);
              }}
            >
              <IoTrashBin />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                infoConfirmed(record);
              }}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const SchedulerConfirmRow = (props) => {
    const record = props.record;
    return (
      <tr key={record.trainer.name + record.trainee.name + record.day_to_meet}>
        <td>{record.training}</td>
        <td>{record.trainer.name}</td>
        <td>{record.trainee.name}</td>
        <td>{record.day_to_meet}</td>
        <td>{record.time_to_meet}</td>
        <td>
          {
            <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => {
                redirectEditConfirmation(record["event_id"], record["source"]);
              }}
            >
              <IoPencilSharp />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setSchedulerCancel(true);
                cancelConfirmation(record);
              }}
            >
              <IoTrashBin />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoSchedulerConfirm(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const SchedulerTraineeRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        <td>{record.training}</td>
        <td>{record.name}</td>
        <td>{record.email}</td>
        {
          record.resolved && (
            <td>RESOLVED</td>
          )
        }
        {
          !record.resolved && (
            <td>NOT RESOLVED</td>
          )
        }
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoSchedulerTrainee(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const SchedulerTrainerRow = (props) => {
    const record = props.record;
    let string_trainings = [];

    for (const [entry] of Object.entries(record.offered_trainings)){
      let temp = record.offered_trainings[entry];

      string_trainings.push(<div>
        {temp}
      </div>)
    }

    return (
      <tr key={props.key}>
        <td>{string_trainings}</td>
        <td>{record.name}</td>
        <td>{record.email}</td>
        <td>{record.zoom_id}</td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoSchedulerTrainer(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const handleChangeTraineeLogs = useCallback((page) => {
    setTraineeLogsCurPage(page);
  }, [])

  const handleChangeTrainerLogs = useCallback((page) => {
    setTrainerLogsCurPage(page);
  }, [])

  const handleChangeConfirmLogs = useCallback((page) => {
    setConfirmLogsCurPage(page);
  }, [])

  const handleChangeSchedulerTrainee = useCallback((page) => {
    setSchedulerTraineeCurPage(page);
  }, [])

  const handleChangeSchedulerTrainer = useCallback((page) => {
    setSchedulerTrainerCurPage(page);
  }, [])

  const handleChangeSchedulerConfirm = useCallback((page) => {
    setSchedulerConfirmCurPage(page);
  }, [])

  const handleChangeTrainerTrainee = useCallback((page) => {
    setTrainerTraineeCurPage(page);
  }, [])

  const handleChangeTraineeTrainer = useCallback((page) => {
    setTraineeTrainerCurPage(page);
  }, [])

  function MyPagination({total, current, onChangePage}) {
    let items = []

    if(current > 1){
      items.push(<Pagination.Prev key="prev" onClick={() => onChangePage(current - 1)} />)
    }

    if(total < 10){
        for (let page = 1; page <= total; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
          );
      }
    } else {
      if(current <= 3){
          for (let page = 1; page <= 5; page++) {
            items.push(
                <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                    {page}
                </Pagination.Item>
            );
        }

        items.push(<Pagination.Ellipsis />);
      } else if(current >= (total - 2)){
        <Pagination.Ellipsis />
        for (let page = total-4; page <= total; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
            );
        }
      } else {
        items.push(<Pagination.Ellipsis />);

        for (let page = current-2; page <= current+2; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
            );
        }

        items.push(<Pagination.Ellipsis />);
      }
    }

    if (current < total) {
        items.push(<Pagination.Next key="next" onClick={() => onChangePage(current + 1)} />)
    }

    return (
      <Pagination>
        {items}
      </Pagination>
    );
  };

  const handleSchedulerConfirmTrainingSort = (info) => {
    const changes = structuredClone(schedulerConfirmFilters);
    let found = false;

    for (const [entry] of Object.entries(changes)){
      if(changes[entry] === info){
        found = true;
        break;
      }
    }

    if(found){
      const idx = changes.indexOf(info);
      changes.splice(idx, 1);
    } else {
      changes.push(info);
    }

    console.log(changes);
    setSchedulerConfirmFilters(changes);
  };

  const handleSchedulerTraineeTrainingSort = (info) => {
    const changes = structuredClone(schedulerTraineeFilters);
    let found = false;

    for (const [entry] of Object.entries(changes)){
      if(changes[entry] === info){
        found = true;
        break;
      }
    }

    if(found){
      const idx = changes.indexOf(info);
      changes.splice(idx, 1);
    } else {
      changes.push(info);
    }

    console.log(changes);
    setSchedulerTraineeFilters(changes);
  };

  const handleSchedulerTrainerTrainingSort = (info) => {
    const changes = structuredClone(schedulerTrainerFilters);
    let found = false;

    for (const [entry] of Object.entries(changes)){
      if(changes[entry] === info){
        found = true;
        break;
      }
    }

    if(found){
      const idx = changes.indexOf(info);
      changes.splice(idx, 1);
    } else {
      changes.push(info);
    }

    console.log(changes);
    setSchedulerTrainerFilters(changes);
  };

  const extractTrainings = (items) => {
    const to_return = [];

    for(const [entry] of Object.entries(items)){
      to_return.push(items[entry]);
    }

    return to_return;
  };

  const SchedulerDisplay = () => {
    var to_return = [];
    
    var start = (
      <Container>
        <h1>Scheduler Hub</h1>
      </Container>
    );

    to_return.push(start);
    
    var trainOptions = extractTrainings(TrainingOpt);

    var final_comf = ( 
    <Container className={styles.sectionContainer}>
      {
        schedulerConfirmLoading && (schedulerConfirm.length > 0) && (
          <div>
        <Table
          tableName={"Final Confirmation Information"}
          columns={[
            "Training type",
            "Trainer Name",
            "Trainee Name",
            "Day to Meet",
            "Time to Meet (in CST)",
            "Edit Confirmation",
            "Delete Confirmation",
            "More Info"
          ]}
          sortable={[
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false
          ]}
          handleSorted={[
            handleSchedulerConfirmTrainingSort,
            false,
            false,
            false,
            false,
            false,
            false,
            false
          ]}
          filters={schedulerConfirmFilters}
          all={trainOptions}
          rows={schedulerConfirm.map((record) => (
            <SchedulerConfirmRow
              record={record}
              key={"schedulerConfirm" + JSON.stringify(record)}
            />
          ))}
        />
        {
          (schedulerConfirmLoading) && (schedulerConfirmTotalPage > 0) && (
            <MyPagination
              total={schedulerConfirmTotalPage}
              current={schedulerConfirmCurPage}
              onChangePage={handleChangeSchedulerConfirm}
              />
            )
        }
        </div>
        )
      }
      {
        (schedulerConfirmLoading && (schedulerConfirm.length === 0)) && (
          <div>
            <h3>Final Conformation Information</h3>
            <p>No Entries Recorded</p>
          </div>
        )
      }
      {
        !schedulerConfirmLoading && (
          <Spinner animation="border" />
        )
      }
    </Container>);
    
    var trainees_need = ( 
    <Container className={styles.sectionContainer}>
      {
        schedulerTraineeLoading && (schedulerTrainee.length > 0) && (
        <div>
        <Table
          tableName={"Users Needing Training"}
          columns={[
            "Training Required",
            "Name",
            "Email",
            "Is Resolved",
            "More Info"
          ]}
          sortable={[
            true,
            false,
            false,
            false,
            false
          ]}
          handleSorted={[
            handleSchedulerTraineeTrainingSort,
            false,
            false,
            false,
            false
          ]}
          filters={schedulerTraineeFilters}
          all={trainOptions}
          rows={schedulerTrainee.map((record) => (
            <SchedulerTraineeRow
            record={record}
            key={"schedulerTrainee" + JSON.stringify(record)}
            />
            ))}
            />
            {
              (schedulerTraineeLoading) && (schedulerTraineeTotalPage > 0) && (
                <MyPagination
                  total={schedulerTraineeTotalPage}
                  current={schedulerTraineeCurPage}
                  onChangePage={handleChangeSchedulerTrainee}
                  />
                )
            }
        </div>
        )
      }
      {
        (schedulerTraineeLoading && (schedulerTrainee.length === 0)) && (
          <div>
            <h3>Users Needing Training</h3>
            <p>No Entries Recorded</p>
          </div>
        )
      }
      {
        !schedulerTraineeLoading && (
          <Spinner animation="border" />
        )
      }
    </Container>);
    
    var trainers_have = ( 
    <Container className={styles.sectionContainer}>
      {
        schedulerTrainerLoading && (schedulerTrainer.length > 0) && (
        <div>
        <Table
          tableName={"Trainers With Availability"}
          columns={[
            "Trainings Offered",
            "Name",
            "Email",
            "Zoom ID",
            "More Info"
          ]}
          sortable={[
            true,
            false,
            false,
            false,
            false
          ]}
          handleSorted={[
            handleSchedulerTrainerTrainingSort,
            false,
            false,
            false,
            false
          ]}
          filters={schedulerTrainerFilters}
          all={trainOptions}
          rows={schedulerTrainer.map((record) => (
            <SchedulerTrainerRow
              record={record}
              key={"schedulerTrainer" + JSON.stringify(record)}
            />
          ))}
        />
        {
          (schedulerTrainerLoading) && (schedulerTrainerTotalPage > 0) && (
            <MyPagination
              total={schedulerTrainerTotalPage}
              current={schedulerTrainerCurPage}
              onChangePage={handleChangeSchedulerTrainer}
              />
            )
        }
        </div>
        )
      }
      {
        (schedulerTrainerLoading && (schedulerTrainer.length === 0)) && (
          <div>
            <h3>Trainers With Availability</h3>
            <p>No Entries Recorded</p>
          </div>
        )
      }
      {
        !schedulerTrainerLoading && (
          <Spinner animation="border" />
        )
      }
    </Container>);

    to_return.push(final_comf);
    to_return.push(trainees_need);
    to_return.push(trainers_have);

    return to_return;
  };

  return (
    <div>
      <NavBar />
      {
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Central Hub</h1>
          </Container>
          {/* Trainee Avail */}
          <Container className={styles.sectionContainer}>
            {
              (traineeLogsLoading && (traineeLogs.length > 0)) && (
              <div>
              <Table
                tableName={`Trainee Availability Information`}
                headers={[
                  { title: "Event Information", colspan: 4 }
                ]}
                columns={[
                  "Latest Availabile Time",
                  "Training Types",
                  "Edit Event",
                  "More Info"
                ]}
                rows={traineeLogs.map((record) => (
                  <TraineeRow
                    record={record}
                    key={"traineerecord" + JSON.stringify(record)}
                  />
                ))}
              />
              {
                (traineeLogsTotalPage > 0) && (
                  <MyPagination
                    total={traineeLogsTotalPage}
                    current={traineeLogsCurPage}
                    onChangePage={handleChangeTraineeLogs}
                    />
                  )
              }
              </div>
              )
            }
            {
              (traineeLogsLoading && (traineeLogs.length === 0)) && (
                <div>
                  <h3>Trainee Availability Information</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !traineeLogsLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
          {/* Available Trainer For You */}
          <Container className={styles.sectionContainer}>
          {
              (trainerTraineeLoading && (trainerTrainee.length > 0)) && (
              <div>
              <Table
                tableName={`Availabile Trainers for You`}
                headers={[
                  { title: "Event Information", colspan: 6 }
                ]}
                columns={[
                  "Training - ID",
                  "Trainer Name",
                  "Date Created",
                  "Training Type",
                  "Day Time",
                  "Accept Link"
                ]}
                rows={trainerTrainee.map((record) => (
                  <TrainerTraineeRow
                    record={record}
                    key={"trainertraineerecord" + JSON.stringify(record)}
                  />
                ))}
              />
              {
                (trainerTraineeTotalPage > 0) && (
                  <MyPagination
                    total={trainerTraineeTotalPage}
                    current={trainerTraineeCurPage}
                    onChangePage={handleChangeTrainerTrainee}
                    />
                  )
              }
              </div>
              )
            }
            {
              (trainerTraineeLoading && (trainerTrainee.length === 0)) && (
                <div>
                  <h3>Availabile Trainers for You Information</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !trainerTraineeLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
          {/* Trainer Avail */}
          <Container className={styles.sectionContainer}>
            {
              (trainerLogsLoading && isTrainer && (trainerLogs.length > 0)) && (
              <div>
              <Table
              tableName={`Trainer Availability Information`}
              headers={[
                { title: "Event Information", colspan: 4 }
              ]}
              columns={[
                "Latest Availabile Time",
                "Event ID",
                "Edit Event",
                "More Info"
              ]}
              rows={trainerLogs.map((record) => (
                <TrainerRow
                record={record}
                key={"trainerrecord" + JSON.stringify(record)}
                />
                ))}
                />
                {
                  (trainerLogsTotalPage > 0) && (
                    <MyPagination
                      total={trainerLogsTotalPage}
                      current={trainerLogsCurPage}
                      onChangePage={handleChangeTrainerLogs}
                      />
                    )
                }
                </div>
              )
            }
            {
              (trainerLogsLoading && isTrainer && (trainerLogs.length === 0)) && (
                <div>
                  <h3>Trainer Availability Information</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !trainerLogsLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
          {/* Available Trainees For You */}
          <Container className={styles.sectionContainer}>
          {
              (traineeTrainerLoading && isTrainer && (traineeTrainer.length > 0)) && (
              <div>
              <Table
                tableName={`Availabile Trainees for You`}
                headers={[
                  { title: "Event Information", colspan: 6 }
                ]}
                columns={[
                  "Trainee Name",
                  "Trainee Email",
                  "Has Availability",
                  "Info"
                ]}
                rows={traineeTrainer.map((record) => (
                  <TraineeTrainerRow
                    record={record}
                    key={"trainertraineerecord" + JSON.stringify(record)}
                  />
                ))}
              />
              {
                (traineeTrainerTotalPage > 0) && (
                  <MyPagination
                    total={traineeTrainerTotalPage}
                    current={traineeTrainerCurPage}
                    onChangePage={handleChangeTraineeTrainer}
                    />
                  )
              }
              </div>
              )
            }
            {
              (traineeTrainerLoading && isTrainer && (traineeTrainer.length === 0)) && (
                <div>
                  <h3>Availabile Trainees for You Information</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !traineeTrainerLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
          {/* Confirmed Training */}
          <Container className={styles.sectionContainer}>
            {(confirmLogsLoading && (confirmLogs.length > 0)) && (
            <div>
            <Table
              tableName={`Confirmed Trainings Information`}
              headers={[
                { title: "Event Information", colspan: 6 }
              ]}
              columns={[
                "Trainee/Trainer",
                "Day To Meet",
                "Time to Meet (in CST)",
                "Training Type",
                "Date Confirmed",
                "Edit Event",
                "Cancel Event",
                "More Info"
              ]}
              rows={confirmLogs.map((record) => (
                <TrainingsRow
                record={record}
                key={"trainingrecord" + JSON.stringify(record)}
                />
                ))}
            />
            {
              (confirmLogsLoading) && (confirmLogsTotalPage > 0) && (
                <MyPagination
                  total={confirmLogsTotalPage}
                  current={confirmLogsCurPage}
                  onChangePage={handleChangeConfirmLogs}
                  />
                )
            }
            </div>
            )
            }
            {
              (confirmLogsLoading && (confirmLogs.length === 0)) && (
                <div>
                  <h3>Confirmed Training Information</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !confirmLogsLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
          
          {
            isScheduler && (
              <SchedulerDisplay />
            )
          }

        </Container>
      }
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop={undefined}
      >
        <Modal.Header closeButton={true}>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          {
            showModalClose && (
              <Modal.Footer>
              <Button
                variant="primary"
                onClick={ schedulerCancel ? handleCancellationScheduler : handleCancellation
                }
              >
                {modalBtnText}
              </Button>
            </Modal.Footer>
            )
          }
      </Modal>

    </div>
  );
};

export default HubPage;
