import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import firebase from "../Firebase";
import { getAuth } from "firebase/auth";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";

import { getPendingUser, completeSignup } from "../api/Users";
import { NavigateRoutes } from "../constants/routes";

import styles from "../styles/Login.module.css";

/**
 * Page component displaying the signup items for a custom signup link
 * @returns React.FC
 */
const Signup = () => {
  // state - read url params - ?redirect
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [pendingUserId] = useState(queryParams.get("id"));

  const navigate = useNavigate();

  const [pendingUserData, setPendingUserData] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [linkError, setLinkError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const auth = getAuth(firebase);
  const [createUserWithEmailAndPassword, user] =
    useCreateUserWithEmailAndPassword(auth);

  const handleClick = () => {
    console.log("In handle Click");
    if (linkError) return;
    if (password.length < 8) {
      console.log("PW <= 8 characters");
      setPasswordLengthError(true);
    } else if (password !== confirmPassword) {
      console.log("PW != CPW");
      setPasswordConfirmError(true);
    } else if (!linkError) {
      setPasswordLengthError(false);
      setPasswordConfirmError(false);
      console.log("PW confirmed, sending creation");
      // Track down the issue with this
      createUserWithEmailAndPassword(pendingUserData.email, password);
    }
  };

  const redirectSuccess = useCallback(
    () => navigate(NavigateRoutes.LOGIN),
    [navigate]
  );

  useEffect(() => {
    getPendingUser(pendingUserId)
      .then((res) => setPendingUserData(res.data))
      .catch(() => setLinkError(true));
  }, [pendingUserId]);

  useEffect(() => {
    if (user) {
      completeSignup(user._tokenResponse.idToken, pendingUserId);
      redirectSuccess();
    }
  }, [user, redirectSuccess, pendingUserId]);

  useEffect(() => {
    document.title = "Project SEED Apps: Signup";
  }, []);

  // return for the page
  return (
    <Container fluid className={styles.loginContainerWrapper}>
      <Container className={styles.headerContainer}>
        <h1>Project SEED Apps Platform: Signup</h1>
        <p><b>PLEASE READ</b></p>
        <p>Make sure that when you create your password <b>you write it down somewhere or remember what it is</b>.<br />As we will not have access to retrieve your password.</p>
      </Container>
      {linkError && (
        <Container className={styles.errorContainer}>
          Could not verify this signup link. Please contact your administrator
          for more information.
        </Container>
      )}

      <Container className={styles.loginContainer}>
        <Form className={styles.loginForm}>
          <Form.Group required className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full name"
              defaultValue={pendingUserData?.name}
              disabled
            />
          </Form.Group>
          <Form.Group required className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              defaultValue={pendingUserData?.email}
              disabled
            />
          </Form.Group>
          <Form.Group required className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              defaultValue={pendingUserData?.phone}
              disabled
            />
          </Form.Group>
          <Form.Group required className="mb-3">
            <Form.Label>UT EID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter UT EID"
              defaultValue={pendingUserData?.uteid}
              disabled
            />
          </Form.Group>
          <Form.Group required className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Enter password"
            ></Form.Control>
          </Form.Group>
          <Form.Group required className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="Confirm password"
            ></Form.Control>
          </Form.Group>

          {passwordLengthError && (
            <Container className={styles.errorContainer}>
              Please make sure the length of your password is at least eight (8)
              characters, then try again.
            </Container>
          )}

          {passwordConfirmError && (
            <Container className={styles.errorContainer}>
              Passwords do not match. Please make sure both password entries are
              matching, then try again.
            </Container>
          )}
          <Button onClick={handleClick}>Submit</Button>
        </Form>
      </Container>
    </Container>
  );
};

export default Signup;
