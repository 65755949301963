import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

import { NavigateRoutes } from "../constants/routes";

/**
 * Page component for the signout page
 * Acts as a temporary display element for when the user clicks "Sign out" in the NavBar component
 * @returns
 */
const SignoutPage = () => {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(["token"]);

  useEffect(() => {
    removeCookie("token");
    navigate(NavigateRoutes.ROOT);
  });
  return <div>Signing out...</div>;
};

export default SignoutPage;
