import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const getRAType = (user) =>
  axios.post(SERVER_URL + "/api/checklist/info", {
    user,
    withCredentials: true,
  });

export const getCompleted = (user) =>
  axios.post(SERVER_URL + "/api/checklist/get_trainings", {
    user,
    withCredentials: true,
  });