import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import NavBar from "../components/NavBar";

import { NavigateRoutes } from "../constants/routes";

import {
  decode
} from "../utils/token";

import { 
  submitPendingChange,
  submitRejectingChange
} from "../api/Scheduler";

import { 
  getPendingEventInfo
} from "../api/Hub";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import { Button } from "react-bootstrap";

import Popup from 'reactjs-popup';

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const EditResponsePage = () => {
  // Need to connect it to some kind of state:
  // do so here:
  // https://www.robinwieruch.de/react-checkbox/

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [eventID] = useState(queryParams.get("event"));
  
  // Hooks for table
  const [trainingType, setTrainingType] = useState();
  const [trainingDay, setTrainingDay] = useState();
  const [trainingTime, setTrainingTime] = useState();
  const [trainingTrainee, setTrainingTrainee] = useState({});
  const [trainingTrainer, setTrainingTrainer] = useState({});

  // Hooks
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);

  // state - track appropriate variables for whether the user is able to access this page
  const [pageDoneLoading, setPageDoneLoading] = useState(false);

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // state - store user data from cookie
  const [userData, setUserData] = useState();

  // state - track changes
  const [changeError] = useState(false);
  const [changeErrorMessage] = useState("");

  // alias for redirect back to login page
  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Response Page";
    if (cookies["token"]) {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    getPendingEventInfo(eventID).then(
      (res) => {
        const data = res.data;
        console.log(data);

        setTrainingTrainee(data["user"]);
        setTrainingTrainer(data["trainer"]);
        setTrainingDay(data["form"]["day_to_meet"]);
        setTrainingTime(data["form"]["time_to_meet"]);
        setTrainingType(data["form"]["training"]);


        setPageDoneLoading(true);
    });

    // unmount callback
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server

      // load the user data and changed user data for future changes
      const decoded = decode(cookies["token"]);
      setUserData(decoded);

    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  // TODO Potentially bring this back
  // useEffect(() => {
  //   if (!authorized && pageDoneLoading) {
  //     const newTimeout = setTimeout(() => redirectLogin(), 5000);
  //     setLogoutTimeout(newTimeout);
  //   }
  // }, [authorized, pageDoneLoading, redirectLogin]);

  const popupConfirmation = () => (
    <Popup trigger={<button> Trigger</button>} position="right center">
      <div>Popup content here !!</div>
    </Popup>
  );

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleAccept = () => {

    // TODO
    // Make sure to catch any errors
    submitPendingChange(eventID)
      .then((res) => { 
                      // setTimeout(refreshPage, 2000); 
                      console.log("About to call popupConfirmation")
                      popupConfirmation();
                    })
      .catch((err) => console.log(err));
    
    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        The events have been successfully changed.
      </p>
      <p>This page will now redirect to the home screen in five (5) seconds.</p>
    </Container>
    );
    setModalBtnText("Close");
    setTimeout(redirectHome, 5000); // TODO change back
    setModalShow(true);
    setShowModalClose(true);
  };

  const handleReject = () => {

    // TODO
    // Make sure to catch any errors

    submitRejectingChange(eventID, userData)
      .then((res) => { 
                      // setTimeout(refreshPage, 2000); 
                      console.log("About to call popupConfirmation")
                      popupConfirmation();
                    })
      .catch((err) => console.log(err));


    setModalHeader("Rejection");
    setModalBody(
      <Container>
      <p>
        The Edits have been rejected. Thank you for responding
      </p>
      <p>This page will now redirect to the home screen in five (5) seconds.</p>
    </Container>
    );
    setModalBtnText("Close");
    setTimeout(redirectHome, 5000); // TODO change back
    setModalShow(true);
    setShowModalClose(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Editting Response Page</h1>
          </Container>
          <Container className={styles.sectionContainer}>
            {userData && (
              <Container>
                <h3>Trainer Information</h3>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingTrainer.name}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingTrainer.email}
                    />
                  </Container>
                </Container>
                <h3>Trainee Information</h3>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingTrainee.name}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingTrainee.email}
                    />
                  </Container>
                </Container>
                <h3>Event Information</h3>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Training Type
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingType}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Day To Meet
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingDay}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Time to Meet (in CST)
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={true}
                      type="text"
                      defaultValue={trainingTime}
                    />
                  </Container>
                </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="success"
                  onClick={handleAccept}
                >
                  Accept Changes
                </Button>
                {' '}
                <Button
                  variant="danger"
                  onClick={handleReject}
                >
                  Reject Changes
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      backdrop={showModalClose ? undefined : "static"}
    >
      <Modal.Header closeButton={showModalClose}>
          <Modal.Title>{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {showModalClose && (
          <Modal.Footer>
            <Button
              variant="primary"
            >
              {modalBtnText}
            </Button>
          </Modal.Footer>
        )}
    </Modal>
    </div>
  );
};

export default EditResponsePage;