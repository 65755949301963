import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

// bootstrap imports
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";

// app-specific
import Table from "../components/Table";
import NavBar from "../components/NavBar";
import {
  AppTitles,
  AppAccess,
  AdminAccess,
  LangOptions,
  AppActions,
} from "../constants/apps";
import { NavigateRoutes } from "../constants/routes";
import { DateOptions, DateLocale } from "../constants/dates";

// utils
import { userIsAdmin, getTokenExpirationDate } from "../utils/token";
import { capitalizeFirstLetter } from "../utils/strings";
import { refreshPage } from "../utils/components";
import { EmailRegex } from "../constants/regex";

// api
import {
  submitCreateFullUser,
  getAllUsers,
  deleteUser,
  updateUsers,
  completeSignupV2
} from "../api/Users";
import { getRedcapLogs } from "../api/Logs";
import { getConfirmedLogs } from "../api/Scheduler.js";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

// custom page styling
import styles from "../styles/Admin.module.css";

/**
 * Page component for displaying the Admin page
 * @returns React.FC
 */
const Admin = () => {
  // state - track new users
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPhone, setNewUserPhone] = useState("");
  const [newUserRedcapAccess, setNewUserRedcapAccess] = useState(
    AppAccess.DEFAULT
  );
  const [newUserTrainerLanguage, setNewUserTrainerLanguage] = useState(LangOptions.ENGL);

  const [newUserUteid, setNewUserUteid] = useState("");
  // const [newUserSchedulerAccess, setNewUserSchedulerAccess] = useState(
  //   AppAccess.DEFAULT
  // );
  const [newUserIsAdmin, setNewUserIsAdmin] = useState(false);

  const [platformUsersMap, setPlatformUsersMap] = useState({});

  // state - redcap logs
  const [redcapLogs, setRedcapLogs] = useState([]);
  
  // state - confirm logs
  const [confirmLogs, setConfirmLogs] = useState([]);

  // state - track errors in new users
  const [newUserEmailError, setNewUserEmailError] = useState(false);
  const [newUserNameError, setNewUserNameError] = useState(false);
  const [newUserPhoneError, setNewUserPhoneError] = useState(false);
  const [newUserUteidError, setNewUserUteidError] = useState(false);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - control modals
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showUpdateUsersModal, setShowUpdateUsersModal] = useState(false);

  const [userUpdatesMap, setUserUpdatesMap] = useState({});
  const [userUpdatesQueued, setUserUpdatesQueued] = useState(false);
  const [userUpdatesError, setUserUpdatesError] = useState(false);
  const [userUpdatesErrorMessages, setUserUpdatesErrorMessages] = useState([
    <></>,
  ]);
  const [userToDelete, setUserToDelete] = useState();

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies] = useCookies(["token"]);

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=ADMIN"),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Admin Panel";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // parse out the token from the server
      const auth = userIsAdmin(cookies["token"]);
      console.log("Auth is:");
      console.log(auth);
      setAuthorized(auth);

      // get all users from the backend
      getAllUsers()
        .then((res) => {
          const newMap = {};
          for (const user of Object.values(res.data.users)) {
            newMap[user.sub] = user;
          }
          setPlatformUsersMap(newMap);
        })
        .catch((err) => console.log(err));

      // get the redcap history logs
      getRedcapLogs()
        .then((res) => {
          // sort the logs by timestamp, descending
          const logs = res.data;
          logs.sort((a, b) => b.timestamp - a.timestamp);
          setRedcapLogs(logs);
        })
        .catch((err) => console.log(err));
      
      // get the scheduler logs  
      getConfirmedLogs()
        .then((res) => {
          // sort the logs by timestamp, descending
          const logs = res.data;
          // logs.sort((a, b) => b.timestamp - a.timestamp);
          setConfirmLogs(logs);
        })
        .catch((err) => console.log(err));

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );

      setAuthTimeout(newTimeout);
    }

    setPageDoneLoading(true);

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  // =================================
  // -- Button handlers --
  // =================================

  /**
   * Handler for deleting a single user, shows modal to prompt user to confirm before proceeding
   * @param {object} user
   */
  const handleSubmitDeleteUser = (user) => {
    setUserToDelete(user);
    setShowDeleteUserModal(true);
  };

  /**
   * Handler for confirming the delete user action within the modal
   */
  const handleConfirmDeleteUser = () => {
    deleteUser(userToDelete.sub)
      .then((res) => setTimeout(refreshPage, 3000))
      .catch((err) => console.log(err));
  };

  /**
   * Handler for updating the userChanges state object from the input forms
   * @param {string} sub
   * @param {string} attributeName
   * @param {*} attributeValue
   */
  const handleChangeUser = (sub, attributeName, attributeValue) => {
    setUserUpdatesQueued(false);
    setUserUpdatesError(false);
    setUserUpdatesErrorMessages([<></>]);
    const changes = structuredClone(userUpdatesMap);

    if (!(sub in changes)) {
      changes[sub] = structuredClone(platformUsersMap[sub]);
    }

    if (attributeName === "is_admin") {
      changes[sub][attributeName] = attributeValue === "Admin";
    } else {
      changes[sub][attributeName] = attributeValue;
    }

    let isSimilar = true;
    for (let attrName of Object.keys(changes[sub])) {
      // console.log(
      //   attrName,
      //   changes[sub][attrName],
      //   platformUsers[platformUsersIdx][attrName],
      //   changes[sub][attrName] === platformUsers[platformUsersIdx][attrName]
      // );
      if (changes[sub][attrName] !== platformUsersMap[sub][attrName]) {
        isSimilar = false;
        break;
      }
    }

    if (isSimilar) {
      delete changes[sub];
    }

    if (Object.keys(changes).length) {
      setUserUpdatesQueued(true);
    }
    setUserUpdatesMap(changes);
  };

  /**
   * Handler for the change users button - opens modal
   */
  const handleSubmitUpdateUser = () => setShowUpdateUsersModal(true);

  const handleConfirmUpdateUsers = () => {
    let error = false;

    const errorMessages = [];

    // check user name updates
    for (const user of Object.values(userUpdatesMap)) {
      if (user.name.trim() === "") {
        errorMessages.push(
          <Container>
            User "{user.name && user.name}" ({user.email}, {user.uteid}) does
            not have a valid name for updates.
          </Container>
        );
        error = true;
      }
    }

    // assemble json for emails to update
    const emailUpdates = {};

    // check user email updates
    for (const user of Object.values(userUpdatesMap)) {
      if (user.email.trim() === "" || !EmailRegex.test(user.email)) {
        errorMessages.push(
          <Container>
            User "{user.name && user.name}" ({user.email}, {user.uteid}) does
            not have a valid email for updates.
          </Container>
        );
        error = true;
      } else {
        emailUpdates[user.sub] = user.email;
      }
    }

    // check user uteid updates
    for (const user of Object.values(userUpdatesMap)) {
      if (user.uteid.trim() === "") {
        errorMessages.push(
          <Container>
            User "{user.name && user.name}" ({user.email}, {user.uteid}) does
            not have a valid UT EID for updates.
          </Container>
        );
        error = true;
      }
    }

    // if errors, show containers with message and halt execution before making any updates
    if (error) {
      setUserUpdatesError(true);
      setUserUpdatesErrorMessages(errorMessages);
      return;
    } else {
      setUserUpdatesError(false);
      setUserUpdatesErrorMessages([<></>]);
    }

    // make database updates
    updateUsers(userUpdatesMap, emailUpdates)
      .then(refreshPage)
      .catch((err) => console.log(err));
  };

  /**
   * Handler for the create user button
   */
  const handleSubmitCreateUser = () => {
    let err = false;
    //eslint-disable-next-line
    if (!EmailRegex.test(newUserEmail)) {
      // email validation
      err = true;
      setNewUserEmailError(true);
    }
    if (newUserName.trim() === "") {
      // name is not empty
      err = true;
      setNewUserNameError(true);
    }
    if (newUserUteid.trim() === "") {
      err = true;
      setNewUserUteidError(true);
    }
    if (newUserPhone.trim() === "") {
      err = true;
      setNewUserPhoneError(true);
    }

    if (!err) {
      resetNewUserAttributes();

      // assemble the backend request
      const data = {
        name: newUserName,
        email: newUserEmail,
        uteid: newUserUteid,
        phone: newUserPhone,
        redcapperms: newUserRedcapAccess,
        is_admin: newUserIsAdmin,
        language: newUserTrainerLanguage,
      };
      
      // This is where I would just create the user:

      // submitCreateUser([data])
      //   .then((res) => console.log(res))
      //   .catch((err) => console.log(err));

      // Now simiply just create the user in the data.
      submitCreateFullUser(data)
        .then((res) => {
          console.log("RES");
          console.log(res.data);

          const auth = getAuth();
          createUserWithEmailAndPassword(auth, data.email, data.email)
          .then((userCredential)=>{
            console.log("USER CREDENTIAL");
            console.log(userCredential);
            console.log(userCredential.user);
            console.log(userCredential.user.uid);
            console.log("EVERYTHING OKAY");
            completeSignupV2(userCredential._tokenResponse.idToken, res.data);
          })
          .catch((error) => {
            console.log("EVERYTHING FAILED");
            console.error(error);
          });
        })
        .catch((err) => console.log(err));


    }
  };

  const resetNewUserAttributes = () => {
    // new user attributes
    setNewUserName("");
    setNewUserEmail("");
    setNewUserPhone("");
    setNewUserRedcapAccess(AppAccess.DEFAULT);
    setNewUserTrainerLanguage(LangOptions.ENGL);
    setNewUserUteid("");
    setNewUserIsAdmin(false);

    // new user errors
    setNewUserEmailError(false);
    setNewUserNameError(false);
    setNewUserPhoneError(false);
    setNewUserUteidError(false);
    setShowCreateUserModal(false);
  };

  /**
   * Assembles a table row with logged history of a REDCap Permissions submit request
   * @param {*} props
   * @returns Customized <tr /> component
   */
  const RedcapHistoryRow = (props) => {
    const record = props.record;
    const body = record.requestBody;
    let description;
    switch (record.action) {
      case AppActions.redcapPerms.UPDATE_REDCAP_PERMS:
        const updateRcPermBody = [];
        for (const [k, v] of Object.entries(body)) {
          if (v.length) {
            updateRcPermBody.push(<h6 key={k}>{k}</h6>);
            updateRcPermBody.push(
              <p key={k + JSON.stringify(v)}>{v.join(", ")}</p>
            );
          }
        }
        description = <Container fluid>{updateRcPermBody}</Container>;
        break;
      case AppActions.redcapPerms.UPDATE_PROJECT_FORMS:
        description = (
          <Container fluid>
            <h6>Updated forms from REDCap for project named: {body.name}</h6>
          </Container>
        );
        break;
      case AppActions.redcapPerms.ADD_PROJECT:
        description = (
          <Container fluid>
            <h6>Project name: {body.projectName}</h6>
            <h6>API Token: {body.projectToken}</h6>
          </Container>
        );
        break;
      case AppActions.redcapPerms.UPDATE_PROJECTS:
        const updateProjectsBody = Object.keys(body.changes).map((name) => (
          <div key={name}>
            <h6>
              Updated project <i>{name}</i>:
            </h6>
            <Container>
              {Object.keys(body.changes[name]).map((attr) =>
                attr === "nameChanged" ? (
                  <div key={JSON.stringify(body.changes[name])}></div>
                ) : attr === "forms" ? (
                  <Container
                    style={{ margin: "0.5em" }}
                    key={name + attr + JSON.stringify(body.changes[name][attr])}
                  >
                    <b>Forms:</b>
                    {body.changes[name][attr].map((form) => (
                      <div key={"update-project-forms" + form}>{form}</div>
                    ))}
                  </Container>
                ) : attr === "perm_sets" ? (
                  <Container
                    style={{ margin: "0.5em" }}
                    key={name + attr + JSON.stringify(body.changes[name][attr])}
                  >
                    <b>Permission sets:</b>
                    {body.changes[name][attr].map((perm) => (
                      <div key={"update-project-perms" + perm}>{perm}</div>
                    ))}
                  </Container>
                ) : (
                  <Container
                    style={{ margin: "0.5em" }}
                    key={"change-project-" + name + attr}
                  >
                    <b>{capitalizeFirstLetter(attr)}&#58;</b>{" "}
                    {body.changes[name][attr]}
                  </Container>
                )
              )}
            </Container>
          </div>
        ));
        description = <Container fluid>{updateProjectsBody}</Container>;
        break;
      case AppActions.redcapPerms.DELETE_PROJECT:
        description = (
          <Container fluid>
            <h6>Deleted project named: {body.projectName}</h6>
          </Container>
        );
        break;
      case AppActions.redcapPerms.ADD_PERM_SET:
        description = (
          <Container fluid>
            <h6>
              Created permission set with empty values: {body.permSetName}
            </h6>
          </Container>
        );
        break;
      case AppActions.redcapPerms.DELETE_PERM_SET:
        description = (
          <Container fluid>
            <h6>
              Deleted permission set <i>{body.permSetName}</i> from the database
            </h6>
          </Container>
        );
        break;
      case AppActions.redcapPerms.UPDATE_PERM_SETS:
        const updatePermSetBody = Object.keys(body.changes).map((name) => (
          <div key={name}>
            <h6>
              Updated permission set <i>{name}</i> with values:
            </h6>
            <Container className={styles.inCellContainer}>
              {Object.keys(body.changes[name]).map((attr) => (
                <div key={"change-perm-" + name + attr}>
                  {attr}&#58; {body.changes[name][attr]}
                </div>
              ))}
            </Container>
          </div>
        ));
        description = <Container fluid>{updatePermSetBody}</Container>;
        break;
      default:
        break;
    }

    return (
      <tr>
        <td>{record.name}</td>
        <td>{record.email}</td>

        <td>{capitalizeFirstLetter(record.action)}</td>
        <td>{description}</td>

        <td>
          {new Date(record.timestamp * 1000).toLocaleDateString(
            DateLocale,
            DateOptions.LONG
          )}
        </td>
      </tr>
    );
  };

  const ConfirmHistoryRow = (props) => {
    const record = props.record;
    return (
      <tr>
        <td>{record.trainee_name}</td>
        <td>{record.trainee_email}</td>
        <td>{record.trainer_name}</td>
        <td>{record.trainer_email}</td>
        <td>{record.training_type}</td>
        <td>{record.day_to_meet}</td>
        <td>{record.time_to_meet}</td>
        {/* <td>{record.timestamp}</td> */}
        <td>
          {new Date(
              record.timestamp.year,
              record.timestamp.month - 1,
              record.timestamp.day,
              record.timestamp.hour,
              record.timestamp.minute,
              record.timestamp.second,
            ).toLocaleDateString(
            DateLocale,
            DateOptions.LONG
          )}
        </td>
      </tr>
    );
  };

  const ChangeUsersModalBody = () => {
    const BodyForUser = (props) => {
      const user = props.user;
      const body = [];

      for (const [attr, value] of Object.entries(userUpdatesMap[user.sub])) {
        if (attr !== "confirmed-times" && platformUsersMap[user.sub][attr] !== value) {             
          if (attr === "is_admin"){
            if(value){
              body.push(
                <Container
                  key={"change" + JSON.stringify(attr) + JSON.stringify(value)}
                >
                  <b>{attr}:</b> True
                </Container>
    
              );
            } else {
              body.push(
                <Container
                  key={"change" + JSON.stringify(attr) + JSON.stringify(value)}
                >
                  <b>{attr}:</b> False
                </Container>
    
              );
            }
          } else {
            body.push(
              <Container
                key={"change" + JSON.stringify(attr) + JSON.stringify(value)}
              >
                <b>{attr}:</b> {value}
              </Container>
  
            );
          }
        }
      }
      return body;
    };

    return Object.entries(userUpdatesMap).map(([sub, user]) => (
      <div key={"change" + JSON.stringify(user)}>
        <Container>
          {platformUsersMap[sub].name} ({platformUsersMap[sub].email}):
        </Container>
        <Container>
          <BodyForUser user={user} />
        </Container>
      </div>
    ));
  };

  // return for the page
  return (
    <div>
      <NavBar />
      <Container className={styles.pageContainer}>
        <Container>
          <h1>Admin Panel</h1>
        </Container>
        <Container className={styles.sectionContainer}>
          <Table
            tableName="Users"
            headers={[
              { title: "User information", colspan: 4 },
              { title: "Application access", colspan: 2 },
              { title: "Scheduler Info", colspan: 1},
              { title: "", colspan: 1 },
            ]}
            columns={[
              "User full name",
              "User email",
              "User Phone Number",
              "UT EID",
              AppTitles.REDCAP_PERMS,
              "Platform Admin",
              "Language Type",
              "Delete user",
            ]}
            rows={Object.values(platformUsersMap).map((user) => (
              <tr key={JSON.stringify(user)}>
                <td>
                  <Form.Control
                    type="text"
                    defaultValue={user.name}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "name", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    defaultValue={user.email}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "email", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    defaultValue={user.phone}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "phone", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    defaultValue={user.uteid}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "uteid", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Select
                    defaultValue={user.redcapperms}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "redcapperms", e.target.value)
                    }
                  >
                    {Object.values(AppAccess).map((it) => (
                      <option key={it}>{capitalizeFirstLetter(it)}</option>
                    ))}
                  </Form.Select>
                </td>
                {/* TODO: commented out scheduler access while in development */}
                {/* <td>
                  <Form>
                    <Form.Select
                      defaultValue={user.scheduler}
                      onChange={(e) =>
                        handleChangeUser(
                          user.sub,
                          i,
                          "scheduler",
                          e.target.value
                        )
                      }
                    >
                      {Object.values(AppAccess).map((it) => (
                        <option key={it}>{capitalizeFirstLetter(it)}</option>
                      ))}
                    </Form.Select>
                  </Form>
                </td> */}
                <td>
                  <Form.Select
                    defaultValue={user.is_admin ? "Admin" : "Default"}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "is_admin", e.target.value)
                    }
                  >
                    {Object.values(AdminAccess).map((it) => (
                      <option key={it}>{it}</option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <Form.Select
                    defaultValue={user.language}
                    onChange={(e) =>
                      handleChangeUser(user.sub, "language", e.target.value)
                    }
                  >
                    {Object.values(LangOptions).map((it) => (
                      <option key={it}>{capitalizeFirstLetter(it)}</option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <Container fluid>
                    <Button
                      size="sm"
                      className={styles.deleteUserBtn}
                      variant="danger"
                      onClick={() => handleSubmitDeleteUser(user)}
                    >
                      Delete
                    </Button>
                  </Container>
                </td>
                {/* <td>
                  <Container fluid>
                    <Button
                      size="sm"
                      className={styles.changeUserBtn}
                      variant="warning"
                      onClick={handleChangeUser}
                    >
                      Submit
                    </Button>
                  </Container>
                </td> */}
              </tr>
            ))}
          />

          <Button
            variant="warning"
            disabled={!userUpdatesQueued}
            onClick={handleSubmitUpdateUser}
            className={styles.usersPanelBtn}
          >
            Submit user changes
          </Button>
          <Button
            onClick={() => setShowCreateUserModal(true)}
            className={styles.usersPanelBtn}
          >
            Create new user
          </Button>
        </Container>

        <Container className={styles.sectionContainer}>
          <Table
            tableName={`History: ${AppTitles.REDCAP_PERMS}`}
            headers={[
              { title: "User information", colspan: 2 },
              { title: "History", colspan: 5 },
            ]}
            columns={[
              "User full name",
              "User email",
              "Action",
              "Description",
              "Timestamp",
            ]}
            rows={redcapLogs.map((record) => (
              <RedcapHistoryRow
                record={record}
                key={"redcaprecord" + JSON.stringify(record)}
              />
            ))}
          />
        </Container>
        {/* TODO FINISH THIS */}
        <Container className={styles.sectionContainer}>
          <Table
            tableName={`History: Final Confirmations`}
            headers={[
              { title: "Trainee information", colspan: 2 },
              { title: "Trainer information", colspan: 2 },
              { title: "Event Information", colspan: 4 },
            ]}
            columns={[
              "Trainee full name",
              "Trainee email",
              "Trainer full name",
              "Trainer email",
              "Training Type",
              "Day to Meet",
              "Time to Meet",
              "Final Confirmation Timestamp",
            ]}
            rows={confirmLogs.map((record) => (
              <ConfirmHistoryRow
                record={record}
                key={"confirmrecord" + JSON.stringify(record)}
              />
            ))}
          />
        </Container>

        {/* // TODO: in development: SCHEDULER APP
        <Container>
          <Table
            tableName={`History: ${AppTitles.SCHEDULER}`}
            headers={[
              { title: "User information", colspan: 2 },
              { title: "History", colspan: 3 },
            ]}
            columns={[
              "User full name",
              "User email",
              "Action",
              "Description",
              "Timestamp",
            ]}
          />
        </Container> */}
      </Container>

      {/* MODAL: Create a new user */}
      {/* TODO: add uteid field and connect */}
      <Modal
        show={showCreateUserModal}
        onHide={() => setShowCreateUserModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container className={styles.formContainer}>
              {newUserNameError && (
                <Container className={styles.errorContainer}>
                  Please make sure you have entered a valid name, then try
                  again.
                </Container>
              )}
              <FloatingLabel label="New user full name">
                <Form.Control
                  type="text"
                  variant="bold"
                  onChange={(e) => setNewUserName(e.target.value)}
                />
              </FloatingLabel>
            </Container>
            <Container className={styles.formContainer}>
              {newUserEmailError && (
                <Container className={styles.errorContainer}>
                  Please make sure you have entered a valid email address, then
                  try again.
                </Container>
              )}
              <FloatingLabel label="New user email address">
                <Form.Control
                  type="text"
                  onChange={(e) => setNewUserEmail(e.target.value)}
                />
              </FloatingLabel>
            </Container>
            <Container className={styles.formContainer}>
              {newUserUteidError && (
                <Container className={styles.errorContainer}>
                  Please make sure you have entered a valid UT EID, then try
                  again.
                </Container>
              )}
              <FloatingLabel label="New user UT EID">
                <Form.Control
                  type="text"
                  onChange={(e) => setNewUserUteid(e.target.value)}
                />
              </FloatingLabel>
            </Container>
            <Container className={styles.formContainer}>
              {newUserPhoneError && (
                <Container className={styles.errorContainer}>
                  Please make sure you have entered a Phone number, then try
                  again.
                </Container>
              )}
              <FloatingLabel label="New user Phone Number">
                <Form.Control
                  type="text"
                  onChange={(e) => setNewUserPhone(e.target.value)}
                />
              </FloatingLabel>
            </Container>
            <Container className={styles.formContainer}>
              <Form.Group>
                <Form.Label>{AppTitles.REDCAP_PERMS}</Form.Label>

                <Form.Select
                  defaultValue={AppAccess.DEFAULT}
                  onChange={(e) => setNewUserRedcapAccess(e.target.value)}
                >
                  {Object.values(AppAccess).map((it) => (
                    <option key={it}>{capitalizeFirstLetter(it)}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Container>
            <Container className={styles.formContainer}>
              <Form.Group>
                <Form.Label>{AppTitles.LANGUAGE}</Form.Label>

                <Form.Select
                  defaultValue={LangOptions.ENGL}
                  onChange={(e) => setNewUserTrainerLanguage(e.target.value)}
                >
                  {Object.values(LangOptions).map((it) => (
                    <option key={it}>{capitalizeFirstLetter(it)}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Container>
            {/* TODO: removed scheduler modal option b/c in development */}
            {/* <Container className={styles.formContainer}>
              <Form.Group>
                <Form.Label>Scheduler</Form.Label>
                <Form.Select
                  defaultValue={AppAccess.DEFAULT}
                  onChange={(e) => setNewUserSchedulerAccess(e.target.value)}
                >
                  {Object.values(AppAccess).map((it) => (
                    <option>{capitalizeFirstLetter(it)}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Container> */}
            <Container className={styles.formContainer}>
              <Form.Label>Platform Admin</Form.Label>
              <Form.Select
                defaultValue={AdminAccess.DEFAULT}
                onChange={(e) =>
                  setNewUserIsAdmin(e.target.value === AdminAccess.ADMIN)
                }
              >
                {Object.values(AdminAccess).map((it) => (
                  <option key={it}>{it}</option>
                ))}
              </Form.Select>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitCreateUser}>Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* TODO: create modal */}
      {/* MODAL: Change multiple users */}
      <Modal
        show={showUpdateUsersModal}
        onHide={() => setShowUpdateUsersModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            The following users will be updated with the following values:
          </h4>
          {userUpdatesError && (
            <Container
              className={styles.errorContainer}
              styles={{ fontSize: "0.75rem" }}
            >
              <h5>Could not validate updates:</h5>
              {userUpdatesErrorMessages}
            </Container>
          )}
          <Container>
            <ChangeUsersModalBody />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleConfirmUpdateUsers}>Confirm</Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL: Delete a current user */}
      <Modal
        show={showDeleteUserModal}
        onHide={() => setShowDeleteUserModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>The following user will be deleted:</h4>
          <p>
            <b>
              <i>
                ({userToDelete?.name}, {userToDelete?.email})
              </i>
            </b>
          </p>
          <p>Please confirm your changes before proceeding.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleConfirmDeleteUser}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Admin;
