import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import styles from "../styles/Login.module.css";

// styling components
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

// firebase - authentication imports
import firebase from "../Firebase";
import { getAuth } from "firebase/auth";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";

// cookies
import { useCookies } from "react-cookie";

// api
import { authorize } from "../api/Login";

// constants
import { NavigateRoutes } from "../constants/routes";

/**
 * Page component displaying login items
 * @returns React.FC
 */
const Login = () => {
  // ========================
  //  Hooks
  // ========================

  // state - track email and password input
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authorizeError, setAuthorizeError] = useState(false);

  // state - read url params - ?redirect
  const location = useLocation();
  const [redirect, setRedirect] = useState();

  // firebase login
  const auth = getAuth(firebase);
  const [signInWithEmailAndPassword, user, , authenticateError] =
    useSignInWithEmailAndPassword(auth);

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [, setCookie] = useCookies(["token", "firebaseToken"]);

  // ========================
  //  useEffect callbacks
  // ========================

  useEffect(() => {
    document.title = "Project SEED Apps: Login";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Updates when user object changes (on login)
  useEffect(() => {
    if (user) {
      const idToken = user._tokenResponse.idToken;
      setCookie("firebaseToken", idToken);
      // make the callback to get the authorization token from the backend
      authorize(idToken)
        .then((res) => {
          setCookie("token", res.data.token);
          if (redirect) navigate(NavigateRoutes[redirect]);
          else navigate(NavigateRoutes.HOME);
        })
        .catch((err) => {
          setAuthorizeError(true);
        });
    }
  }, [user, navigate, setCookie, redirect]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setRedirect(queryParams.get("redirect"));
  }, [location]);

  // ========================
  //  Utility functions
  // ========================

  /**
   * Handler for the login button press - alias for react-firebase-hooks/auth - signInWithEmailAndPassword
   * @returns
   */
  const handleLogin = () => signInWithEmailAndPassword(email, password);

  /**
   * Login handler if the user presses the "Enter" key within the form -- calls handleLogin()
   * @param {*} ev
   */
  const handleEnterKey = (ev) => {
    if (ev.key === "Enter") {
      handleLogin();
    }
  };

  // return for the page
  return (
    <Container fluid className={styles.loginContainerWrapper}>
      <Container className={styles.headerContainer}>
        <h1>Welcome to Project SEED: Login</h1>
      </Container>

      <Container className={styles.loginContainer}>
        {(authenticateError || authorizeError) && (
          <Container className={styles.errorContainer}>
            Could not log in with the given credentials. Please check you've
            correctly entered your email and password, then try again.
          </Container>
        )}
        <Form onKeyUp={handleEnterKey} className={styles.loginForm}>
          <Container fluid className={styles.loginItemContainer}>
            <FloatingLabel label="Email address">
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
            </FloatingLabel>
          </Container>
          <Container fluid className={styles.loginItemContainer}>
            <FloatingLabel label="Password">
              <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </FloatingLabel>
          </Container>

          <Button variant="primary" onClick={handleLogin}>
            Log in
          </Button>
        </Form>
        <Container>
          <Nav.Link as={Link} to="/reset-password">
            Forgot password?
          </Nav.Link>
        </Container>
      </Container>
    </Container>
  );
};

export default Login;
