import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const getIsTrainer = (user) =>
  axios.post(
    SERVER_URL + "/api/info/is_trainer",
    {
      user,
      withCredentials: true,
    }
  )

export const getValidTrainings = async (user) => 
  await axios.post(
    SERVER_URL + "/api/info/get_trainings",
    {
      user,
      withCredentials: true,
    }
  )
