export const TrainingOpt = {
  GAV:             "GAV Training",
  GAV_E:           "GAV Exam",
  GAV_M:           "GAV Mock",
  GAV_T:           "GAV Trainer Exam",
  HV:              "HV Training",
  HV_E:            "HV Exam",
  HV_M:            "HV Mock",
  HV_T:            "HV Trainer Exam",
  REDCAP_1:        "Redcap 1 Training",
  REDCAP_2:        "Redcap 2 Training",
  REDCAP_T:        "Redcap 2 Trainer Training",
  DAILY:           "Daily - Training",
  DAILY_T:         "Daily - Trainer Training",
  TRANSCRIPTION_E: "Transcription - Training",
  TRANSCRIPTION_T: "Transcription - Trainer Exam",
  SURVEY_1:        "Survey - 1 Training",
  SURVEY_2:        "Survey - 2 Training",
  SURVEY_E:        "Survey - MC Exam",
  SURVEY_M:        "Survey - Mock",
  SURVEY_T:        "Survey - Trainer Exam",
};

export const OnlyTrainingOpt = {
  GAV:             "GAV Training",
  HV:              "HV Training",
  REDCAP_1:        "Redcap 1 Training",
  REDCAP_2:        "Redcap 2 Training",
  DAILY:           "Daily - Training",
  TRANSCRIPTION_E: "Transcription - Training",
  SURVEY_1:        "Survey - 1 Training",
  SURVEY_2:        "Survey - 2 Training",
};

