import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const submitCreateSchedule = (user, form) =>
  axios.post(
    SERVER_URL + "/api/scheduler/create",
    { 
      user, form
    },
    {
      withCredentials: true,
    }
  );

export const submitEditSchedule = (user, form, eventID, source, checked) =>
  axios.post(
    SERVER_URL + "/api/scheduler/edit_avail",
    { 
      user, form, eventID, source, checked
    },
    {
      withCredentials: true,
    }
  );

export const submitEditConfirm = (user, form, eventID, source, checked) =>
    axios.post(
      SERVER_URL + "/api/scheduler/edit_confirm",
      { 
        user, form, eventID, source, checked
      },
      {
        withCredentials: true,
      }
    );

export const submitPendingChange = (eventID) =>
      axios.post(
        SERVER_URL + "/api/scheduler/confirm_pending",
        { 
          eventID
        },
        {
          withCredentials: true,
        }
      );

export const submitRejectingChange = (eventID, user) =>
      axios.post(
        SERVER_URL + "/api/scheduler/reject_pending",
        { 
          eventID,
          user
        },
        {
          withCredentials: true,
        }
      );

// export const helperServerFunction = () =>
//   axios.post(
//     SERVER_URL + "/api/scheduler/helper",
//     {
//       withCredentials: true,
//     }
//   );

export const claimEvent = (event, trainerEmail, trainingOpt, daytimeOpt) =>
  axios.post(
    SERVER_URL + "/api/scheduler/claim",
    {
      event,
      trainerEmail,
      trainingOpt,
      daytimeOpt
    },
    {
      withCredentials: true,
    }
  )

export const getConfirmedLogs = (event, accept, trainerEmail, option) =>
  axios.post(
    SERVER_URL + "/api/scheduler/logs",
    {
      withCredentials: true,
    }
  )


  
