import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";

import NavBar from "../components/NavBar";

import { AiOutlinePlus } from "react-icons/ai";
import { IoTrashBin } from "react-icons/io5";

import { NavigateRoutes } from "../constants/routes";

import {
  tokenIsValid,
  getTokenExpirationDate,
  decode,
  userIsAdmin,
} from "../utils/token";

import {
  getValidTrainings
} from "../api/Trainer.js"

import { 
  // helperServerFunction, 
  submitCreateAvailability 
} from "../api/Scheduler_Trainer";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import redcap_styles from "../apps/redcap-perms/styles/Main.module.css";

import { Button } from "react-bootstrap";

import Popup from 'reactjs-popup';

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const ScheduleTrainerPage = () => {

  // Need to connect it to some kind of state:
  // do so here:
  // https://www.robinwieruch.de/react-checkbox/
  const getNextTwoWeeks = () => {
    const dates = [];
    var num_weeks = 3;

    var today = new Date();

    for(var i = 0; i < 7 * num_weeks; i++){
        console.log(today.getMonth())

        var cur_month = today.getMonth() + 1
        var str_month = cur_month.toString()

        var cur_day = today.getDate().toString();
        var cur_year = today.getFullYear().toString();
        
        dates[i] = str_month.concat("/", cur_day, "/", cur_year);
        today.setDate(today.getDate() + 1);
    }

    return dates
  };

  const get24Hours = () => {
    const times = [];
    let idx = 0;
    for(var i = 0; i < 24; i++){
      for(var j = 0; j < 2; j++){
          let temp = i;
          let hour = "";
          let time_of_day = "am";
          let minutes = "00";

          if(j === 1){
            minutes = "30";
          }
    
          if(i > 12){
            time_of_day = "pm";
            temp -= 12;
          } else if (i === 12){
            time_of_day = "pm";
          }
    
          if(temp < 10){
            hour = "0".concat(temp.toString());
          } else {
            hour = temp.toString();
          }
    
          times[idx] = hour.concat(":", minutes, " ", time_of_day);
          idx += 1;
        }
      }

    return times;
  };

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  // static method - returns a new user entry corresponding to a row
  const getNewUserEmptyDayTime = () => {
    return {
      day: getNextTwoWeeks()[0],
      time: get24Hours()[0],
    };
  };

  const getNewUserEmptyTraining = () => {
    return {
      training: "None"
    };
  };

  const [formInfo, setFormInfo] = useState();
  
  const [trainOptions, setTrainOptions] = useState([]);


  // Hooks for table
  const [userDataMapDayTime, setUserDataMapDayTime] = useState({ 0: getNewUserEmptyDayTime() });

  const [userDataMapTraining, setUserDataMapTraining] = useState({ 0: getNewUserEmptyTraining() });

  const [tableRowMapDayTime, setTableRowMapDayTime] = useState({}); // keeps track of display components for adding/removing
  const [tableRowMapTraining, setTableRowMapTraining] = useState({}); // keeps track of display components for adding/removing
  const [tableRowsToDisplayDayTime, setTableRowsToDisplayDayTime] = useState([]); // actual display components
  const [tableRowCounterDayTime, setTableRowCounterDayTime] = useState(0); // use to keep track of all new rows created (only increment)

  const [tableRowsToDisplayTraining, setTableRowsToDisplayTraining] = useState([]); // actual display components
  const [tableRowCounterTraining, setTableRowCounterTraining] = useState(0); // use to keep track of all new rows created (only increment)

  const handleRowEnterDay = (e, id) => {
    // update the data object
    const currNewUserData = userDataMapDayTime;
    currNewUserData[id].day = e.target.value;
    setUserDataMapDayTime(currNewUserData);
  };

  const handleRowEnterTime = (e, id) => {
    // update the data object
    const currNewUserData = userDataMapDayTime;
    currNewUserData[id].time = e.target.value;
    setUserDataMapDayTime(currNewUserData);
  };

  const handleRowEnterTraining = (e, id) => {
    // update the data object
    const currNewUserData = userDataMapTraining;
    currNewUserData[id].training = e.target.value;
    setUserDataMapTraining(currNewUserData);
  };

  const handleAddRowDayTime = () => {
    console.log("IN HANDLE AddRowDayTime");
    const lastRowMap = tableRowMapDayTime;
    const counter = tableRowCounterDayTime;

    const newRow = (
      <EmptyRowDayTime key={"row_" + counter} id={counter} day={getNextTwoWeeks()[0]} time={get24Hours()[0]} />
    );
    lastRowMap[counter] = newRow;
    setTableRowMapDayTime(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplayDayTime(newRowsToDisplay);

    const currNewUserData = userDataMapDayTime;
    currNewUserData[counter] = getNewUserEmptyDayTime();
    setUserDataMapDayTime(currNewUserData);

    setTableRowCounterDayTime(counter + 1);
  };

  const handleAddRowTraining = () => {
    console.log("IN HANDLE AddRowTraining");
    const lastRowMap = tableRowMapTraining;
    const counter = tableRowCounterTraining;

    const newRow = (
      <EmptyRowTraining key={"row_" + counter} id={counter} training={"None"} options={trainOptions}/>
    );
    lastRowMap[counter] = newRow;
    setTableRowMapTraining(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplayTraining(newRowsToDisplay);

    const currNewUserData = userDataMapTraining;
    currNewUserData[counter] = getNewUserEmptyTraining();
    setUserDataMapTraining(currNewUserData);

    setTableRowCounterTraining(counter + 1);
  };

  const handleRemoveRowDayTime = (id) => {
    console.log("IN HANDLE RemoveRowDayTime");
    // remove entries in newUserData
    const lastRowMap = tableRowMapDayTime;
    const lastNewData = userDataMapDayTime;
    delete lastNewData[id];
    setUserDataMapDayTime(lastNewData);

    // remove entry in tableRowMap
    delete lastRowMap[id];
    setTableRowMapDayTime(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplayDayTime(newRowsToDisplay);
  };

  const handleRemoveRowTraining = (id) => {
    console.log("IN HANDLE RemoveRowTraining");
    // remove entries in newUserData
    const lastRowMap = tableRowMapTraining;
    const lastNewData = userDataMapTraining;
    delete lastNewData[id];
    setUserDataMapTraining(lastNewData);

    // remove entry in tableRowMap
    delete lastRowMap[id];
    setTableRowMapTraining(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplayTraining(newRowsToDisplay);
  };

  const EmptyRowDayTime = (props) => (
    <tr key={props.id}>
      <td>
        <div>
          <Form.Select onChange={(e) => handleRowEnterDay(e, props.id)}>
              {Object.values(getNextTwoWeeks()).map((opt) => (
                <option key={"day_to_meet_" + opt}>{opt}</option>
              ))}
          </Form.Select>
        </div>
      </td>
      <td>
        <div>
          <Form.Select onChange={(e) => handleRowEnterTime(e, props.id)}>
            {Object.values(get24Hours()).map((opt) => (
              <option key={"time_to_meet_" + opt}>{opt}</option>
            ))}
          </Form.Select>
        </div>
      </td>
      <td>
        {!props.first && (
          <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => handleRemoveRowDayTime(props.id)}
            >
              <IoTrashBin />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );

  const EmptyRowTraining = (props) => (
    <tr key={props.id}>
      <td>
        <div>
          <Form.Select onChange={(e) => handleRowEnterTraining(e, props.id)}>
              {Object.values(props.options).map((opt) => (
                <option key={"training_opt_" + opt}>{opt}</option>
              ))}
          </Form.Select>
        </div>
      </td>
      <td>
        {!props.first && (
          <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => handleRemoveRowTraining(props.id)}
            >
              <IoTrashBin />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );


  // Hooks
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);
  const [requestQueued, setRequestQueued] = useState(false);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // state - store user data from cookie
  const [userData, setUserData] = useState();
  const [allowChangeAccess, setAllowChangeAccess] = useState(false);

  // state - track changes
  const [changeError] = useState(false);
  const [changeErrorMessage] = useState("");

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  // PREVIOUS VERSION OF FORMINFO
  // Form information
  // const formInfo = {
  //   "training": "GAV Training",
  //   "day_to_meet": getNextTwoWeeks()[0],
  //   "time_to_meet": "00:00 am"
  // }

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    console.log("IN useEffect");
    document.title = "Project SEED Apps: Profile";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });

      const decoded = decode(cookies["token"]);

      getValidTrainings(decoded)
      .then((res) => {
        const logs = res.data;
        setTrainOptions(logs);

        const newRowDayTime = {
          0: <EmptyRowDayTime key={"row_start_daytime"} first id={0} day={getNextTwoWeeks()[0]} time={get24Hours()[0]} />
        };
    
        const newRowTraining = {
          0: <EmptyRowTraining key={"row_start_training"} first id={0} training={"None"} options={logs} />
        };
    
        setTableRowMapDayTime(newRowDayTime);
        setTableRowMapTraining(newRowTraining);
    
        const newRowsToDisplayDayTime = Object.values(newRowDayTime);
        setTableRowsToDisplayDayTime(newRowsToDisplayDayTime);
    
        const newRowsToDisplayTraining = Object.values(newRowTraining);
        setTableRowsToDisplayTraining(newRowsToDisplayTraining);

      })
      .catch((err) => console.log(err));
    }

    setTableRowCounterDayTime(1);
    setTableRowCounterTraining(1);

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - (1 * 10 * 1000)
      );

      const blankForm = () => {
        const item = {
          "training": "None"
        };
    
        return item;
      };

      setAuthTimeout(newTimeout);

      // load the user data and changed user data for future changes
      const decoded = decode(cookies["token"]);
      setUserData(decoded);
      
      const blank = blankForm();

      setFormInfo(blank);

      // set allowChangeAccess
      setAllowChangeAccess(userIsAdmin(cookies["token"]));

      setPageDoneLoading(true);
    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Handler for updating the changedUserData and changesDetected state objects
   * @param {string} attribute
   * @param {*} value
   */
  const handleChangeUser = (attribute, value) => {
    console.log("Change has been made to");
    console.log(attribute);
    console.log(value);

    // PREVIOUS VERSION
    // formInfo[attribute] = value;

    const changes = structuredClone(formInfo);
    changes[attribute] = value;
    setFormInfo(changes);
  };

  const popupConfirmation = () => (
    <Popup trigger={<button> Trigger</button>} position="right center">
      <div>Popup content here !!</div>
    </Popup>
  );

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleSubmitChanges = () => {
    // This is where we need to record the information that
    // the user submitted for the form to then send the email

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Review your changes before submitting Availability:
      </h5>,
    ];

    newModalBody.push(<h5 key="training"><b>Training(s):</b></h5>);

    for (const [idx, entry] of Object.entries(userDataMapTraining)){
      newModalBody.push(
        <h6 key = {"training_data_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"training_" + idx}>
              {entry.training}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
    }
    
    newModalBody.push(<h5 key="header"><b>Day(s) and Time(s) to Meet (in CST)</b></h5>);
    // newModalBody.push(<h5 key="day_to_meet"><b>Day to Meet:</b> {formInfo.day_to_meet}</h5>);
    // newModalBody.push(<h5 key="time_to_meet"><b>Time to Meet (CST):</b> {formInfo.time_to_meet}</h5>);
    console.log("About to push daytime entries:");
    for (const [idx, entry] of Object.entries(userDataMapDayTime)){
      newModalBody.push(
        <h6 key = {"day_time_data_" + idx}>
          <ListGroup>
            <ListGroup.Item key={"day_time_" + idx}>
              {entry.day + " " + entry.time}
            </ListGroup.Item>
          </ListGroup>
        </h6>
      );
    }

    setModalHeader("Confirm Entry");
    setModalBody(newModalBody)
    setModalBtnText("Confirm");
    setModalShow(true);
    setRequestQueued(true);
  };

  // const dummy = () => {
  //   console.log("I HAVE BEEN PRESSED");
  //   helperServerFunction();
  // };

  const handleConfirm = () => {
    console.log("rowCounterDayTime")
    console.log(tableRowCounterDayTime)
    console.log("tableRowCounter")
    console.log(tableRowCounterTraining)
    formInfo["day_times"] = new Array(tableRowCounterDayTime);
    formInfo["training"] = new Array(tableRowCounterTraining)

    for (const [idx, entry] of Object.entries(userDataMapDayTime)){
      formInfo["day_times"][idx] = entry
    }

    for (const [idx, entry] of Object.entries(userDataMapTraining)){
      formInfo["training"][idx] = entry
    }

    console.log("Final userData");
    console.log(userData)

    console.log("Final FormInfo");
    console.log(formInfo)


    setShowModalClose(false);

    // TODO
    // Make sure to catch any errors
    submitCreateAvailability(userData, formInfo)
      .then((res) => { 
                      // setTimeout(refreshPage, 2000); 
                      console.log("About to call popupConfirmation")
                      popupConfirmation();
                    })
      .catch((err) => console.log(err));
    
    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Training Event has been successfully created.
      </p>
      <p>This page will now redirect to the home screen in three (3) seconds.</p>
    </Container>
    );
    setModalBtnText("Close");
    setTimeout(redirectHome, 3000);
    setModalShow(true);
    setShowModalClose(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Trainer Scheduling Details</h1>
            <h2>(Person offering Training)</h2>
          </Container>
          <Container className={styles.sectionContainer}>
            {userData && (
              <Container>
                
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={!allowChangeAccess}
                      type="text"
                      defaultValue={userData.name}
                      onChange={(e) => handleChangeUser("name", e.target.value)}
                    />
                  </Container>
                </Container>

                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={!allowChangeAccess}
                      type="text"
                      defaultValue={userData.email}
                      onChange={(e) =>
                        handleChangeUser("email", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>UT EID</Container>
                  <Container>
                    <Form.Control
                      disabled={!allowChangeAccess}
                      type="text"
                      defaultValue={userData.uteid}
                      onChange={(e) =>
                        handleChangeUser("uteid", e.target.value)
                      }
                    />
                  </Container>
                </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={redcap_styles.container}>
              <Container className={redcap_styles.functionContainer}>
                <h4>Select the trainings that you are qualified for</h4>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Training</th>
                    </tr>
                  </thead>
                  <tbody>{tableRowsToDisplayTraining}</tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <div>
                        <Button
                          size="sm"
                          variant="dark"
                          className={redcap_styles.addRowBtn}
                          onClick={handleAddRowTraining}
                        >
                          <AiOutlinePlus />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                </Table>
              </Container>
            </Container>
            <Container className={redcap_styles.container}>
              <Container className={redcap_styles.functionContainer}>
                <h4>Select day and time(s) that you are available</h4>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Day Available</th>
                      <th>Time Available (in CST)</th>
                    </tr>
                  </thead>
                  <tbody>{tableRowsToDisplayDayTime}</tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <div>
                        <Button
                          size="sm"
                          variant="dark"
                          className={redcap_styles.addRowBtn}
                          onClick={handleAddRowDayTime}
                        >
                          <AiOutlinePlus />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                </Table>
              </Container>
            </Container>
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="warning"
                  onClick={handleSubmitChanges}
                >
                  Submit Availability
                </Button>
                {/* <Button
                  variant="primary"
                  onClick={
                    dummy
                  }
                >
                  {"temp Button"}
                </Button> */}
              </Container>
            </Container>
          </Container>
        </Container>
      )}
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      backdrop={showModalClose ? undefined : "static"}
    >
      <Modal.Header closeButton={showModalClose}>
          <Modal.Title>{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {showModalClose && (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={
                requestQueued ? handleConfirm : () => setModalShow(false)
              }
            >
              {modalBtnText}
            </Button>
          </Modal.Footer>
        )}
    </Modal>
    </div>
  );
};

export default ScheduleTrainerPage;