import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

// bootstrap imports
import Container from "react-bootstrap/Container";

import { Button } from "react-bootstrap";

// app-specific
import Table from "../components/Table";
import Modal from "react-bootstrap/Modal";
import NavBar from "../components/NavBar";
import { NavigateRoutes } from "../constants/routes";

import { IoTrashBin, IoInformationCircleOutline  } from "react-icons/io5";

// utils
import { 
  userIsAdmin,
  getTokenExpirationDate 
} from "../utils/token";

import { 
  getHelpLogs,
  resolveEvent
} from "../api/Help.js"


// custom page styling
import styles from "../styles/Admin.module.css";

/**
 * Page component for displaying the Admin page
 * @returns React.FC
 */
const HelpDashboardPage = () => {
  // state - track new users
  
  // state - confirm logs
  const [log, setLog] = useState([]);

  // state - get user info

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // Modal information
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(false);

  const [eventToResolve, setEventToResolve] = useState("");

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies] = useCookies(["token", "firebaseToken"]);

  const handleResolve= () => {
    resolveEvent(eventToResolve)
      .then((res) => {
        console.log("Got the following response");
        console.log(res)
      })
      .catch((err) => console.log(err));
    
    let id_to_delete = -1;
    for (const [idx, entry] of Object.entries(log)){
      console.log(idx);
      console.log(entry);

      if (entry.event_id === eventToResolve){
        id_to_delete = idx;
        break;
      }
    }

    const tempItems = log;
    delete tempItems[id_to_delete];
    setLog(tempItems);

    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Issue has been successfully resolved.
      </p>
    </Container>
    );
    
    setModalShow(true);
    setShowModalClose(false);
  };

  const resolveConfirmation = (info) => {
    console.log("IN DELETE CONFIRMATION");
    console.log(info);
    
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Double Check information before submitting as resolved.
      </h5>,
    ];
 
    newModalBody.push(<h5 key="Trainer_Info"><b>Submitter information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Name:&nbsp;</b></td>
          <td>{info.user.name}</td>
        </tr>
        <tr>
          <td><b>Email:&nbsp;</b></td>
          <td>{info.user.email}</td>
        </tr>
      </table>
    );

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event_Info"><b>Issue Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Subject: </b></td>
          <td>{info.subject}</td>
        </tr>
        <tr>
          <td><b>Message:&nbsp;</b></td>
          <td>{info.message}</td>
        </tr>
      </table>
    );
    

    setEventToResolve(info.event_id);

    setModalHeader("Resolving Issue");
    setModalBody(newModalBody);
    setShowModalClose(true);
    setModalBtnText("Confirm Issue to Resolve");
    setModalShow(true);
  };

  const infoLog = (info) => {
    console.log("IN INFO SCHEDULER CONFIRM");
    console.log(info);

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<h5 key="Trainer_Info"><b>Submitter Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Name:&nbsp;</b></td>
          <td>{info.user.name}</td>
        </tr>
        <tr>
          <td><b>Email:&nbsp;</b></td>
          <td>{info.user.email}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event_Info"><b>Issue Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Subject:&nbsp;</b></td>
          <td>{info.subject}</td>
        </tr>
        <tr>
          <td><b>Message:&nbsp;</b></td>
          <td>{info.message}</td>
        </tr>
        <tr>
          <td><b>Time Created:&nbsp;</b></td>
          <td>{info.time_created}</td>
        </tr>
        <tr>
          <td><b>Resolved:&nbsp;</b></td>
          {
            info.resolved && (
              <td>Resolved</td>
            )
          }
          {
            !info.resolved && (
              <td>Not Resolved</td>
            )
          }
        </tr>
      </table>
    );

    setModalHeader("Issue More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=HOME"),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Help Issue Dashboard";

    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // parse out the token from the server
      const auth = userIsAdmin(cookies["token"]);
      setAuthorized(auth);

      // get the training logs
      getHelpLogs()
      .then((res) => {
        const logs = res.data;
        setLog(logs);
      })
      .catch((err) => console.log(err));

      // TODO NEED TO GET THE TRAININGS


      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );

      setAuthTimeout(newTimeout);
    }

    setPageDoneLoading(true);

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  // =================================
  // -- Button handlers --
  // =================================

  /**
   * Handler for deleting a single user, shows modal to prompt user to confirm before proceeding
   * @param {object} user
   */

  /**
   * Assembles a table row with logged history of a REDCap Permissions submit request
   * @param {*} props
   * @returns Customized <tr /> component
   */

  // EDIT TO INCLUDE ALL INFO
  const LogRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>{record.time_created}</td>
        <td>{record.user.name}</td>
        {
          !record.resolved && (
            <td>Not Resolved</td>
          )
        }
        {
          record.resolved && (
            <td>Resolved</td>
          )
        }
        <td>
          {
            <div>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                resolveConfirmation(record);
              }}
            >
              <IoTrashBin />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoLog(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Help Issues Dashboard</h1>
          </Container>
          {/* TODO FINISH THIS */}

          {(log.length > 0) && (<Container className={styles.sectionContainer}>
            <Table
              tableName={`Help Issues`}
              columns={[
                "Date Created",
                "Name of Who Submitted",
                "Resolved",
                "Resolve Issue",
                "More Info"
              ]}
              rows={log.map((record) => (
                <LogRow
                  record={record}
                  key={"logrecord" + JSON.stringify(record)}
                />
              ))}
            />
          </Container>)}

          {(log.length === 0) && (
            <h3>No Issues to report.</h3>
          )}
        </Container>
      }
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop={undefined}
      >
        <Modal.Header closeButton={true}>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          {
            showModalClose && (
              <Modal.Footer>
              <Button
                variant="primary"
                onClick={ handleResolve
                }
              >
                {modalBtnText}
              </Button>
            </Modal.Footer>
            )
          }
      </Modal>

    </div>
  );
};

export default HelpDashboardPage;
