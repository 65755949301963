import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Pagination } from "react-bootstrap"

import Container from "react-bootstrap/Container";

import Table from "../components/Table";
import NavBar from "../components/NavBar";
import Spinner from 'react-bootstrap/Spinner';
import Modal from "react-bootstrap/Modal";

import { IoInformationCircleOutline  } from "react-icons/io5";

import { NavigateRoutes } from "../constants/routes";

import {
  tokenIsValid,
  getTokenExpirationDate,
  decode
} from "../utils/token";

import {  
  getConfirmVol 
} from "../api/Mock.js";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import { Button } from "react-bootstrap";

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const VolunteerHubPage = () => {

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const [confirmVol, setConfirmVol] = useState([]);
  const [confirmVolLoading, setConfirmVolLoading] = useState(false);
  const [confirmVolCurPage, setConfirmVolCurPage] = useState(1);
  const [confirmVolTotalPage, setConfirmVolTotalPage] = useState();

  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  // PREVIOUS VERSION OF FORMINFO
  // Form information
  // const formInfo = {
  //   "training": "GAV Training",
  //   "day_to_meet": getNextTwoWeeks()[0],
  //   "time_to_meet": "00:00 am"
  // }

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    if (!authorized && confirmVolLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, confirmVolLoading, redirectLogin]);
  
  useEffect(() => {
    document.title = "Project SEED Apps: Profile";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();

      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - (1 * 10 * 1000)
      );
      
       setAuthTimeout(newTimeout);
    }
  }, [cookies, reauthorized]);



  useEffect(() => {
    if(reauthorized){
      // Now get the trainer info
      const decoded = decode(cookies["token"]);
      
      setConfirmVolLoading(false);
      getConfirmVol(decoded, confirmVolCurPage, 10)
      .then((res) => {
        const logs = res.data.data;
        const size = res.data.totalPage;
        setConfirmVol(logs);
        setConfirmVolTotalPage(size);
        setConfirmVolLoading(true);
      })
      .catch((err) => console.log(err));

    }
  }, [reauthorized, cookies, confirmVolCurPage]);

  function MyPagination({total, current, onChangePage}) {
    let items = []

    if(current > 1){
      items.push(<Pagination.Prev key="prev" onClick={() => onChangePage(current - 1)} />)
    }

    if(total < 10){
        for (let page = 1; page <= total; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
          );
      }
    } else {
      if(current <= 3){
          for (let page = 1; page <= 5; page++) {
            items.push(
                <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                    {page}
                </Pagination.Item>
            );
        }

        items.push(<Pagination.Ellipsis />);
      } else if(current >= (total - 2)){
        <Pagination.Ellipsis />
        for (let page = total-4; page <= total; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
            );
        }
      } else {
        items.push(<Pagination.Ellipsis />);

        for (let page = current-2; page <= current+2; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
            );
        }

        items.push(<Pagination.Ellipsis />);
      }
    }

    if (current < total) {
        items.push(<Pagination.Next key="next" onClick={() => onChangePage(current + 1)} />)
    }

    return (
      <Pagination>
        {items}
      </Pagination>
    );
  };

  const handleChangeConfirmVol = useCallback((page) => {
    setConfirmVolCurPage(page);
  }, [])

  const ConfirmVolRow = (props) => {
    const record = props.record;
    return (
      <tr key={props.key}>
        {/* <td>{record.timestamp}</td> */}
        <td>{record.form.day_to_meet}</td>
        <td>{record.form.time_to_meet}</td>
        <td>{record.form.training}</td>
        <td>{record.volunteer}</td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoConfirmVol(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const infoConfirmVol = (info) => {
    const newModalBody = [
        <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
            More Information:
        </h5>,
    ];

    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Form_Info"><b>Form Info</b></h5>);

    newModalBody.push(
        <table>
          <tr>
            <td><b>Event Id:&nbsp;</b></td>
            <td>{info.confirmID}</td>
          </tr>
          <tr>
            <td><b>Training Type:&nbsp;</b></td>
            <td>{info.form.training}</td>
          </tr>
          <tr>
            <td><b>Day to meet:&nbsp;</b></td>
            <td>{info.form.day_to_meet}</td>
          </tr>
          <tr>
            <td><b>Time to meet:&nbsp;</b></td>
            <td>{info.form.time_to_meet}</td>
          </tr>
          <tr>
            <td><b>Has a volunteer:&nbsp;</b></td>
            <td>{info.volunteer}</td>
          </tr>
          <tr>
            <td><b>Accept Link:&nbsp;</b></td>
            <td><a href={info.link}>Accept</a></td>
          </tr>
        </table>
      );

      newModalBody.push(<br></br>);
      newModalBody.push(<h5 key="User_Info"><b>Trainee Info</b></h5>);

      newModalBody.push(
        <table>
          <tr>
            <td><b>Name:&nbsp;</b></td>
            <td>{info.user.name}</td>
          </tr>
          <tr>
            <td><b>Phone:&nbsp;</b></td>
            <td>{info.user.phone}</td>
          </tr>
          <tr>
            <td><b>Email:&nbsp;</b></td>
            <td>{info.user.email}</td>
          </tr>
        </table>
      );

      newModalBody.push(<br></br>);
      newModalBody.push(<h5 key="User_Info"><b>Trainer Info</b></h5>);

      newModalBody.push(
        <table>
          <tr>
            <td><b>Name:&nbsp;</b></td>
            <td>{info.trainer.name}</td>
          </tr>
          <tr>
            <td><b>Phone:&nbsp;</b></td>
            <td>{info.trainer.phone}</td>
          </tr>
          <tr>
            <td><b>Email:&nbsp;</b></td>
            <td>{info.trainer.email}</td>
          </tr>
        </table>
      );

      setModalHeader("Confirmed Mock Training More Info");
      setModalBody(newModalBody);
      setModalShow(true);

  };

  // return for the page
  return (
    <div>
      <NavBar />
      {
        <Container className={styles.pageContainer}>
        <Container>
          <h1>Volunteer Hub</h1>
        </Container>

        <Container className={styles.sectionContainer}>
            {
              (confirmVolLoading && (confirmVol.length > 1)) && (
              <div>
              <Table
                tableName={`Currently Confirmed Mock Trainings`}
                headers={[
                  { title: "Event Information", colspan: 7 }
                ]}
                columns={[
                  "Day to Meet",
                  "Time to Meet",
                  "Mock Type",
                  "Has Volunteer",
                  "More Info"
                ]}
                rows={confirmVol.map((record) => (
                  <ConfirmVolRow
                    record={record}
                    key={"traineerecord" + JSON.stringify(record)}
                  />
                ))}
              />
              {
                (confirmVolTotalPage > 1) && (
                  <MyPagination
                    total={confirmVolTotalPage}
                    current={confirmVolCurPage}
                    onChangePage={handleChangeConfirmVol}
                    />
                  )
              }
              </div>
              )
            }
            {
              (confirmVolLoading && confirmVol.length === 0) && (
                <div>
                  <h3>Currently Confirmed Mock Trainings</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !confirmVolLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
        </Container>
      }
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop={undefined}
      >
        <Modal.Header closeButton={true}>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
    </div>
  );
};

export default VolunteerHubPage;