export const ReactRoutes = {
  ROOT: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  HOME: "/home",
  ADMIN: "/admin",
  SIGNUP: "/signup",
  COMPLETE_SIGNUP: "/signup-complete",
  REDCAP_PERMS_PARENT: "/redcap-perms/*",
  REDCAP_PERMS_DASHBOARD: "/",
  REDCAP_PERMS_SETTINGS: "/settings",
  SCHEDULER_PARENT: "/scheduler/*",
  SCHEDULER_RESPONSE: "/response",
  SCHEDULER_TRAINER_PARENT: "/schedule-trainer/*",
  SCHEDULER_TRAINER_RESPONSE: "/trainer-response",
  SCHEDULER_DASHBOARD: "dashboard",
  SCHEDULER_SCHEDULE: "schedule",
  SCHEDULER_BYPASS: "/scheduler-bypass",
  SIGNOUT: "/signout",
  PROFILE: "/profile",
  HUB: "/hub",
  VOLUNTEERHUB: "/volunteer-hub",
  VOLUNTEER_RESPONSE: "/volunteer-response",
  CHECKLIST: "/checklist",
  HELP: "/help",
  HELPDASHBOARD: "/help-dash",
  NEEDTOKNOW: "/need-to-know",
  EDITS: "/edit",
  CONFIRMEDITS: "/confirm-edit",
  EDITRESPONSE: "/edit-response",
  EXISTINGTRAINING: "/existing-training"
};

export const NavigateRoutes = {
  ROOT: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  HOME: "/home",
  ADMIN: "/admin",
  SIGNUP: "/signup",
  COMPLETE_SIGNUP: "/signup-complete",
  REDCAP_PERMS: "/redcap-perms",
  REDCAP_PERMS_DASHBOARD: "/redcap-perms/",
  REDCAP_PERMS_SETTINGS: "/redcap-perms/settings",
  SCHEDULER_PARENT: "/scheduler",
  SCHEDULER_DASHBOARD: "/scheduler/dashboard",
  SCHEDULER_SCHEDULE: "/scheduler/schedule",
  SCHEDULER_TRAINER_PARENT: "/schedule-trainer",
  SCHEDULER_TRAINER_SCHEDULE: "/schedule-trainer/schedule",
  SCHEDULER_BYPASS: "/scheduler-bypass",
  SIGNOUT: "/signout",
  PROFILE: "/profile",
  HUB: "/hub",
  VOLUNTEERHUB: "/volunteer-hub",
  VOLUNTEER_RESPONSE: "/volunteer-response",
  CHECKLIST: "/checklist",
  HELP: "/help",
  HELPDASHBOARD: "/help-dash",
  NEEDTOKNOW: "/need-to-know",
  EDITS: "/edit",
  CONFIRMEDITS: "/confirm-edit",
  EDITRESPONSE: "/edit-response",
  EXISTINGTRAINING: "/existing-training"
};

