import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { claimEvent} from "../api/Scheduler";
import { NavigateRoutes } from "../constants/routes";

// import NavBar from "../components/NavBar";

import styles from "../styles/Login.module.css";

/**
 * Page component displaying the signup items for a custom signup link
 * @returns React.FC
 */
const ScheduleResponse = () => {
  // state - read url params - ?redirect
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [eventID] = useState(queryParams.get("event"));
  const [acceptResponse] = useState(queryParams.get("accept"));
  const [trainerEmail] = useState(queryParams.get("email"));
  const [trainingOption] = useState(queryParams.get("train_opt"));
  const [dayTimeOption] = useState(queryParams.get("time_opt"));
  const [confirmed, setConfirmed] = useState();

  const navigate = useNavigate();

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  useEffect(() => {
    console.log("CLAIM EVENT ACTIVATED");
    claimEvent(
      eventID, 
      acceptResponse, 
      trainerEmail, 
      dayTimeOption,
      trainingOption).then((res) => {setConfirmed(res.data);})
  }, [eventID, acceptResponse, trainerEmail, trainingOption, dayTimeOption]);
  
  useEffect(() => {
    document.title = "Project SEED Apps: Response";
  }, []);

  // return for the page
  return (
    <div>
      {/* <NavBar /> */} {/* TODO make sure to modify NavBar to not require permissions */}
      <Container fluid className={styles.loginContainerWrapper}>
        <Container className={styles.headerContainer}>
          <h1>Project SEED Apps Platform: Response Page</h1>
        </Container>

        <h3>
          Thank you for your response to the scheduling event!
        </h3>

        {(acceptResponse === "1") && (
          <p>
            You have <mark>accepted</mark>!
          </p>
        )}

        {(acceptResponse === "1") && (confirmed) && (<p> <mark>And the event has been confirmed!</mark> Check your email for a final confirmation email and thank you for scheduling with the portal! </p>)}
        {(acceptResponse === "1") && (!confirmed) && (<p> <mark>However, someone has already accepted this event.</mark> Thank you for trying to schedule this event but keep an eye out for more events to accept.</p>)}

        

        {(acceptResponse === "0") && (
          <p>
            You have <mark>declined</mark>! Thank you for your response!
          </p>
        )}


        <p>
          Please return to the home page to continue.
        </p>

        <Container className={styles.loginContainer}>
          <Form className={styles.loginForm}>
            <Button onClick={redirectHome}>Back to Home Page</Button>
          </Form>
        </Container>
      </Container>
    </div>
  );
};

export default ScheduleResponse;