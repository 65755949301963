export const DateOptions = {
  LONG: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  },
};

export const DateLocale = "en-us";
