import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";

import NavBar from "../components/NavBar";

import { NavigateRoutes } from "../constants/routes";

import {
  tokenIsValid,
  getTokenExpirationDate
} from "../utils/token";


// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";


/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const NeedToKnowPage = () => {

  // Need to connect it to some kind of state:
  // do so here:
  // https://www.robinwieruch.de/react-checkbox/


  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);


  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  // PREVIOUS VERSION OF FORMINFO
  // Form information
  // const formInfo = {
  //   "training": "GAV Training",
  //   "day_to_meet": getNextTwoWeeks()[0],
  //   "time_to_meet": "00:00 am"
  // }

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Need To Know";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      console.log(now);
      console.log(exp);
      console.log(exp - now);
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - (1 * 10 * 1000)
      );

      setAuthTimeout(newTimeout);

      // load the user data and changed user data for future changes


      setPageDoneLoading(true);
    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Need to Know</h1>
            <h4>This page is to inform you of things that you may need as you continue throughout the semester.</h4>
            <hr></hr>
          </Container>
          
          <Container className={styles.pageContainer}>
            <h3>6 Mandatory Meetings/Makeup Meetings</h3>
            <Container className={styles.attributeContainerText}>
              <p>In the case that you miss a meeting and need to make up the meeting. Make sure to email: <a href= "mailto:utprojectseed14@gmail.com">utprojectseed14@gmail.com</a> to figure out the correct time to make up the time. If you do not get a response make sure to call: (713) 822-0117</p>
            </Container>
          </Container>
          <Container className={styles.pageContainer}>
            <h3>Social Calendar</h3>
            <Container className={styles.attributeContainerText}>
              <p>If you are interested in seeing the social calendar please email: <a href="mailto:utprojectseed14@gmail.com">utprojectseed14@gmail.com</a>.</p>
            </Container>
          </Container>
          <Container className={styles.pageContainer}>
            <h3>Interest Group Meetings and Role Evaluations</h3>
            <Container className={styles.attributeContainerText}>
              <p><ul>
                <li>Make sure to finish 2 Role Evaluations</li>
                <li>Make sure to finish the 3 interest group meetings (listed <a href="https://docs.google.com/spreadsheets/d/13SUhsMObJBp1NHgamuvhhtsuftp_nUvzkN4WZJq3jnA/edit#gid=0">HERE</a>)</li>
                <li>Make sure to email <a href="mailto:kmlabhdfs10@gmail.com">kmlabhdfs10@gmail.com</a> if you have any questions</li>
                  <ul><li>Note, if you're not getting emails from this account then you have fully met the requirements</li></ul>
                </ul></p>
            </Container>
          </Container>
          <Container className={styles.pageContainer}>
            <h3>Other Information for All RAs</h3>
            <Container className={styles.attributeContainerText}>
              <p><ul>
                <li>Wondering about your grade in the class?</li>
                <ul><li>It will be an A unless there is a grade deduction on your timesheet</li></ul>
                <li>The following is the link to the Notion Page: <a href="https://recondite-spider-969.notion.site/UT-Project-SEED-Home-Page-
c86717a5ca1942488f59644731b05af1">HERE</a>.</li>
                <li>Any RA that wants to change their position (field to office or office to field) should contact Dr. Kim.</li>
                <li>Dr. Kim's email is: <a href="mailto:sykim@prc.utexas.edu">sykim@prc.utexas.edu</a>.</li>
                </ul></p>
            </Container>
          </Container>          
        </Container>
      )}
    </div>
  );
};

export default NeedToKnowPage;