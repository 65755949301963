import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import firebase from "../Firebase";
import { getAuth } from "firebase/auth";
import {
  useUpdateEmail,
  useSendPasswordResetEmail,
} from "react-firebase-hooks/auth";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import NavBar from "../components/NavBar";

import { AppAccess, AdminAccess, LangOptions } from "../constants/apps";
import { NavigateRoutes } from "../constants/routes";
import { EmailRegex } from "../constants/regex";
import { clientUrl } from "../constants/client";

import { refreshPage } from "../utils/components";
import {
  tokenIsValid,
  getTokenExpirationDate,
  decode,
  userIsAdmin,
} from "../utils/token";

import { updateUser } from "../api/Users";
import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";
import { Button } from "react-bootstrap";

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const ProfilePage = () => {
  const auth = getAuth(firebase);
  const [updateEmail] = useUpdateEmail(auth);
  const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);
  const [sentResetEmail, setSentResetEmail] = useState(false);

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // state - store user data from cookie
  const [userData, setUserData] = useState();
  const [allowChangeAccess, setAllowChangeAccess] = useState(false);

  // state - track changes
  const [changedUserData, setChangedUserData] = useState();
  const [changesDetected, setChangesDetected] = useState(false);
  const [changeError, setChangeError] = useState(false);
  const [changeErrorMessage, setChangeErrorMessage] = useState("");

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Profile";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );

      setAuthTimeout(newTimeout);

      // load the user data and changed user data for future changes
      const decoded = decode(cookies["token"]);
      setUserData(decoded);
      setChangedUserData(decoded);

      // set allowChangeAccess
      setAllowChangeAccess(userIsAdmin(cookies["token"]));

      setPageDoneLoading(true);
    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Handler for updating the changedUserData and changesDetected state objects
   * @param {string} attribute
   * @param {*} value
   */
  const handleChangeUser = (attribute, value) => {
    const changes = structuredClone(changedUserData);
    changes[attribute] = value;

    let isSimilar = true;
    for (let key of Object.keys(userData)) {
      if (userData[key] !== changes[key]) {
        isSimilar = false;
        break;
      }
    }

    if (isSimilar) {
      setChangesDetected(false);
    } else {
      setChangesDetected(true);
    }
    setChangedUserData(changes);
  };

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleSubmitChanges = () => {
    // check if there are any email validation errors
    if (!EmailRegex.test(changedUserData.email)) {
      setChangeError(true);
      setChangeErrorMessage(
        "Email is invalid. Please make sure you have entered your email address correctly, then try again."
      );
      return;
    }

    if (changedUserData.email !== userData.email) {
      // if the email was changed, update the email in firebase
      updateEmail(changedUserData.email);
    }

    // make backend request to update the user, then refresh
    updateUser(userData.sub, changedUserData)
      .then((res) => setTimeout(refreshPage, 3000))
      .catch((err) => console.log(err));
  };

  const handleSendResetEmail = () => {
    sendPasswordResetEmail(userData.email);
    setSentResetEmail(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Profile</h1>
          </Container>
          <Container className={styles.sectionContainer}>
            {userData && (
              <Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={userData.name}
                      onChange={(e) => handleChangeUser("name", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={userData.email}
                      onChange={(e) =>
                        handleChangeUser("email", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Phone Number
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={userData.phone}
                      onChange={(e) =>
                        handleChangeUser("phone", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>UT EID</Container>
                  <Container>
                    <Form.Control
                      disabled={!allowChangeAccess}
                      type="text"
                      defaultValue={userData.uteid}
                      onChange={(e) =>
                        handleChangeUser("uteid", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    REDCap Permissions access
                  </Container>
                  <Container>
                    <Form.Select
                      defaultValue={userData.redcapperms}
                      disabled={!allowChangeAccess}
                      onChange={(e) =>
                        handleChangeUser("redcapperms", e.target.value)
                      }
                    >
                      {Object.values(AppAccess).map((accessOption) => (
                        <option key={"redcapperms" + accessOption}>
                          {accessOption}
                        </option>
                      ))}
                    </Form.Select>
                  </Container>
                </Container>
                {/* TODO add Scheduler access when ready */}
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Platform Admin
                  </Container>
                  <Container>
                    <Form.Select
                      defaultValue={
                        userData.is_admin
                          ? AdminAccess.ADMIN
                          : AdminAccess.DEFAULT
                      }
                      disabled={!allowChangeAccess}
                      onChange={(e) =>
                        handleChangeUser("is_admin", e.target.value === "Admin")
                      }
                    >
                      {Object.values(AdminAccess).map((accessOption) => (
                        <option key={"admin" + accessOption}>
                          {accessOption}
                        </option>
                      ))}
                    </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Scheduling Language
                  </Container>
                  <Container>
                    <Form.Select
                      defaultValue={
                        userData.language
                          ? LangOptions.ENGL
                          : LangOptions.SPAN
                      }
                      disabled={!allowChangeAccess}
                      onChange={(e) =>
                        handleChangeUser("language", e.target.value)
                      }
                    >
                      {Object.values(LangOptions).map((accessOption) => (
                        <option key={"language" + accessOption}>
                          {accessOption}
                        </option>
                      ))}
                    </Form.Select>
                  </Container>
                </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="warning"
                  disabled={!changesDetected}
                  onClick={handleSubmitChanges}
                >
                  Submit Changes
                </Button>
              </Container>
            </Container>
            <Container fluid className={styles.buttonContainer}>
              <Button onClick={handleSendResetEmail}>
                Send password reset email
              </Button>
            </Container>
            {sentResetEmail && (
              <Container className={styles.sectionContainer}>
                <Container className={styles.sentEmail}>
                  Sent a link to your email ({userData.email}) to reset your
                  password. If your email exists in our records, you should
                  receive a request from
                  <Container fluid className={styles.emailAddress}>
                    noreply@{clientUrl}
                  </Container>{" "}
                  within the next hour. Check your junk inbox if you have not
                  received it.
                </Container>
              </Container>
            )}
          </Container>
        </Container>
      )}
    </div>
  );
};

export default ProfilePage;
