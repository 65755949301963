import React from "react";
import PropTypes from "prop-types";

// bootstrap imports
import Table from "react-bootstrap/Table";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const CondDropdown = (props) => {
  let name = props.name;
  let sortable = props.sortable;
  let sort_idx = props.sort_idx;
  let all_items = props.all_items;
  let handleFunctions = props.sorting_functions;
  let filters = props.cur_filter;

  if(sortable === null || sortable[sort_idx] === false){
    return (<th key={"col" + JSON.stringify(name)}>{name}</th>);
  } else {

    const items = [];
    for (const [idx, entry] of Object.entries(all_items)){

      if(filters !== null && filters.indexOf(entry) !== -1){
        items.push(
        <Dropdown.Item key={idx}
        onClick={ () => {
          handleFunctions[sort_idx](entry)
        }} active>
          {entry}
        </Dropdown.Item>)
      } else {
        items.push(
          <Dropdown.Item key={idx}
          onClick={ () => {
            handleFunctions[sort_idx](entry)
          }}>
            {entry}
          </Dropdown.Item>)
      }
    }

    return (<th key={"col" + JSON.stringify(name)}>
      <DropdownButton id="dropdown-basic-button" variant="secondary" title={name}>
        {items}
      </DropdownButton>
    </th>);
  }
}

const TableComponent = (props) => {

  let sortable = null;
  let handleFunctions = null;
  let filters = [];

  if("sortable" in props){
    sortable = props.sortable;
  }

  if("handleSorted" in props){
    handleFunctions = props.handleSorted;
  }

  if("filters" in props){
    filters = props.filters;
  }

  return (
    <>
      {props.tableNameContainer ? (
        <props.tableNameContainer>{props.tableName}</props.tableNameContainer>
      ) : (
        <h3>{props.tableName}</h3>
      )}
      <Table bordered striped responsive>
        <thead>
          {props.headers && (
            <tr>
              {props.headers.map((it) => (
                <th colSpan={it.colspan} key={"headercol" + JSON.stringify(it)}>
                  {it.title}
                </th>
              ))}
            </tr>
          )}
          <tr>
            {props.columns.map((it, idx) => (
             <CondDropdown 
             name={it} 
             sortable={sortable} 
             sort_idx={idx} 
             all_items={props.all}
             sorting_functions={handleFunctions}
             cur_filter={filters}
             />
            ))}
          </tr>
        </thead>
        <tbody valign="middle">{props.rows}</tbody>
      </Table>
    </>
  );
};

TableComponent.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableNameContainer: PropTypes.object,
  headers: PropTypes.array,
  columns: PropTypes.array.isRequired,
  sortable: PropTypes.array,
  rows: PropTypes.array,
};

export default TableComponent;
