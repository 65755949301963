import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const sendPerms = async (data) => {
  // return axios.post(SERVER_URL + "/api/redcap-perms/send-perms", data, {
  //   withCredentials: true,
  //   httpAgent: new http.Agent({ keepAlive: true }),
  //   httpsAgent: new https.Agent({ keepAlive: true }),
  // });
  // fetch api to use keepalive option
  const response = await fetch(SERVER_URL + "/api/redcap-perms/send-perms", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    keepalive: true,
  });
  return { data: response.json() };
};

export const getAllProjects = () =>
  axios.get(SERVER_URL + "/api/redcap-perms/projects", {
    withCredentials: true,
  });

export const getAllPermSets = () =>
  axios.get(SERVER_URL + "/api/redcap-perms/perm-sets", {
    withCredentials: true,
  });

export const updateFormsOneProject = (projectName) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/update-project-forms",
    { name: projectName },
    {
      withCredentials: true,
    }
  );

export const updateFormsAllProjects = () =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/update-project-forms-all",
    {},
    {
      withCredentials: true,
    }
  );

export const updateProjects = (changes) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/update-projects",
    { changes },
    { withCredentials: true }
  );

export const createProject = (projectName, projectToken, projectPermSets) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/create-project",
    { projectName, projectToken, projectPermSets },
    { withCredentials: true }
  );

export const deleteProject = (projectName) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/delete-project",
    { projectName },
    { withCredentials: true }
  );

export const createPermSet = (permSetName, permSetValues) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/create-perm-set",
    { permSetName, permSetValues },
    { withCredentials: true }
  );

export const updatePermSets = (changes) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/update-perm-sets",
    { changes },
    { withCredentials: true }
  );

export const deletePermSet = (permSetName) =>
  axios.post(
    SERVER_URL + "/api/redcap-perms/delete-perm-set",
    { permSetName },
    { withCredentials: true }
  );
