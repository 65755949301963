import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const submitCreateUser = (users) =>
  axios.post(
    SERVER_URL + "/api/users/create/flow/1",
    { users },
    {
      withCredentials: true,
    }
  );

export const submitCreateFullUser = (users) =>
  axios.post(
    SERVER_URL + "/api/users/create/flow/4",
    { users },
    {
      withCredentials: true,
    }
  );

export const getPendingUser = (id) =>
  axios.post(SERVER_URL + "/api/users/create/flow/2", { id });

export const completeSignup = (token, pendingUserId) =>
  axios.post(
    SERVER_URL + "/api/users/create/flow/3",
    { token, pendingUserId },
    {
      withCredentials: true,
    }
  );

export const completeSignupV2 = (token, pendingUserId) =>
  axios.post(
    SERVER_URL + "/api/users/create/flow/5",
    { 
      token,
      pendingUserId 
    },
    {
      withCredentials: true,
    }
  );

export const getAllUsers = () =>
  axios.get(SERVER_URL + "/api/users/all", { withCredentials: true });

export const deleteUser = (sub) =>
  axios.post(
    SERVER_URL + "/api/users/delete",
    { sub },
    { withCredentials: true }
  );

export const updateUsers = (changes, emailUpdates) =>
  axios.post(
    SERVER_URL + "/api/users/update",
    { changes, emailUpdates },
    { withCredentials: true }
  );

export const updateUser = (sub, data) =>
  axios.post(
    SERVER_URL + "/api/users/user",
    { sub, data },
    { withCredentials: true }
  );
