import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import NavBar from "../components/NavBar";

import { TrainingOpt } from "../constants/scheduler.js";

import { NavigateRoutes } from "../constants/routes";

import {
  getTokenExpirationDate,
  decode
} from "../utils/token";

import { 
  submitEditConfirm 
} from "../api/Scheduler";

import { 
  getSchedulerStatus,
  getConfirmEventInfo
} from "../api/Hub";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import redcap_styles from "../apps/redcap-perms/styles/Main.module.css";

import { Button } from "react-bootstrap";
import ToggleButton from 'react-bootstrap/ToggleButton';

import Popup from 'reactjs-popup';

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const ConfirmEditPage = () => {
  // Need to connect it to some kind of state:
  // do so here:
  // https://www.robinwieruch.de/react-checkbox/
  const getNextTwoWeeks = (first_item) => {
    const dates = [];

    if(first_item){
      dates[0] = first_item;
    }

    var num_weeks = 3;

    var today = new Date();

    for(var i = 1; i <= 7 * num_weeks; i++){
        console.log(today.getMonth())

        var cur_month = today.getMonth() + 1
        var str_month = cur_month.toString()

        var cur_day = today.getDate().toString();
        var cur_year = today.getFullYear().toString();
        
        dates[i] = str_month.concat("/", cur_day, "/", cur_year);
        today.setDate(today.getDate() + 1);
    }

    return dates
  };

  const get24Hours = () => {
    const times = [];
    let idx = 0;
    for(var i = 0; i < 24; i++){
      for(var j = 0; j < 2; j++){
          let temp = i;
          let hour = "";
          let time_of_day = "am";
          let minutes = "00";

          if(j === 1){
            minutes = "30";
          }
    
          if(i > 12){
            time_of_day = "pm";
            temp -= 12;
          } else if (i === 12){
            time_of_day = "pm";
          }
    
          if(temp < 10){
            hour = "0".concat(temp.toString());
          } else {
            hour = temp.toString();
          }
    
          times[idx] = hour.concat(":", minutes, " ", time_of_day);
          idx += 1;
        }
      }

    return times;
  };

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const handleRowEnterDay = (e) => {
    // update the data object
    setTrainingDay(e.target.value);
  };

  const handleRowEnterTime = (e) => {
    // update the data object
    setTrainingTime(e.target.value);
  };

  const handleRowEnterTraining = (e) => {
    // update the data object
    setTrainingType(e.target.value);
  };
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [eventID] = useState(queryParams.get("event"));
  const [source] = useState(queryParams.get("source"));

  // Hooks for table
  const [trainingType, setTrainingType] = useState();
  const [trainingDay, setTrainingDay] = useState();
  const [trainingTime, setTrainingTime] = useState();
  const [trainingTrainee, setTrainingTrainee] = useState({});
  const [trainingTrainer, setTrainingTrainer] = useState({});

  // Hooks
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);
  const [requestQueued, setRequestQueued] = useState(false);

  // For the email checkbox
  const [checked, setChecked] = useState(false);

  // state - track appropriate variables for whether the user is able to access this page
  const [pageDoneLoading, setPageDoneLoading] = useState(false);

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // state - store user data from cookie
  const [userData, setUserData] = useState();
  const [isScheduler, setIsScheduler] = useState(false);

  // state - track changes
  const [changeError] = useState(false);
  const [changeErrorMessage] = useState("");

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Profile";
    if (cookies["token"]) {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    getConfirmEventInfo(eventID, source).then(
      (res) => {
        const data = res.data;

        setTrainingTrainee(data["user"]);
        setTrainingTrainer(data["trainer"]);
        setTrainingDay(data["form"]["day_to_meet"]);
        setTrainingTime(data["form"]["time_to_meet"]);
        setTrainingType(data["form"]["training"]);



        setPageDoneLoading(true);
    });

    // unmount callback
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      console.log(now);
      console.log(exp);
      console.log(exp - now);

      // load the user data and changed user data for future changes
      const decoded = decode(cookies["token"]);
      setUserData(decoded);

      getSchedulerStatus(decoded).then(
        (res) => {
          const scheduler_res = res.data;
          setIsScheduler(scheduler_res);
        }
      );

    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  // TODO Potentially bring this back
  // useEffect(() => {
  //   if (!authorized && pageDoneLoading) {
  //     const newTimeout = setTimeout(() => redirectLogin(), 5000);
  //     setLogoutTimeout(newTimeout);
  //   }
  // }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Handler for updating the changedUserData and changesDetected state objects
   * @param {string} attribute
   * @param {*} value
   */
  const handleChangeTrainee = (attribute, value) => {
    console.log("Change has been made to");
    console.log(attribute);
    console.log(value);

    // PREVIOUS VERSION
    // formInfo[attribute] = value;

    const changes = structuredClone(trainingTrainee);
    changes[attribute] = value;
    setTrainingTrainee(changes);
  };

  const handleChangeTrainer = (attribute, value) => {
    console.log("Change has been made to");
    console.log(attribute);
    console.log(value);

    // PREVIOUS VERSION
    // formInfo[attribute] = value;

    const changes = structuredClone(trainingTrainee);
    changes[attribute] = value;
    setTrainingTrainer(changes);
  };

  const popupConfirmation = () => (
    <Popup trigger={<button> Trigger</button>} position="right center">
      <div>Popup content here !!</div>
    </Popup>
  );

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleSubmitChanges = () => {
    // This is where we need to record the information that
    // the user submitted for the form to then send the email

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Review your changes before submitting Event:
      </h5>,
    ];

    newModalBody.push(<h5 key="training"><b>Training:</b></h5>);
    newModalBody.push(<p><b>{trainingType}</b></p>);

    newModalBody.push(<h5 key="header"><b>Day and Time to Meet (in CST)</b></h5>);
    newModalBody.push(<h5 key="day_to_meet"><b>Day to Meet:</b> {trainingDay}</h5>);
    newModalBody.push(<h5 key="time_to_meet"><b>Time to Meet (CST):</b> {trainingTime}</h5>);

    if(checked){
      newModalBody.push(<h5 key={"day_time_info"}>
        <p><b>Delete</b> confirmation on rejection</p>
        </h5>);
    } else {
      newModalBody.push(<h5 key={"day_time_info"}>
        <p>Do <b>not delete</b> on confirmation</p>
        </h5>);
    }

    setModalHeader("Confirm Entry");
    setModalBody(newModalBody)
    setModalBtnText("Confirm");
    setModalShow(true);
    setRequestQueued(true);
  };

  const handleConfirm = () => {

    const formInfo = {};

    formInfo["day_to_meet"] = trainingDay;
    formInfo["time_to_meet"] = trainingTime;
    formInfo["training"] = trainingType;

    formInfo["trainee"] = trainingTrainee;
    formInfo["trainer"] = trainingTrainer;


    setShowModalClose(false);

    // TODO
    // Make sure to catch any errors
    submitEditConfirm(userData, formInfo, eventID, source, checked)
      .then((res) => { 
                      // setTimeout(refreshPage, 2000); 
                      console.log("About to call popupConfirmation")
                      popupConfirmation();
                    })
      .catch((err) => console.log(err));
    
    setModalHeader("Success");
    setModalBody(
      <Container>
      <p>
        Training Event has been successfully created.
      </p>
      <p>This page will now redirect to the home screen in five (5) seconds.</p>
    </Container>
    );
    setModalBtnText("Close");
    setTimeout(redirectHome, 5000); // TODO change back
    setModalShow(true);
    setShowModalClose(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Confirmation Editting Page</h1>
          </Container>
          <Container className={styles.sectionContainer}>
            {userData && (
              <Container>
                <h3>Trainer Information</h3>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={!isScheduler}
                      type="text"
                      defaultValue={trainingTrainer.name}
                      onChange={(e) => handleChangeTrainer("name", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={!isScheduler}
                      type="text"
                      defaultValue={trainingTrainer.email}
                      onChange={(e) =>
                        handleChangeTrainer("email", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <h3>Trainee Information</h3>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Full name
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={!isScheduler}
                      type="text"
                      defaultValue={trainingTrainee.name}
                      onChange={(e) => handleChangeTrainee("name", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Email address
                  </Container>
                  <Container>
                    <Form.Control
                      disabled={!isScheduler}
                      type="text"
                      defaultValue={trainingTrainee.email}
                      onChange={(e) =>
                        handleChangeTrainee("email", e.target.value)
                      }
                    />
                  </Container>
                </Container>
                <h3>Event Information</h3>
                <Container className={styles.attributeContainer}>
                  <Container className={redcap_styles.functionContainer}>
                    <h4>Select the Training you would like to switch to:</h4>
                    <Form.Select value={trainingType} onChange={(e) => handleRowEnterTraining(e)}>
                      {Object.values(TrainingOpt).map((opt) => (
                        <option key={"training_opt_" + opt}>{opt}</option>
                      ))}
                  </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={redcap_styles.functionContainer}>
                    <h4>Select the Day you're interested in switching to:</h4>
                    <Form.Select value={trainingDay} onChange={(e) => handleRowEnterDay(e)}>
                      {Object.values(getNextTwoWeeks(trainingDay)).map((opt) => (
                        <option key={"training_day_opt_" + opt}>{opt}</option>
                      ))}
                  </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={redcap_styles.functionContainer}>
                    <h4>Select the Time you're interested in switching to:</h4>
                    <Form.Select value={trainingTime} onChange={(e) => handleRowEnterTime(e)}>
                      {Object.values(get24Hours()).map((opt) => (
                        <option key={"training_time_opt_" + opt}>{opt}</option>
                      ))}
                  </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Delete confirmation on rejection?
                  </Container>
                  <Container>
                  <ToggleButton
                      className="mb-2"
                      id="toggle-check"
                      type="checkbox"
                      variant="outline-primary"
                      checked={checked}
                      value="1"
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    >
                      Yes
                    </ToggleButton>
                  </Container>
                </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="warning"
                  onClick={handleSubmitChanges}
                >
                  Submit Availability
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      backdrop={showModalClose ? undefined : "static"}
    >
      <Modal.Header closeButton={showModalClose}>
          <Modal.Title>{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {showModalClose && (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={
                requestQueued ? handleConfirm : () => setModalShow(false)
              }
            >
              {modalBtnText}
            </Button>
          </Modal.Footer>
        )}
    </Modal>
    </div>
  );
};

export default ConfirmEditPage;