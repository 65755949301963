import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const getConfirmVol = (user, cur_page, page_size) =>
  axios.post(
    SERVER_URL + "/api/mock/confirmedMocks",
    { 
      user,
      cur_page,
      page_size
    },
    {
      withCredentials: true,
    }
  );


export const claimVolunteer = (user, eventID, ref_idx, accept) =>
  axios.post(
    SERVER_URL + "/api/mock/addVolunteer",
    {
      user,
      eventID,
      accept,
      ref_idx
    },
    {
      withCredentials: true,
    }
  )