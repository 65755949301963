import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalUnauthorized = (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Unauthorized</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Your login has expired or you do not have access to this webpage.</p>
      <p>You will now be redirected to the login page in a few seconds.</p>
    </Modal.Body>
  </Modal>
);

export default ModalUnauthorized;
