import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// app routes
import RedCapPerms from "./apps/redcap-perms/App";
// import Scheduler from "./apps/scheduler/App";

// page routes
import LoginPage from "./pages/LoginPage";
import ResetPage from "./pages/ResetPage";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/AdminPage";
import SignupPage from "./pages/SignupPage";
import SignoutPage from "./pages/SignoutPage";
import ProfilePage from "./pages/ProfilePage";
import SchedulePage from "./pages/SchedulePage";
import SchedulerBypassPage from "./pages/SchedulerBypassPage";
import ScheduleResponsePage from "./pages/ScheduleResponsePage";
import ScheduleTrainerPage from "./pages/ScheduleTrainerPage";
import ScheduleTrainerResponse from "./pages/ScheduleResponseTrainerPage";
import HubPage from "./pages/HubPage";
import ChecklistPage from "./pages/ChecklistPage";
import HelpPage from "./pages/HelpPage";
import VolunteerHubPage from "./pages/MockPage";
import HelpDashboardPage from "./pages/HelpDashboardPage";
import NeedToKnowPage from "./pages/NeedToKnowPage";
import VolunteerResponse from "./pages/VolunteerResponsePage";
import EditPage from "./pages/EditPage";
import ConfirmEditPage from "./pages/ConfirmEditPage";
import EditResponsePage from "./pages/EditResponsePage";
import ExistingTrainingPage from "./pages/ExistingTrainingPage";


import { ReactRoutes } from "./constants/routes";
import React from "react";
import CompleteSignup from "./pages/CompleteSignup";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            exact
            path={ReactRoutes.COMPLETE_SIGNUP}
            element={<CompleteSignup />}
          />
          <Route exact path={ReactRoutes.LOGIN} element={<LoginPage />} />
          <Route
            exact
            path={ReactRoutes.RESET_PASSWORD}
            element={<ResetPage />}
          />
          <Route exact path={ReactRoutes.ROOT} element={<LoginPage />} />

          <Route exact path={ReactRoutes.SIGNUP} element={<SignupPage />} />
          <Route exact path={ReactRoutes.HOME} element={<HomePage />} />
          <Route
            exact
            path={ReactRoutes.REDCAP_PERMS_PARENT}
            element={<RedCapPerms />}
          />
          <Route exact path={ReactRoutes.ADMIN} element={<AdminPage />} />
          <Route exact path={ReactRoutes.PROFILE} element={<ProfilePage />} />
          <Route exact path={ReactRoutes.SIGNOUT} element={<SignoutPage />} />
          <Route exact path={ReactRoutes.SCHEDULER_PARENT} element={<SchedulePage />} />
          <Route exact path={ReactRoutes.SCHEDULER_BYPASS} element={<SchedulerBypassPage />} />
          <Route exact path={ReactRoutes.SCHEDULER_RESPONSE} element={<ScheduleResponsePage />} />
          <Route exact path={ReactRoutes.SCHEDULER_TRAINER_PARENT} element={<ScheduleTrainerPage />} />
          <Route exact path={ReactRoutes.SCHEDULER_TRAINER_RESPONSE} element={<ScheduleTrainerResponse />} />
          <Route exact path={ReactRoutes.HUB} element={<HubPage />} />
          <Route exact path={ReactRoutes.VOLUNTEERHUB} element={<VolunteerHubPage />} />
          <Route exact path={ReactRoutes.VOLUNTEER_RESPONSE} element={<VolunteerResponse />} />
          <Route exact path={ReactRoutes.CHECKLIST} element={<ChecklistPage />} />
          <Route exact path={ReactRoutes.HELP} element={<HelpPage />} />
          <Route exact path={ReactRoutes.HELPDASHBOARD} element={<HelpDashboardPage />} />
          <Route exact path={ReactRoutes.NEEDTOKNOW} element={<NeedToKnowPage />} />
          <Route exact path={ReactRoutes.EDITS} element={<EditPage />} />
          <Route exact path={ReactRoutes.CONFIRMEDITS} element={<ConfirmEditPage />} />
          <Route exact path={ReactRoutes.EDITRESPONSE} element={<EditResponsePage />} />
          <Route exact path={ReactRoutes.EXISTINGTRAINING} element={<ExistingTrainingPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;