import React, { useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useCookies } from "react-cookie";

import { AppNames, AppAccess, AppTitles } from "../constants/apps";
import { NavigateRoutes } from "../constants/routes";

import { userIsAdmin, getAppAccess } from "../utils/token";

import { IoAlertCircleOutline } from "react-icons/io5";

const NavBar = () => {
  // cookies hook
  const [cookies] = useCookies(["token"]);

  const [isAdmin] = useState(userIsAdmin(cookies["token"]));

  return (
    <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to={NavigateRoutes.HOME}>
          Project SEED Apps Platform
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Item>
              <Nav.Link eventKey="1" as={Link} to={NavigateRoutes.HOME}>
                Home
              </Nav.Link>
            </Nav.Item>
            <NavDropdown title={AppTitles.REDCAP_PERMS}>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.REDCAP_PERMS_DASHBOARD}
              >
                Dashboard
              </NavDropdown.Item>
              {getAppAccess(cookies["token"], AppNames.REDCAP_PERMS) ===
                AppAccess.ADMIN && (
                <NavDropdown.Item
                  as={Link}
                  to={NavigateRoutes.REDCAP_PERMS_SETTINGS}
                >
                  Settings
                </NavDropdown.Item>
              )}
            </NavDropdown>
            {/* NEW STUFF */}
            <NavDropdown title={AppTitles.SCHEDULING_TAB}>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.SCHEDULER_PARENT}
              >
                Seeking Training
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.SCHEDULER_TRAINER_PARENT}
              >
                Trainer Availability
              </NavDropdown.Item>
            </NavDropdown>
            {/* END NEW STUFF */}
            {/* NEW STUFF */}
            <NavDropdown title={AppTitles.PERSONAL_TAB}>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.PROFILE}
              >
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.CHECKLIST}
              >
                Training Checklist
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.HUB}
              >
                Trainings Hub
              </NavDropdown.Item>
            </NavDropdown>
            {/* END NEW STUFF */}
            {isAdmin && (
              <NavDropdown title={AppTitles.ADMIN}>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.ADMIN}
              >
                Admin Home
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={NavigateRoutes.HELPDASHBOARD}
              >
                Help Dashboard
              </NavDropdown.Item>
            </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
        <Nav>
          <Navbar.Collapse className="justify-content-end">
            <Nav.Item>
              <Nav.Link as={Link} to={NavigateRoutes.HELP}>
                <div>
                {"Submit Issue"} {<IoAlertCircleOutline />} 
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={NavigateRoutes.SIGNOUT}>
                Sign out
              </Nav.Link>
            </Nav.Item>
          </Navbar.Collapse>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBar;
