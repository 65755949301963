import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { claimEvent} from "../api/Scheduler_Trainer";
import { NavigateRoutes } from "../constants/routes";

// import NavBar from "../components/NavBar";

import styles from "../styles/Login.module.css";

/**
 * Page component displaying the signup items for a custom signup link
 * @returns React.FC
 */
const ScheduleTrainerResponse = () => {
  // state - read url params - ?redirect
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [eventID] = useState(queryParams.get("event"));
  const [acceptResponse] = useState(queryParams.get("accept"));
  const [traineeEmail] = useState(queryParams.get("email"));
  const [trainingOption] = useState(queryParams.get("train-opt"));
  const [dayTimeOption] = useState(queryParams.get("day_time-opt"));
  const [confirmed, setConfirmed] = useState();

  const navigate = useNavigate();

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  useEffect(() => {
    console.log("CLAIM EVENT ACTIVATED");
    claimEvent(eventID, acceptResponse, traineeEmail, trainingOption, dayTimeOption).then((res) => {setConfirmed(res.data);})
  }, [eventID, acceptResponse, traineeEmail, trainingOption, dayTimeOption]);
  
  useEffect(() => {
    document.title = "Project SEED Apps: Trainee Response";
  }, []);

  // return for the page
  return (
    <div>
      {/* <NavBar /> */} {/* TODO make sure to modify NavBar to not require permissions */}
      <Container fluid className={styles.loginContainerWrapper}>
        <Container className={styles.headerContainer}>
          <h1>Project SEED Apps Platform: Trainee Response Page</h1>
        </Container>

        <h3>
          Thank you for your response to the scheduling event!
        </h3>

        <h4>
        Please give at least 5 seconds before trusting the page as it takes time to verify whether you have claimed an event or not.
        </h4>

        {(acceptResponse === "1") && (
          <p>
            You have <mark>accepted</mark>!
          </p>
        )}

        {(acceptResponse === "1") && (confirmed) && (<p> <mark>And the event has been confirmed!</mark> Check your email for a final confirmation email and thank you for scheduling with the portal! </p>)}
        {(acceptResponse === "1") && (!confirmed) && (<p> <mark>However, someone has already accepted this event.</mark> Thank you for trying to claim this event but try accepting either a different time or submit your own availability.</p>)}

        

        {(acceptResponse === "0") && (
          <p>
            You have <mark>declined</mark>! Thank you for your response!
          </p>
        )}


        <p>
          Please return to the home page to continue.
        </p>

        <Container className={styles.loginContainer}>
          <Form className={styles.loginForm}>
            <Button onClick={redirectHome}>Back to Home Page</Button>
          </Form>
        </Container>
      </Container>
    </div>
  );
};

export default ScheduleTrainerResponse;