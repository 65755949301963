import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const submitCreateAvailability = (trainer, availability) =>
  axios.post(
    SERVER_URL + "/api/scheduler-training/create",
    { 
      trainer, availability
    },
    {
      withCredentials: true,
    }
  );

// export const helperServerFunction = () =>
//   axios.post(
//     SERVER_URL + "/api/scheduler-training/helper",
//     {
//       withCredentials: true,
//     }
//   );

export const claimEvent = (event, accept, traineeEmail, training_opt, day_time_opt) =>
  axios.post(
    SERVER_URL + "/api/scheduler-training/claim",
    {
      event,
      accept,
      traineeEmail,
      training_opt,
      day_time_opt
    },
    {
      withCredentials: true,
    }
  )

// export const getConfirmedLogs = (event, accept, trainerEmail, option) =>
//   axios.post(
//     SERVER_URL + "/api/scheduler-training/logs",
//     {
//       withCredentials: true,
//     }
//   )


  
