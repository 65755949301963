import axios from "axios";
import { SERVER_URL } from "../constants/server";


// Adding bs text to try to get this pushed
// TODO FIX THIS UP
export const getTraineeLogs = (user, page, num) =>
  axios.post(SERVER_URL + "/api/hub/trainee", {
    user,
    page,
    num,
    withCredentials: true,
  });

export const getTrainerLogs = (user, page, num) =>
  axios.post(SERVER_URL + "/api/hub/trainer", {
    user,
    page,
    num,
    withCredentials: true,
  });

  export const getConfirmedTrainings = (user, page, num) =>
  axios.post(SERVER_URL + "/api/hub/trainings", {
    user,
    page,
    num,
    withCredentials: true,
  });

export const getSchedulerStatus = (user) =>
  axios.post(SERVER_URL + "/api/hub/is_scheduler", {
    user,
    withCredentials: true,
  });

export const getSchedulerConfirm = (user, page, num, filters) =>
  axios.post(SERVER_URL + "/api/hub/scheduler-confirms", {
    user,
    page,
    num,
    filters,
    withCredentials: true,
  });

export const getSchedulerTrainer = (user, page, num, filters) =>
  axios.post(SERVER_URL + "/api/hub/scheduler-trainer", {
    user,
    page,
    num,
    filters,
    withCredentials: true,
  });

export const getSchedulerTrainee = (user, page, num, filters) =>
  axios.post(SERVER_URL + "/api/hub/scheduler-trainee", {
    user,
    page,
    num,
    filters,
    withCredentials: true,
  });

export const getTrainerTrainee = (user, page, num) =>
  axios.post(SERVER_URL + "/api/hub/avail_trainer", {
    user,
    page,
    num,
    withCredentials: true,
  });

export const getTraineeTrainer = (user, page, num) =>
  axios.post(SERVER_URL + "/api/hub/avail_trainee", {
    user,
    page,
    num,
    withCredentials: true,
  });

export const cancelEvent = (event, database, user) =>
  axios.post(SERVER_URL + "/api/hub/cancel-event", {
    event,
    database,
    user,
    withCredentials: true,
  });

export const getTraineeEventInfo = (event) =>
  axios.post(SERVER_URL + "/api/hub/trainee-event-info", {
    event,
    withCredentials: true,
  });

export const getTrainerEventInfo = (event) =>
  axios.post(SERVER_URL + "/api/hub/trainer-event-info", {
    event,
    withCredentials: true,
  });

export const getConfirmEventInfo = (event, source) =>
  axios.post(SERVER_URL + "/api/hub/confirm-event-info", {
    event,
    source,
    withCredentials: true,
  });

export const getPendingEventInfo = (event) =>
    axios.post(SERVER_URL + "/api/hub/pending-event-info", {
      event,
      withCredentials: true,
    });

export const getTrainingsConfirmed = (page, num, filters) =>
    axios.post(SERVER_URL + "/api/hub/confirmed-trainings", {
      page,
      num,
      filters,
      withCredentials: true,
    });


export const sendConfirmedTraining = (user, training, eventID, source) =>
    axios.post(SERVER_URL + "/api/hub/attach-training", {
      user,
      training,
      eventID,
      source,
      withCredentials: true,
    });




