import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

// bootstrap imports
import Container from "react-bootstrap/Container";

import { Button } from "react-bootstrap";

import { Pagination } from "react-bootstrap"

// app-specific
import Table from "../components/Table";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import NavBar from "../components/NavBar";
import { NavigateRoutes } from "../constants/routes";

import { 
  IoInformationCircleOutline,
  IoAddCircle 
  } from "react-icons/io5";

import { authorize } from "../api/Login";

import { OnlyTrainingOpt } from "../constants/scheduler.js";

// utils
import {
  tokenIsValid,
  decode, 
  getTokenExpirationDate 
} from "../utils/token";

import { 
  getTrainingsConfirmed,
  sendConfirmedTraining
} from "../api/Hub.js"



// custom page styling
import styles from "../styles/Admin.module.css";

/**
 * Page component for displaying the Admin page
 * @returns React.FC
 */
const ExistingTrainingPage = () => {

  // state - confirm logs scheduler
  const [confirmedTraining, setConfirmedTraining] = useState([]);
  const [confirmedTrainingLoading, setConfirmedTrainingLoading] = useState(false);
  const [confirmedtrainingCurPage, setConfirmedTrainingCurPage] = useState(1);
  const [confirmedTrainingTotalPage, setConfirmedTrainingTotalPage] = useState();
  const [confirmedTrainingFilters, setConfirmedTrainingFilters] = useState([]);

  // state - get user info
  const [userData, setUserData] = useState();

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [reauthorized, setReauthorized] = useState(false);
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // Modal information
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showModalClose, setShowModalClose] = useState(false);

  const [infoToUse, setInfoToUse] = useState([]);

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const handleSendConfirmedTraining = () => {
    console.log("BUTTON PRESSED");
    setShowModalClose(false);

    sendConfirmedTraining(
      userData, 
      infoToUse.event_details.training, 
      infoToUse.event_details.eventID,
      infoToUse.event_details.source
    )
    .then((res) => {
      if(res.data < 0){
        setModalHeader("Time Conflict");
        setShowModalClose(false);
        
        if(res.data === -1){
          setModalBody(
            <Container>
            <p>
              There was an issue with creating the event. The Trainee has a time conflict with the current time selected. Please select a different time. Make sure to refill in all values even if they appear filled in.
            </p>
          </Container>
          );
        } else {
          setModalBody(
            <Container>
            <p>
              There was an issue with creating the event. There is either an error with the event or the trainer. Please check with the respective scheduler to confirm.
            </p>
          </Container>
          );
        }
      } else {
        setModalHeader("Success");
        setModalBody(
          <Container>
          <p>
            Training Event has been successfully created.
          </p>
          <p>This page will now redirect to the home screen in three (3) seconds.</p>
        </Container>
        );
        setTimeout(redirectHome, 3000);
        setModalShow(true);
        setShowModalClose(false);
      }
    }).catch((err) => console.log(err));
    
    setModalHeader("Event Pending");
    setModalBody(
      <Container>
      <p>
        Training Event has been submitted please wait for this screen to update to confirm the event creation.
      </p>
    </Container>
    );
    setModalShow(true);
    setShowModalClose(false);

  };

  const attachToTraining = (info) => {

    setInfoToUse(info);

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Are you sure you want to join the following event?
      </h5>,
    ];

    newModalBody.push(<h5 key="Trainer_Info"><b>Trainer Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainer Name:&nbsp;</b></td>
          <td>{info.trainer.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainer.email}</td>
        </tr>
        <tr>
          <td><b>Trainer Phone Number:&nbsp;</b></td>
          <td>{info.trainer.phone}</td>
        </tr>
        <tr>
          <td><b>Trainer Zoom ID:&nbsp;</b></td>
          <td>{info.trainer.zoom_id}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event_Info"><b>Event Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Training Type: </b></td>
          <td>{info.event_details.training}</td>
        </tr>
        <tr>
          <td><b>Day to Meet: </b></td>
          <td>{info.event_details.day_to_meet}</td>
        </tr>
        <tr>
          <td><b>Time to Meet (in CST):&nbsp;</b></td>
          <td>{info.event_details.time_to_meet}</td>
        </tr>
        <tr>
          <td><b>Event ID:&nbsp;</b></td>
          <td>{info.event_details.eventID}</td>
        </tr>
      </table>
    );

    setModalHeader("Final Confirmation More Info")
    setModalBody(newModalBody);
    setModalBtnText("Confirm");
    setModalShow(true);
    setShowModalClose(true);
  }

  const infoConfirmedTraining = (info) => {
    console.log("IN INFO CONFIRM TRAINING");
    console.log(info);

    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        More Information:
      </h5>,
    ];

    newModalBody.push(<h5 key="Trainer_Info"><b>Trainer Information</b></h5>);
    
    newModalBody.push(
      <table>
        <tr>
          <td><b>Trainer Name:&nbsp;</b></td>
          <td>{info.trainer.name}</td>
        </tr>
        <tr>
          <td><b>Trainer Email:&nbsp;</b></td>
          <td>{info.trainer.email}</td>
        </tr>
        <tr>
          <td><b>Trainer Phone Number:&nbsp;</b></td>
          <td>{info.trainer.phone}</td>
        </tr>
        <tr>
          <td><b>Trainer Zoom ID:&nbsp;</b></td>
          <td>{info.trainer.zoom_id}</td>
        </tr>
      </table>
    );
    
    newModalBody.push(<br></br>);
    newModalBody.push(<h5 key="Event_Info"><b>Event Information</b></h5>);

    newModalBody.push(
      <table>
        <tr>
          <td><b>Training Type: </b></td>
          <td>{info.event_details.training}</td>
        </tr>
        <tr>
          <td><b>Day to Meet: </b></td>
          <td>{info.event_details.day_to_meet}</td>
        </tr>
        <tr>
          <td><b>Time to Meet (in CST):&nbsp;</b></td>
          <td>{info.event_details.time_to_meet}</td>
        </tr>
        <tr>
          <td><b>Event ID:&nbsp;</b></td>
          <td>{info.event_details.eventID}</td>
        </tr>
      </table>
    );

    setModalHeader("Final Confirmation More Info")
    setModalBody(newModalBody);
    setModalShow(true);
  };

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=HOME"),
    [navigate]
  );

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );
  

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Existing Page";

    if (!cookies["token"]) {
        // redirect to the login page
        setAuthorized(false);
    } else {
        authorize(cookies["firebaseToken"]).then((res) => {
          setCookie("token", res.data.token);
          setReauthorized(true);
        });
    }
    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This hook will be there in order to show the loading of the pages.
  useEffect(() => {
    if(reauthorized){
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      const decoded = decode(cookies["token"]);

      setUserData(decoded);

    }
  }, [reauthorized, cookies]);


  useEffect(() => {
    if(reauthorized){
      // Now get the scheduler info

      setConfirmedTrainingLoading(false);
      getTrainingsConfirmed(confirmedtrainingCurPage, 10, confirmedTrainingFilters)
        .then((res) => {
          const logs = res.data.data;
          const size = res.data.totalPage;
          setConfirmedTraining(logs);
          setConfirmedTrainingTotalPage(size);
          setConfirmedTrainingLoading(true);
        })
        .catch((err) => console.log(err));
    }
  }, [cookies, reauthorized, confirmedtrainingCurPage, confirmedTrainingFilters]);

  useEffect(() => {
    if(confirmedTrainingLoading){
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );

      setAuthTimeout(newTimeout);
      setPageDoneLoading(true);
    }
  }, [
    confirmedTrainingLoading,
    cookies
  ]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      console.log("REDIRECT LOGIN, authorized value:");
      console.log(authorized);
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  // =================================
  // -- Button handlers --
  // =================================


  const ConfirmedTrainingRow = (props) => {
    const record = props.record;
    return (
      <tr key={record.trainer.name + record.event_details.day_to_meet}>
        <td>{record.event_details.training}</td>
        <td>{record.trainer.name}</td>
        <td>{record.event_details.day_to_meet}</td>
        <td>{record.event_details.time_to_meet}</td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => infoConfirmedTraining(record)}
            >
              <IoInformationCircleOutline />
            </Button>
          </div>
          }
        </td>
        <td>
          {
            <div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => attachToTraining(record)}
            >
              <IoAddCircle />
            </Button>
          </div>
          }
        </td>
      </tr>
    );
  };

  const handleChangeConfirmedTraining = useCallback((page) => {
    setConfirmedTrainingCurPage(page);
  }, [])

  function MyPagination({total, current, onChangePage}) {
    let items = []

    if(current > 1){
      items.push(<Pagination.Prev key="prev" onClick={() => onChangePage(current - 1)} />)
    }

    if(total < 10){
        for (let page = 1; page <= total; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
          );
      }
    } else {
      if(current <= 3){
          for (let page = 1; page <= 5; page++) {
            items.push(
                <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                    {page}
                </Pagination.Item>
            );
        }

        items.push(<Pagination.Ellipsis />);
      } else if(current >= (total - 2)){
        <Pagination.Ellipsis />
        for (let page = total-4; page <= total; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
            );
        }
      } else {
        items.push(<Pagination.Ellipsis />);

        for (let page = current-2; page <= current+2; page++) {
          items.push(
              <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                  {page}
              </Pagination.Item>
            );
        }

        items.push(<Pagination.Ellipsis />);
      }
    }

    if (current < total) {
        items.push(<Pagination.Next key="next" onClick={() => onChangePage(current + 1)} />)
    }

    return (
      <Pagination>
        {items}
      </Pagination>
    );
  };

  const handleConfirmedTrainingSort = (info) => {
    const changes = structuredClone(confirmedTrainingFilters);
    let found = false;

    for (const [entry] of Object.entries(changes)){
      if(changes[entry] === info){
        found = true;
        break;
      }
    }

    if(found){
      const idx = changes.indexOf(info);
      changes.splice(idx, 1);
    } else {
      changes.push(info);
    }

    console.log(changes);
    setConfirmedTrainingFilters(changes);
  };


  const extractTrainings = (items) => {
    const to_return = [];

    for(const [entry] of Object.entries(items)){
      to_return.push(items[entry]);
    }

    return to_return;
  };

  return (
    <div>
      <NavBar />
      {
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Existing Trainings Page</h1>
          </Container>          
          
          <Container className={styles.sectionContainer}>
            {
              confirmedTrainingLoading && (confirmedTraining.length > 0) && (
                <div>
              <Table
                tableName={"Final Confirmed Trainings"}
                columns={[
                  "Training type",
                  "Trainer Name",
                  "Day to Meet",
                  "Time to Meet (in CST)",
                  "Training Info",
                  "Join Training"
                ]}
                sortable={[
                  true,
                  false,
                  false,
                  false,
                  false,
                  false
                ]}
                handleSorted={[
                  handleConfirmedTrainingSort,
                  false,
                  false,
                  false,
                  false,
                  false
                ]}
                filters={confirmedTrainingFilters}
                all={extractTrainings(OnlyTrainingOpt)}
                rows={confirmedTraining.map((record) => (
                  <ConfirmedTrainingRow
                    record={record}
                    key={"confirmedTraining" + JSON.stringify(record)}
                  />
                ))}
              />
              {
                (confirmedTrainingLoading) && (confirmedTrainingTotalPage > 0) && (
                  <MyPagination
                    total={confirmedTrainingTotalPage}
                    current={confirmedtrainingCurPage}
                    onChangePage={handleChangeConfirmedTraining}
                    />
                  )
              }
              </div>
              )
            }
            {
              (confirmedTrainingLoading && (confirmedTraining.length === 0)) && (
                <div>
                  <h3>Final Confirmed Trainings</h3>
                  <p>No Entries Recorded</p>
                </div>
              )
            }
            {
              !confirmedTrainingLoading && (
                <Spinner animation="border" />
              )
            }
          </Container>
          

        </Container>
      }
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop={undefined}
      >
        <Modal.Header closeButton={true}>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          {
            showModalClose && (
              <Modal.Footer>
              <Button
                variant="primary"
                onClick={ handleSendConfirmedTraining
                }
              >
                {modalBtnText}
              </Button>
            </Modal.Footer>
            )
          }
      </Modal>

    </div>
  );
};

export default ExistingTrainingPage;
