import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const submitHelpRequest = (user, form) =>
  axios.post(
    SERVER_URL + "/api/help/submit",
    {
      user,
      form,
      withCredentials: true,
    }
  )

export const getHelpLogs = () =>
  axios.post(
    SERVER_URL + "/api/help/logs",
    {
      withCredentials: true,
    }
  )

export const resolveEvent = (event) =>
  axios.post(
    SERVER_URL + "/api/help/resolve",
    {
      event,
      withCredentials: true,
    }
  )