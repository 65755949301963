import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

// react-bootstrap imports
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import {
  tokenIsValid,
  decode,
  getTokenExpirationDate,
  userIsAdmin,
} from "../utils/token";
import { AppTitles } from "../constants/apps";
import { NavigateRoutes } from "../constants/routes";
import { 
  IoServer, 
  IoCloudUpload, 
  IoPerson, 
  IoCalendar, 
  IoSchool, 
  IoBook, 
  IoGitCompareSharp,
  IoCheckbox,
  IoInformationCircle,
  IoPersonAdd,
  IoCreate
 } from "react-icons/io5";
import styles from "../styles/Main.module.css";
import NavBar from "../components/NavBar";

import {
  // submitWeeklyUpdate,
  // submitWeeklyReminder
} from "../api/weeklyUpdate";

import {
  getIsTrainer
} from "../api/Trainer.js"

/**
 * Page component displaying all available applications + profile + admin as buttons
 * @returns React.FC
 */
const Home = () => {
  const iconSize = 100;

  const everyFriday = () => {
    var today = new Date();

    var utc_today_string = today.toLocaleString("en-US", {timeZone: "America/Chicago"})

    var utc_today = new Date(utc_today_string);

    return utc_today.getDay() === 1;
  }

  useEffect(() => {
    if(everyFriday()){
      console.log("Submitting weekly update");
      // submitWeeklyUpdate();
      // submitWeeklyReminder();
      console.log("Finished");
    } else {
      console.log("Not the right day");
    }
  }, []);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isTrainer, setIsTrainer] = useState(false);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies] = useCookies(["token"]);

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=HOME"),
    [navigate]
  );
  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered

  useEffect(() => {
    document.title = "Project SEED Apps: Home";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );
      setAuthTimeout(newTimeout);

      // check if the user is an admin
      setIsAdmin(userIsAdmin(cookies["token"]));
      
      const decoded = decode(cookies["token"]);

      getIsTrainer(decoded)
      .then((res) => {
        const logs = res.data;
        setIsTrainer(logs);
      })
      .catch((err) => console.log(err));
    }

    setPageDoneLoading(true);

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Customized button to display the platform links
   * @param {*} props
   * @returns Customized react-bootstrap/Button
   */
  const IconButton = (props) => (
    <Button
      variant="dark"
      className={styles.appIconButton}
      onClick={() => navigate(props.link)}
    >
      <Container className={styles.appIcon}>{props.icon}</Container>
      <Container className={styles.appIconTitle}>{props.title}</Container>
    </Button>
  );

  // return for the page
  return (
    <>
      <NavBar />
      <Container className={styles.mainPageContainer}>
        <h1>Welcome to the Project SEED Apps Platform</h1>
        <Container fluid className={styles.appIconContainer}>
          <IconButton
            icon={<IoCloudUpload size={iconSize} />}
            title={AppTitles.REDCAP_PERMS}
            link={NavigateRoutes.REDCAP_PERMS}
          />
          <IconButton
            icon={<IoPerson size={iconSize} />}
            title={AppTitles.PROFILE}
            link={NavigateRoutes.PROFILE}
          />
          <IconButton
            icon={<IoCheckbox size={iconSize} />}
            title={AppTitles.CHECKLIST}
            link={NavigateRoutes.CHECKLIST}
          />
          <IconButton
            icon={<IoInformationCircle size={iconSize} />}
            title={AppTitles.NEEDTOKNOW}
            link={NavigateRoutes.NEEDTOKNOW}
          />
          {/* Might need to add verification that they are a trainer */}
          {isTrainer && (<IconButton
            icon={<IoSchool size={iconSize} />}
            title={AppTitles.TRAINER_SCHEDULER}
            link={NavigateRoutes.SCHEDULER_TRAINER_PARENT}
          />)}
          {/* TODO: in development: SCHEDULER APP */
          <IconButton
            icon={<IoCalendar size={iconSize} />}
            title={AppTitles.SCHEDULER}
            link={NavigateRoutes.SCHEDULER_PARENT}
          /> /**/}
          <IconButton 
            icon={<IoCreate size={iconSize} />}
            title={AppTitles.EXISTINGTRAINING}
            link={NavigateRoutes.EXISTINGTRAINING}
          />
          {
          <IconButton
            icon={<IoBook size={iconSize} />}
            title={AppTitles.SCHEDULER_BYPASS}
            link={NavigateRoutes.SCHEDULER_BYPASS}
          />}
          <IconButton
            icon={<IoGitCompareSharp size={iconSize} />}
            title={AppTitles.HUB}
            link={NavigateRoutes.HUB}
          />
          <IconButton
            icon={<IoPersonAdd size={iconSize} />}
            title={AppTitles.VOLUNTEERHUB}
            link={NavigateRoutes.VOLUNTEERHUB}
          />
          {isAdmin && (
            <IconButton
              icon={<IoServer size={iconSize} />}
              title={AppTitles.ADMIN}
              link={NavigateRoutes.ADMIN}
            />
          )}
        </Container>
      </Container>
    </>
  );
};

export default Home;