import React, { useState, useEffect } from "react";

// bootstrap imports
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Link } from "react-router-dom";

// firebase imports
import firebase from "../Firebase";
import { getAuth } from "firebase/auth";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";

import { EmailRegex } from "../constants/regex";
import { NavigateRoutes } from "../constants/routes";
import { clientUrl } from "../constants/client";

import styles from "../styles/Login.module.css";

/**
 * Page component - displayed when the user is not yet logged in and needs to send a password reset request (handled by firebase)
 * @returns React.FC
 */
const ResetPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);

  const auth = getAuth(firebase);
  const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);

  const handleClick = async () => {
    //eslint-disable-next-line
    if (!EmailRegex.test(email)) {
      // show emailError
      setEmailError(true);
    } else {
      setEmailError(false);
      await sendPasswordResetEmail(email);
      setSentEmail(true);
    }
  };

  useEffect(() => {
    document.title = "Project SEED Apps: Reset Password Request";
  }, []);

  // return for the page
  return (
    <Container fluid className={styles.loginContainerWrapper}>
      <Container className={styles.headerContainer}>
        <h1>Reset Password</h1>
      </Container>
      <p>
        Enter the email associated with your account below. If the account
        exists in our records, you will receive an email to reset your password.
      </p>

      <Container className={styles.loginContainer}>
        <Form className={styles.loginForm}>
          <Container className={styles.loginItemContainer}>
            <FloatingLabel label="Email address">
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
            </FloatingLabel>
          </Container>
          {emailError && (
            <Container className={styles.errorContainer}>
              Please make sure you have entered a valid email address, then try
              again.
            </Container>
          )}
          {sentEmail && (
            <Container className={styles.messageContainer}>
              Sent a link to your email address to reset your password. If your
              email exists in our records, you should receive a request from
              <Container fluid className={styles.emailAddress}>
                noreply@{clientUrl}
              </Container>{" "}
              within the next hour. Check your junk inbox if you have not
              received it.
            </Container>
          )}
          <Container className={styles.buttonContainer}>
            <Button onClick={handleClick}>Submit reset request</Button>
          </Container>
          <Container className={styles.buttonContainer}>
            <Link to={NavigateRoutes.LOGIN}>Return to login page</Link>
          </Container>
        </Form>
      </Container>
    </Container>
  );
};

export default ResetPage;
