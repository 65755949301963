import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import NavBar from "../components/NavBar";

import { TrainingOpt } from "../constants/scheduler.js";

import { NavigateRoutes } from "../constants/routes";

import {
  tokenIsValid,
  getTokenExpirationDate
} from "../utils/token";

import {
 // helperServerFunction,
  submitFinalConfirmation
} from "../api/Scheduler_Bypass";

// import { refreshPage } from "../utils/components";

import { authorize } from "../api/Login";

import styles from "../styles/Profile.module.css";

import { Button } from "react-bootstrap";

import ToggleButton from 'react-bootstrap/ToggleButton';

/**
 * Page component for displaying/editing the current user's profile information stored in the Firestore collection "users"
 * @returns React.FC
 */
const SchedulerBypassPage  = () => {

  // Need to connect it to some kind of state:
  // do so here:
  // https://www.robinwieruch.de/react-checkbox/
  const getNextTwoWeeks = () => {
    const dates = [];
    var num_weeks = 3;

    var today = new Date();

    for(var i = 0; i < 7 * num_weeks; i++){
        var cur_month = today.getMonth() + 1
        var str_month = cur_month.toString()

        var cur_day = today.getDate().toString();
        var cur_year = today.getFullYear().toString();
        
        dates[i] = str_month.concat("/", cur_day, "/", cur_year);
        today.setDate(today.getDate() + 1);
    }

    return dates
  };

  const get24Hours = () => {
    const times = [];
    let idx = 0;
    for(var i = 0; i < 24; i++){
      for(var j = 0; j < 2; j++){
          let temp = i;
          let hour = "";
          let time_of_day = "am";
          let minutes = "00";

          if(j === 1){
            minutes = "30";
          }
    
          if(i > 12){
            time_of_day = "pm";
            temp -= 12;
          } else if (i === 12){
            time_of_day = "pm";
          }
    
          if(temp < 10){
            hour = "0".concat(temp.toString());
          } else {
            hour = temp.toString();
          }
    
          times[idx] = hour.concat(":", minutes, " ", time_of_day);
          
          if(times[idx] === "00:00 am"){
            times[idx] = "12:00 am"
          }

          idx += 1;
        }
      }

    return times;
  }

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  const [formInfo, setFormInfo] = useState();

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // state - track authorization progress
  const [reauthorized, setReauthorized] = useState(false);

  // For the email checkbox
  const [checked, setChecked] = useState(false);

  // state - track changes
  const [changeError] = useState(false);
  const [changeErrorMessage] = useState("");

  // Hooks
  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);
  const [requestQueued, setRequestQueued] = useState(false);

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=PROFILE"),
    [navigate]
  );

  const redirectHome = useCallback(
    () => navigate(NavigateRoutes.HOME),
    [navigate]
  );

  // Form information
  // const formInfo = {
  //   "trainee_name": "",
  //   "trainee_email": "",
  //   "trainee_eid": "",
  //   "trainer_name": "",
  //   "trainer_email": "",
  //   "trainer_eid": "",
  //   "training": "GAV",
  //   "day_to_meet": "07/01/2023",
  //   "time_to_meet": "00:00 am"
  // }

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Profile";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // make a new authorization request to get the most recent token on page load
      authorize(cookies["firebaseToken"]).then((res) => {
        setCookie("token", res.data.token);
        setReauthorized(true);
      });
    }

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 10 * 1000
      );

      const blankForm = () => {
        const item = {
          "trainee_name"  : "",
          "trainee_email" : "",
          "trainee_phone" : "",
          "trainee_eid"   : "",
          "trainer_name"  : "",
          "trainer_email" : "",
          "trainer_phone"   : "",
          "trainer_eid"   : "",
          "trainer_zoom_url" : "",
          "trainer_zoom_id": "",
          "volunteer_name": "",
          "volunteer_email": "",
          "volunteer_phone": "",
          "volunteer_eid"   : "",
          "training"      : TrainingOpt["GAV"],
          "day_to_meet"   : getNextTwoWeeks()[0],
          "time_to_meet"  : get24Hours()[0]
        };
    
        return item;
      };

      setAuthTimeout(newTimeout);

      // load the user data and changed user data for future changes

      const blank = blankForm();

      setFormInfo(blank);

      setPageDoneLoading(true);
    }
  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  /**
   * Handler for updating the changedUserData and changesDetected state objects
   * @param {string} attribute
   * @param {*} value
   */
  const handleChangeUser = (attribute, value) => {

    // formInfo[attribute] = value;
    const changes = structuredClone(formInfo);
    changes[attribute] = value;
    setFormInfo(changes);
  };

  // const popupConfirmation = () => (
  //   <Popup trigger={<button> Trigger</button>} position="right center">
  //     <div>Popup content here !!</div>
  //   </Popup>
  // );

  /**
   * Handler for submitting the changes stored in the changedUserData stateobject to the backend API
   */
  const handleSubmitChanges = () => {
    // This is where we need to record the information that
    // the user submitted for the form to then send the email
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Review the information before submitting the event:
      </h5>,
    ];
    newModalBody.push(<h5 key={"training_info"}>
                      <p><b>Training:</b> {formInfo.training}</p>
                      </h5>);
    newModalBody.push(<h5 key="Trainee">
                      <b>Trainee Information:</b>
                      <p><b>Name:</b> {formInfo.trainee_name}</p>
                      <p><b>Email:</b> {formInfo.trainee_email}</p>
                      <p><b>Phone Number:</b> {formInfo.trainee_phone}</p>
                      <p><b>EID:</b> {formInfo.trainee_eid}</p>
                      </h5>);
    newModalBody.push(<h5 key="Trainer">
                      <b>Trainer Information:</b>
                      <p><b>Name:</b> {formInfo.trainer_name}</p>
                      <p><b>Email:</b> {formInfo.trainer_email}</p>
                      <p><b>Phone Number:</b> {formInfo.trainer_phone}</p>
                      <p><b>EID:</b> {formInfo.trainer_eid}</p>
                      <p><b>Zoom Url:</b> {formInfo.trainer_zoom_url}</p>
                      <p><b>Zoom ID:</b> {formInfo.trainer_zoom_id}</p>
                      </h5>);
    
    if (formInfo.volunteer_name.length > 0){
      newModalBody.push(<h5 key="volunteer">
                      <b>Volunteer Information:</b>
                      <p><b>Name:</b> {formInfo.volunteer_name}</p>
                      <p><b>Email:</b> {formInfo.volunteer_email}</p>
                      <p><b>Phone Number:</b> {formInfo.volunteer_phone}</p>
                      <p><b>EID:</b> {formInfo.volunteer_eid}</p>
                      </h5>);
    }
                      
    newModalBody.push(<h5 key={"day_time_info"}>
                      <p><b>Day to Meet:</b> {formInfo.day_to_meet}</p>
                      <p><b>Time to Meet (CST):</b> {formInfo.time_to_meet}</p>
                      </h5>);

    if(checked){
      newModalBody.push(<h5 key={"day_time_info"}>
        <p>Recieve an email on submission.</p>
        </h5>);
    } else {
      newModalBody.push(<h5 key={"day_time_info"}>
        <p>Do not recieve an email on submission.</p>
        </h5>);
    }

    setModalHeader("Confirm Entry");
    setModalBody(newModalBody)
    setModalBtnText("Confirm");
    setModalShow(true);
    setRequestQueued(true);
  };

  // const dummy = () => {
  //   console.log("I HAVE BEEN PRESSED");
  //   helperServerFunction();
  // };

  const handleConfirm = () => {

    const blankForm = () => {
      const item = {
        "trainee_name"  : "",
        "trainee_email" : "",
        "trainee_phone" : "",
        "trainee_eid"   : "",
        "trainer_name"  : "",
        "trainer_email" : "",
        "trainer_eid"   : "",
        "trainer_phone"   : "",
        "trainer_zoom_url" : "",
        "trainer_zoom_id": "",
        "volunteer_name": "",
        "volunteer_email": "",
        "volunteer_phone": "",
        "volunteer_eid"   : "",
        "training"      : TrainingOpt["GAV"],
        "day_to_meet"   : getNextTwoWeeks()[0],
        "time_to_meet"  : get24Hours()[0]
      };
  
      return item;
    };
   
    setShowModalClose(false);
    // TODO
    // Make sure to catch any errors
    submitFinalConfirmation(formInfo, checked)
      .then((res) => {
                      if(res.data < 0){
                        setModalHeader("Time Conflict");
                        setShowModalClose(false);
                        
                        const blank = blankForm();
                        setFormInfo(blank);

                        if(res.data === -1){
                          setModalBody(
                            <Container>
                            <p>
                              There was an issue with creating the event. The Trainer has a time conflict with the current time selected. Please select a different time. Make sure to refill in all values even if they appear filled in.
                            </p>
                          </Container>
                          );
                        } else if (res.data === -2){
                          setModalBody(
                            <Container>
                            <p>
                              There was an issue with creating the event. The Trainee has a time conflict with the current time selected. Please select a different time. Make sure to refill in all values even if they appear filled in.
                            </p>
                          </Container>
                          );
                        } else if(res.data === -3){
                          setModalBody(
                            <Container>
                            <p>
                              There was an issue with creating the event. The Trainer email submitted does not exist in the Car No Car spreadsheet. Please make sure to double check on the spreadsheet. If you believe this issue was incorrect, please submit an issue request.
                            </p>
                          </Container>
                          );
                        }
                      } else {
                        setModalHeader("Success");
                        setModalBody(
                          <Container>
                          <p>
                            Training Event has been successfully created.
                          </p>
                          <p>This page will now redirect to the home screen in three (3) seconds.</p>
                        </Container>
                        );
                        setModalBtnText("Close");
                        setTimeout(redirectHome, 3000);
                        setModalShow(true);
                        setShowModalClose(false);
                      }
                      // setTimeout(refreshPage, 2000);
                      // console.log("About to call popupConfirmation")
                      // popupConfirmation();
                    })
      .catch((err) => console.log(err));
    
    setModalHeader("Event Pending");
    setModalBody(
      <Container>
      <p>
        Training Event has been submitted please wait for this screen to update to confirm the event creation.
      </p>
    </Container>
    );
    setModalShow(true);
  };

  // return for the page
  return (
    <div>
      <NavBar />
      {pageDoneLoading && (
        <Container className={styles.pageContainer}>
          <Container>
            <h1>Scheduler Training Form</h1>
            <p>This is a tool to allow schedulers to by pass the training forms to just manually submit a event confirmation.</p>
          </Container>
          <Container className={styles.sectionContainer}>
            {(
              <Container>
                
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainee Name
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainee_name", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainee Email
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainee_email", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainee Phone Number
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainee_phone", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainee EID
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainee_eid", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainer Name
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainer_name", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainer Email
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainer_email", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainer Phone Number
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainer_phone", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainer EID
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainer_eid", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainer Zoom Url 
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainer_zoom_url", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Trainer Zoom ID
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("trainer_zoom_id", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Volunteer Name
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("volunteer_name", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Volunteer Email
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("volunteer_email", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Volunteer Phone Number
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("volunteer_phone", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Volunteer EID
                  </Container>
                  <Container>
                    <Form.Control
                      type="text"
                      defaultValue={""}
                      onChange={(e) => handleChangeUser("volunteer_eid", e.target.value)}
                    />
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Training Type
                  </Container>
                  <Container>
                    <Form.Select
                      defaultValue={TrainingOpt["GAV"]}
                      onChange={(e) =>
                        handleChangeUser("training", e.target.value)
                      }
                    >
                      {Object.values(TrainingOpt).map((accessOption) => (
                        <option key={"training" + accessOption}>
                          {accessOption}
                        </option>
                      ))}
                    </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Day to Meet
                  </Container>
                  <Container>
                    <Form.Select
                      defaultValue={getNextTwoWeeks()[0]}
                      onChange={(e) =>
                        handleChangeUser("day_to_meet", e.target.value)
                      }
                    >
                      {Object.values(getNextTwoWeeks()).map((accessOption) => (
                        <option key={"day_to_meet" + accessOption}>
                          {accessOption}
                        </option>
                      ))}
                    </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Time to meet (in CST)
                  </Container>
                  <Container>
                    <Form.Select
                      defaultValue={
                        "0:00 am"
                      }
                      onChange={(e) =>
                        handleChangeUser("time_to_meet", e.target.value)
                      }
                    >
                      {Object.values(get24Hours()).map((accessOption) => (
                        <option key={"time_to_meet" + accessOption}>
                          {accessOption}
                        </option>
                      ))}
                    </Form.Select>
                  </Container>
                </Container>
                <Container className={styles.attributeContainer}>
                  <Container className={styles.attributeName}>
                    Recieve Confirmation Email on Submission?
                  </Container>
                  <Container>
                    <ToggleButton
                      className="mb-2"
                      id="toggle-check"
                      type="checkbox"
                      variant="outline-primary"
                      checked={checked}
                      value="1"
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    >
                      Yes
                    </ToggleButton>
                  </Container>
                </Container>
              </Container>
            )}
            {changeError && (
              <Container className={styles.errorContainer}>
                {changeErrorMessage}
              </Container>
            )}
            <Container className={styles.sectionContainer}>
              <Container fluid className={styles.buttonContainer}>
                <Button
                  variant="warning"
                  onClick={handleSubmitChanges}
                >
                  Submit Scheduling Event
                </Button>
                {/* <Button
                  variant="primary"
                  onClick={
                    dummy
                  }
                >
                  {"temp Button"}
                </Button> */}
              </Container>
            </Container>
          </Container>
        </Container>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop={showModalClose ? undefined : "static"}
      >
        <Modal.Header closeButton={showModalClose}>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          {showModalClose && (
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={
                  requestQueued ? handleConfirm : () => setModalShow(false)
                }
              >
                {modalBtnText}
              </Button>
            </Modal.Footer>
          )}
      </Modal>
    </div>
  );
};

export default SchedulerBypassPage;
