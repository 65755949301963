import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { ReactRoutes } from "../../constants/routes";
import Dashboard from "./Dashboard";
import Settings from "./Settings";

import NavBar from "../../components/NavBar";
import ModalUnauthorized from "../../components/ModalUnauthorized";

// utils
import { tokenIsValid, getTokenExpirationDate } from "../../utils/token";
import { AppNames } from "../../constants/apps";
import { NavigateRoutes } from "../../constants/routes";

const App = () => {
  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies] = useCookies(["token"]);

  // state - track authorization and page viewing
  const [authorized, setAuthorized] = useState();
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => navigate(NavigateRoutes.LOGIN + "?redirect=REDCAP_PERMS"),
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: REDCap Permissions";
    if (!cookies["token"]) {
      // redirect to the login page
      setAuthorized(false);
    } else {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"], AppNames.REDCAP_PERMS);
      setAuthorized(auth);

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
        () => setAuthorized(false),
        exp - now - 60 * 1000
      );
      setAuthTimeout(newTimeout);
    }

    setPageDoneLoading(true);

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  return (
    <>
      {!authorized && ModalUnauthorized}
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route
          exact
          path={ReactRoutes.REDCAP_PERMS_SETTINGS}
          element={<Settings />}
        />
      </Routes>
    </>
  );
};

export default App;
