import axios from "axios";
import { SERVER_URL } from "../constants/server";

export const submitFinalConfirmation = (form, checked) =>
  axios.post(
    SERVER_URL + "/api/schedulerBypass/create",
    { 
      form,
      checked
    },
    {
      withCredentials: true,
    }
  );

// export const helperServerFunction = () =>
//   axios.post(
//     SERVER_URL + "/api/schedulerBypass/helper",
//     {
//       withCredentials: true,
//     }
//   );

export const getConfirmedLogs = (event, accept, trainerEmail, option) =>
  axios.post(
    SERVER_URL + "/api/scheduler/logs",
    {
      withCredentials: true,
    }
  )


  
