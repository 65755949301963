import React, { useState, useEffect } from "react";

// bootstrap imports
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";

// custom styling
import styles from "./styles/Main.module.css";

// API
import {
  getAllProjects,
  getAllPermSets,
  updateProjects,
  updateFormsOneProject,
  createProject,
  deleteProject,
  createPermSet,
  deletePermSet,
  updatePermSets,
} from "../../api/RedcapPerms";

import { refreshPage } from "../../utils/components";

const Settings = () => {
  // state - list of projects
  const [projectData, setProjectData] = useState([]);
  const [projectDataMap, setProjectDataMap] = useState({});

  // state - track project changes
  const [projectChanges, setProjectChanges] = useState({});
  const [projectChangesQueued, setProjectChangesQueued] = useState(false);
  const [showUpdateProjectsModal, setShowUpdateProjectsModal] = useState(false);
  const [updateProjectsError, setUpdateProjectsError] = useState(false);

  // state - new project name and api token
  const [newProjectName, setNewProjectName] = useState("");
  const [newProjectToken, setNewProjectToken] = useState("");
  const [newProjectPermSets, setNewProjectPermSets] = useState([]);
  const [newProjectError, setNewProjectError] = useState(false);
  const [newProjectErrorText, setNewProjectErrorText] = useState("");
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);

  // state - delete project by name
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [deleteProjectError, setDeleteProjectError] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  // state - list of perm sets
  const [permSets, setPermSets] = useState([]);
  const [permSetMap, setPermSetMap] = useState({});
  const [permAttributes, setPermAttributes] = useState([]);

  // state - track perm set changes
  const [permSetChanges, setPermSetChanges] = useState({});
  const [permSetChangesQueued, setPermSetChangesQueued] = useState(false);
  const [showUpdatePermSetsModal, setShowUpdatePermSetsModal] = useState(false);
  const [updatePermError, setUpdatePermError] = useState(false);

  // state - new perm set name
  const [newPermSetName, setNewPermSetName] = useState("");
  const [newPermError, setNewPermError] = useState(false);
  const [newPermErrorText, setNewPermErrorText] = useState("");
  const [showNewPermModal, setShowNewPermModal] = useState(false);

  // state - delete perm set
  const [deletePermName, setDeletePermName] = useState("");
  const [deletePermError, setDeletePermError] = useState(false);
  const [showDeletePermModal, setShowDeletePermModal] = useState(false);

  const generateNewPermSet = (newName) => {
    const newSet = {};
    for (let i = 0; i < permAttributes.length; i++) {
      newSet[permAttributes[i]] = 0;
    }
    newSet.name = newName;
    return newSet;
  };

  useEffect(() => {
    // get the projects to display in app on page load
    getAllProjects()
      .then((res) => {
        const data = res.data;

        setProjectData(data);
        const newMap = {};
        // set the map
        for (let i = 0; i < data.length; i++) {
          const proj = data[i];
          newMap[proj.name] = proj;
        }
        setProjectDataMap(newMap);
      })
      .catch((err) => console.log(err));

    // get the perm_sets to display in app on page load
    getAllPermSets()
      .then((res) => {
        // set the perm set data
        const data = res.data;
        setPermSets(data);

        // set the perm set map
        const newMap = {};
        for (let i = 0; i < data.length; i++) {
          const perm = data[i];
          newMap[perm.name] = perm;
        }
        setPermSetMap(newMap);

        // set the perm set attributes (common)
        const attrList = Object.keys(data[0]).filter(
          (label) => label !== "name"
        );

        attrList.sort();

        // move the "name" attribute to the front
        attrList.unshift("name");
        setPermAttributes(attrList);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => console.log(permSetMap, projectDataMap),
    [permSetMap, projectDataMap]
  );

  const handlePermChange = (
    permSetsIdx,
    permSetName,
    permSetAttribute,
    changeValue
  ) => {
    const changes = permSetChanges;

    if (!(permSetName in changes)) {
      // if the project has not yet been tracking changes, add it
      changes[permSetName] = structuredClone(permSets[permSetsIdx]);
    }

    changes[permSetName][permSetAttribute] = changeValue;

    // track if name gets changed
    if (
      permSetAttribute === "name" &&
      changes[permSetName].name !== permSets[permSetsIdx].name
    ) {
      changes[permSetName].nameChanged = true;
    } else if (
      Object.keys(changes[permSetName]).includes("nameChanged") &&
      changes[permSetName].name === permSets[permSetsIdx].name
    ) {
      delete changes[permSetName].nameChanged;
    }

    // check if the new changes make the new object identical to the database-stored object
    let changesAreIdentical = true;
    for (const attr of Object.keys(permSets[permSetsIdx])) {
      if (changes[permSetName][attr] !== permSets[permSetsIdx][attr]) {
        changesAreIdentical = false;
        break;
      }
    }

    // if identical, delete from changes object
    if (changesAreIdentical) {
      delete changes[permSetName];
    }

    if (Object.keys(changes).length) {
      setPermSetChangesQueued(true);
    } else {
      setPermSetChangesQueued(false);
    }

    setPermSetChanges(changes);
  };

  const handleProjectChange = (
    projectDataIdx,
    projectName,
    projectAttribute,
    changeValue
  ) => {
    const changes = projectChanges;

    if (!(projectName in changes)) {
      // if the project has not yet been tracking changes, add it
      changes[projectName] = structuredClone(projectData[projectDataIdx]);
    }

    // make changes

    if (projectAttribute === "perm_sets") {
      // handle changes to applicable perm sets for project
      for (const [permName, includePerm] of Object.entries(changeValue)) {
        if (
          !includePerm &&
          changes[projectName][projectAttribute].includes(permName)
        ) {
          changes[projectName][projectAttribute] = structuredClone(
            changes[projectName][projectAttribute].filter((i) => i !== permName)
          );
        } else if (
          includePerm &&
          !changes[projectName][projectAttribute].includes(permName)
        ) {
          changes[projectName][projectAttribute].push(permName);
        }
      }
    } else {
      // handle name or API token changes
      changes[projectName][projectAttribute] = changeValue;
    }

    // track if name gets changed
    if (
      projectAttribute === "name" &&
      changes[projectName].name !== projectData[projectDataIdx].name
    ) {
      changes[projectName].nameChanged = true;
    } else if (
      Object.keys(changes[projectName]).includes("nameChanged") &&
      changes[projectName].name === projectData[projectDataIdx].name
    ) {
      delete changes[projectName].nameChanged;
    }

    // check if the new changes make the new object identical to the database-stored object
    let changesAreIdentical = true;
    for (const attr of Object.keys(projectData[projectDataIdx])) {
      if (Array.isArray(changes[projectName][attr])) {
        const arr1 = changes[projectName][attr];
        const arr2 = projectData[projectDataIdx][attr];

        arr1.sort();
        arr2.sort();

        if (arr1.length !== arr2.length) {
          changesAreIdentical = false;
          break;
        }

        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            changesAreIdentical = false;
            break;
          }
        }
      } else if (
        changes[projectName][attr] !== projectData[projectDataIdx][attr]
      ) {
        changesAreIdentical = false;
        break;
      }
    }

    // if identical, delete from changes object
    if (changesAreIdentical) {
      delete changes[projectName];
    }

    if (Object.keys(changes).length) {
      setProjectChangesQueued(true);
    } else {
      setProjectChangesQueued(false);
    }

    setProjectChanges(changes);
  };

  const handleSubmitUpdatePerms = () => setShowUpdatePermSetsModal(true);

  const handleConfirmUpdatePerms = () => {
    updatePermSets(permSetChanges)
      .then(refreshPage)
      .catch((err) => {
        setUpdatePermError(true);
        console.log(err);
      });
  };

  const handleSubmitUpdateProjects = () => setShowUpdateProjectsModal(true);

  const handleConfirmUpdateProjects = () => {
    updateProjects(projectChanges)
      .then(refreshPage)
      .catch((err) => {
        setUpdateProjectsError(true);
        console.log(err);
      });
  };

  const handleSubmitUpdateForms = (projectName) => {
    updateFormsOneProject(projectName)
      .then(refreshPage)
      .catch((err) => console.log(err));
  };

  const handleEditNewProjectPermSets = (e, permSetName) => {
    if (e.target.checked && !newProjectPermSets.includes(permSetName)) {
      const newPerms = newProjectPermSets;
      newPerms.push(permSetName);
      setNewProjectPermSets(newPerms);
    } else if (!e.target.checked && newProjectPermSets.includes(permSetName)) {
      const newPerms = structuredClone(
        newProjectPermSets.filter((i) => i !== permSetName)
      );
      setNewProjectPermSets(newPerms);
    }
  };

  const handleSubmitCreateProject = () => {
    setNewProjectErrorText(
      "Could not validate the entered project information. Please make sure you have entered all information correctly, then try again."
    );
    if (
      newProjectName.trim() === "" ||
      newProjectToken.trim() === "" ||
      newProjectPermSets.length < 1
    ) {
      setNewProjectError(true);
      return;
    } else setNewProjectError(false);

    createProject(
      newProjectName.trim(),
      newProjectToken.trim(),
      newProjectPermSets
    )
      .then(refreshPage)
      .catch((err) => {
        setNewProjectErrorText(
          "There was an error adding the new project with the given information. Please make sure you have entered the correct API token, then try again."
        );
        console.log(err);
      });
  };

  const handleHideNewProjectModal = () => {
    setNewProjectError(false);
    setShowNewProjectModal(false);
  };

  const handleSubmitDeleteProject = () => {
    deleteProject(deleteProjectName)
      .then(refreshPage)
      .catch((err) => {
        console.log(err);
        setDeleteProjectError(true);
      });
  };

  const handleClickDeleteProject = (projectName) => {
    setDeleteProjectName(projectName);
    setShowDeleteProjectModal(true);
  };

  const handleHideDeleteProjectModal = () => {
    setDeleteProjectError(false);
    setShowDeleteProjectModal(false);
  };

  const handleSubmitNewPermSet = () => {
    setNewPermErrorText(
      "The permission set name cannot be empty. Please enter a name, then try submitting again."
    );
    if (newPermSetName.trim() === "") {
      setNewPermError(true);
      return;
    }

    createPermSet(newPermSetName, generateNewPermSet(newPermSetName))
      .then(refreshPage)
      .catch((err) => {
        setNewPermErrorText(
          "There was an error creating the new permissions set. Please try again later"
        );
        console.log(err);
      });
  };

  const handleHideNewPermModal = () => {
    setNewPermError(false);
    setShowNewPermModal(false);
  };

  const handleSubmitDeletePerm = () => {
    deletePermSet(deletePermName)
      .then(refreshPage)
      .catch((err) => {
        console.log(err);
        setDeletePermError(true);
      });
  };

  const handleClickDeletePerm = (permName) => {
    setDeletePermName(permName);
    setShowDeletePermModal(true);
  };

  const handleHideDeletePermModal = () => {
    setDeletePermError(false);
    setShowDeletePermModal(false);
  };

  return (
    <div>
      <Container className={styles.container}>
        <Container className={styles.viewTitle}>
          <h1>REDCapPermissions: Settings</h1>
        </Container>
        <Container className={styles.functionContainer}>
          <h4>Permission Set List</h4>
          <Container>
            Users with access to the <i>Dashboard</i> page of this app are able
            to apply these permission sets to UT EIDs they enter into the
            interface. Given a permission set below, the UT EID will be given
            access based on the permission set values to applicable projects.
            For more information on what these values mean, see the REDCap API
            documentation on importing users{" "}
            <a
              href="https://redcap.prc.utexas.edu/redcap/api/help/?content=imp_users"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . The "forms" and "forms_export" values are applied to each form
            attributed to each project.
          </Container>
          <div style={{ height: "1rem" }} />
          <Container>
            <Button variant="success" onClick={() => setShowNewPermModal(true)}>
              Add a new permission set
            </Button>
            <Button
              variant="warning"
              disabled={!permSetChangesQueued}
              onClick={handleSubmitUpdatePerms}
            >
              Submit set changes
            </Button>
          </Container>
          <Container className={styles.settingsTableContainer}>
            <Table bordered responsive className={styles.settingsTable}>
              <thead>
                <tr>
                  {permAttributes.map((attrName) => (
                    <th key={attrName}>
                      {attrName === "name" ? "Permission set name" : attrName}
                    </th>
                  ))}
                  <th>Delete set?</th>
                </tr>
              </thead>
              <tbody>
                {permSets.map((perm, i) => (
                  <tr key={perm.name}>
                    {permAttributes.map((attrName) => (
                      <th key={perm + attrName}>
                        {attrName === "name" ? (
                          <Form.Control
                            size="sm"
                            className={styles.settingsTableTextInput}
                            type="text"
                            defaultValue={perm.name}
                            onChange={(e) =>
                              handlePermChange(
                                i,
                                perm.name,
                                attrName,
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <Form.Control
                            size="sm"
                            className={styles.settingsTableNumberInput}
                            type="number"
                            defaultValue={perm[attrName]}
                            min={0}
                            onChange={(e) =>
                              handlePermChange(
                                i,
                                perm.name,
                                attrName,
                                e.target.value
                              )
                            }
                          />
                        )}
                      </th>
                    ))}
                    <th>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleClickDeletePerm(perm.name)}
                      >
                        Delete
                      </Button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Container>

        <Container className={styles.functionContainer}>
          <h4>Project List</h4>
          <Container>
            This application leverages the REDCap API functionality to update
            users for a given REDCap project, using the API tokens below. The
            API token is used to access view/edit functionalities for each
            REDCap project below. For more information on REDCap API tokens, see
            the corresponding documentation{" "}
            <a
              href="https://redcap.prc.utexas.edu/redcap/api/help/?content=tokens"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </Container>
          <div style={{ height: "1rem" }} />
          <Container>
            <Button
              variant="success"
              onClick={() => setShowNewProjectModal(true)}
            >
              Add a new project
            </Button>
            <Button
              variant="warning"
              onClick={handleSubmitUpdateProjects}
              disabled={!projectChangesQueued}
            >
              Submit project changes
            </Button>
          </Container>
          <Container className={styles.settingsTableContainer}>
            <Table bordered responsive className={styles.settingsTable}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>API Token</th>
                  <th>Permission sets applied</th>
                  <th>Form ids applied</th>
                  <th>Update forms</th>
                  <th>Delete project?</th>
                </tr>
              </thead>
              <tbody>
                {projectData?.map((project, i) => (
                  <tr key={project.name}>
                    <td>
                      <Container fluid>
                        <Form.Control
                          size="sm"
                          className={styles.settingsTableTextInput}
                          type="text"
                          defaultValue={project.name}
                          onChange={(e) =>
                            handleProjectChange(
                              i,
                              project.name,
                              "name",
                              e.target.value
                            )
                          }
                        />
                      </Container>
                    </td>
                    <td>
                      <Container fluid>
                        <Form.Control
                          size="sm"
                          className={styles.settingsTableTextInput}
                          type="text"
                          defaultValue={project.token}
                          onChange={(e) =>
                            handleProjectChange(
                              i,
                              project.name,
                              "token",
                              e.target.value
                            )
                          }
                        />
                      </Container>
                    </td>
                    <td>
                      <Container fluid>
                        {permSets.map((permSet) => (
                          <Form.Check
                            key={project.name + permSet.name}
                            type="checkbox"
                            label={permSet.name}
                            defaultChecked={project.perm_sets.includes(
                              permSet.name
                            )}
                            onChange={(e) => {
                              const val = {};
                              val[permSet.name] = e.target.checked;
                              handleProjectChange(
                                i,
                                project.name,
                                "perm_sets",
                                val
                              );
                            }}
                          />
                        ))}
                      </Container>
                    </td>
                    <td>
                      {project.forms.map((form) => (
                        <div key={project.name + form}>
                          {form}
                          <br />
                        </div>
                      ))}
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleSubmitUpdateForms(project.name)}
                      >
                        Update forms
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleClickDeleteProject(project.name)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Container>
      </Container>
      {/* MODAL: new project */}
      <Modal show={showNewProjectModal} onHide={handleHideNewProjectModal}>
        <Modal.Header closeButton>Add a new project</Modal.Header>
        <Modal.Body>
          <Container>
            {newProjectError && (
              <>
                <Container>
                  <Container className={styles.errorContainer}>
                    {newProjectErrorText}
                  </Container>
                </Container>
                <div style={{ height: "2vh" }} />
              </>
            )}
            <Container>
              <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewProjectName(e.target.value)}
                />
              </Form.Group>
            </Container>
            <div style={{ height: "2vh" }} />
            <Container>
              <Form.Group>
                <Form.Label>API Token</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewProjectToken(e.target.value)}
                />
              </Form.Group>
            </Container>
            <div style={{ height: "2vh" }} />
            <Container>
              <Form.Group>
                <Form.Label>Use permission sets</Form.Label>

                {permSets.map((permSet) => (
                  <Form.Check
                    key={"newproject" + permSet.name}
                    type="checkbox"
                    label={permSet.name}
                    onChange={(e) =>
                      handleEditNewProjectPermSets(e, permSet.name)
                    }
                  />
                ))}
              </Form.Group>
            </Container>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitCreateProject}>Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* MODAL: update projects */}
      <Modal
        show={showUpdateProjectsModal}
        onHide={() => setShowUpdateProjectsModal(false)}
      >
        <Modal.Header closeButton>
          <b>Confirm update projects</b>
        </Modal.Header>
        <Modal.Body>
          {updateProjectsError && (
            <>
              <Container>
                <Container className={styles.errorContainer}>
                  <p>
                    There was an error attempting to update the projects. Please
                    try again later.
                  </p>
                </Container>
              </Container>
              <div style={{ height: "2vh" }} />
            </>
          )}
          <Container>
            Changes will be made to the following projects:{" "}
            {Object.keys(projectChanges).map((projName) => (
              <Container key={"changes" + projName}>
                <b>{projName}</b>
              </Container>
            ))}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleConfirmUpdateProjects}>Confirm</Button>
        </Modal.Footer>
      </Modal>
      {/* MODAL: delete project */}
      <Modal
        show={showDeleteProjectModal}
        onHide={handleHideDeleteProjectModal}
      >
        <Modal.Header closeButton>Delete project</Modal.Header>
        <Modal.Body>
          {deleteProjectError && (
            <>
              <Container>
                <Container className={styles.errorContainer}>
                  <p>
                    There was an error attempting to delete{" "}
                    <b>{deleteProjectName}</b>. Please try again later.
                  </p>
                </Container>
              </Container>
              <div style={{ height: "2vh" }} />
            </>
          )}
          <Container>
            <p>
              Are you sure you want to delete project:
              <br />
              <b>{deleteProjectName}</b>?
            </p>
            <p>Please confirm before continuing.</p>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleSubmitDeleteProject}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL: new permission set */}
      <Modal show={showNewPermModal} onHide={handleHideNewPermModal}>
        <Modal.Header closeButton>Add a new permission set</Modal.Header>
        <Modal.Body>
          <Container>
            {newPermError && (
              <>
                <Container>
                  <Container className={styles.errorContainer}>
                    {newPermErrorText}
                  </Container>
                </Container>
                <div style={{ height: "2vh" }} />
              </>
            )}
            <Container>
              <Form.Group>
                <Form.Label>Permission Set Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewPermSetName(e.target.value)}
                />
              </Form.Group>
            </Container>
            <div style={{ height: "2vh" }} />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitNewPermSet}>Submit</Button>
        </Modal.Footer>
      </Modal>
      {/* MODAL: update permsets */}
      <Modal
        show={showUpdatePermSetsModal}
        onHide={() => setShowUpdatePermSetsModal(false)}
      >
        <Modal.Header closeButton>
          <b>Confirm update permission sets</b>
        </Modal.Header>
        <Modal.Body>
          {updatePermError && (
            <>
              <Container>
                <Container className={styles.errorContainer}>
                  <p>
                    There was an error attempting to update the permission sets.
                    Please try again later.
                  </p>
                </Container>
              </Container>
              <div style={{ height: "2vh" }} />
            </>
          )}
          <Container>
            Changes will be made to the following permission sets:{" "}
            {Object.keys(permSetChanges).map((permName) => (
              <Container key={"changes" + permName}>
                <b>{permName}</b>
              </Container>
            ))}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleConfirmUpdatePerms}>Confirm</Button>
        </Modal.Footer>
      </Modal>
      {/* MODAL: delete perm */}
      <Modal show={showDeletePermModal} onHide={handleHideDeletePermModal}>
        <Modal.Header closeButton>Delete permission set</Modal.Header>
        <Modal.Body>
          {deletePermError && (
            <>
              <Container>
                <Container className={styles.errorContainer}>
                  <p>
                    There was an error attempting to delete{" "}
                    <b>{deletePermName}</b>. Please try again later.
                  </p>
                </Container>
              </Container>
              <div style={{ height: "2vh" }} />
            </>
          )}
          <Container>
            <p>
              Are you sure you want to delete permission set:
              <br />
              <b>{deletePermName}</b>?
            </p>
            <p>Please confirm before continuing.</p>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleSubmitDeletePerm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Settings;
