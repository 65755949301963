// REACT
import React, { useState, useEffect } from "react";
// import { useCookies } from "react-cookie"

// bootstrap imports
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";

import { AiOutlinePlus } from "react-icons/ai";
import { IoTrashBin } from "react-icons/io5";

import styles from "./styles/Main.module.css";

// utils
import { refreshPage } from "../../utils/components";

// getting decode for userData
// import { userIsAdmin } from "../../utils/token" 

// constants
import { UserPermOptions } from "../../constants/redcapperms"; //  necessary to get the delete item

// API
import { sendPerms, getAllPermSets } from "../../api/RedcapPerms";

const Dashboard = () => {
  // const [allowChangeAccess, setAllowChangeAccess] = useState(false);
  // const cookies = useCookies(["token"]);

  // static method - returns a new user entry corresponding to a row
  const getNewUserEmpty = () => {
    return {
      uteid: "",
      perm: "Default",
    };
  };

  // ========================
  //  Hooks
  // ========================

  const [userDataMap, setUserDataMap] = useState({ 0: getNewUserEmpty() });

  const [dataToSend, setDataToSend] = useState();
  const [requestQueued, setRequestQueued] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [showModalClose, setShowModalClose] = useState(true);

  const [tableRowMap, setTableRowMap] = useState({}); // keeps track of display components for adding/removing
  const [tableRowsToDisplay, setTableRowsToDisplay] = useState([]); // actual display components
  const [tableRowCounter, setTableRowCounter] = useState(0); // use to keep track of all new rows created (only increment)

  const [permOptions, setPermOptions] = useState([]);

  const handleRowEnterUteid = (e, id) => {
    const uteid = e.target.value.trim();
    if (uteid !== "") {
      // update the data object
      const currNewUserData = userDataMap;
      currNewUserData[id].uteid = uteid;
      setUserDataMap(currNewUserData);
    }
  };

  const handleRowChangePerm = (e, id) => {
    // update the data object
    const currNewUserData = userDataMap;
    currNewUserData[id].perm = e.target.value;
    setUserDataMap(currNewUserData);
  };

  const handleAddRow = () => {
    const lastRowMap = tableRowMap;
    const counter = tableRowCounter;

    const newRow = (
      <EmptyRow key={"row" + counter} id={counter} perms={permOptions} />
    );
    lastRowMap[counter] = newRow;
    setTableRowMap(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplay(newRowsToDisplay);

    const currNewUserData = userDataMap;
    currNewUserData[counter] = getNewUserEmpty();
    setUserDataMap(currNewUserData);

    setTableRowCounter(counter + 1);
  };

  const handleRemoveRow = (id) => {
    // remove entries in newUserData
    const lastRowMap = tableRowMap;
    const lastNewData = userDataMap;
    delete lastNewData[id];
    setUserDataMap(lastNewData);

    // remove entry in tableRowMap
    delete lastRowMap[id];
    setTableRowMap(lastRowMap);

    const newRowsToDisplay = Object.values(lastRowMap);
    setTableRowsToDisplay(newRowsToDisplay);
  };

  const EmptyRow = (props) => (
    <tr key={props.id}>
      <td>
        <div>
          <Form.Control
            type="text"
            onChange={(e) => handleRowEnterUteid(e, props.id)}
          />
        </div>
      </td>
      <td>
        <div>
          <Form.Select onChange={(e) => handleRowChangePerm(e, props.id)}>
            {Object.values(props.perms).map((opt) => (
              <option key={opt}>{opt}</option>
            ))}
          </Form.Select>
        </div>
      </td>
      <td>
        {!props.first && (
          <div>
            <Button
              variant="warning"
              size="sm"
              onClick={() => handleRemoveRow(props.id)}
            >
              <IoTrashBin />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );

  // ========================
  //  useEffect callbacks
  // ========================

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {

    // Not sure why you have to access the first cookie to get
    // the correct response but it works
    // setAllowChangeAccess(userIsAdmin(cookies[0]["token"]));
    console.log("allow change access has been set to:");
    // console.log(allowChangeAccess);

    // call the backend to get the list of permission options
    getAllPermSets()
      .then((res) => {
        const newOptions = res.data.map((it) => it.name);
        newOptions.push(UserPermOptions.DELETE);
        setPermOptions(newOptions);

        // set the table row map, row counter, and user data map
        setTableRowCounter(1);
        const newRowMap = {
          0: <EmptyRow key={"row0"} id={0} first perms={newOptions} />,
        };
        setTableRowMap(newRowMap);
        const newRowsToDisplay = Object.values(newRowMap);
        setTableRowsToDisplay(newRowsToDisplay);
      })
      .catch((err) => console.log(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ========================
  //  Button handlers
  // ========================

  const handleSubmit = () => {
    // create and set the modal body
    // and json data to send to backend
    const bodyData = {};

    for (const option of permOptions) {
      bodyData[option] = [];
    }

    for (const userData of Object.values(userDataMap)) {
      const uteid = userData.uteid;

      // handle - interrupt assembly of modal body if any entries for uteid are left empty
      if (uteid === "") {
        setModalHeader("Could not verify entry updates.");
        setModalBody(
          "Could not verify entry updates. Please make sure all entered data are correct."
        );
        setModalBtnText("Close");
        setModalShow(true);
        return;
      }

      // add to data map
      bodyData[userData.perm].push(uteid);
    }

    setDataToSend(bodyData);

    // set the modal body
    const newModalBody = [
      <h5 key="modal-header" style={{ textDecorationLine: "underline" }}>
        Review your changes before submitting:
      </h5>,
    ];

    for (const [perm, userList] of Object.entries(bodyData)) {
      if (userList.length > 0) {
        newModalBody.push(<h5 key={"header-" + perm}>{perm} Updates:</h5>);
        newModalBody.push(
          <h6 key={"data" - perm}>
            <ListGroup>
              {userList.map((uteid) => (
                <ListGroup.Item key={perm + "-" + uteid}>
                  {uteid}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </h6>
        );
      }
    }

    // set the modal header
    setModalHeader("Confirm entries");
    setModalBody(newModalBody);
    setModalBtnText("Confirm");
    // set modal showing
    setModalShow(true);
    setRequestQueued(true);
  };

  const handleConfirm = () => {
    setModalHeader("Processing");
    setModalBody(
      <Container>
        <p>
          Your request is being processed. Please wait while permissions are
          being updated. Do not navigate away from this page.
        </p>
      </Container>
    );
    setShowModalClose(false);
    // parse the data received
    // make the API call
    sendPerms(dataToSend)
      .then((res) => {
        if (res.data.error) {
          const errList = res.data.error;
          const newModalBody = errList.map((item) => {
            if (item.errorAction === "access") {
              return (
                <p>
                  Error updating User Rights:
                  <br />
                  {item.message}
                </p>
              );
            } else if (item.errorAction === "add") {
              // handle add errors
              return (
                <p>
                  Error adding to project: {item.projectName}
                  <br />
                  <i>Message from server: {item.message}</i>
                </p>
              );
            } else if (item.errorAction === "delete") {
              // handle delete errors
              return (
                <p>
                  Error deleting <b>{item.userAttempted}</b> from Project:{" "}
                  {item.projectName}
                  <br />
                  <i>Message from server: {item.message}</i>
                </p>
              );
            } else {
              return <p>Error</p>;
            }
          });
          setModalHeader("Error");
          setModalBody(newModalBody);
          setModalBtnText("Close");
          setModalShow(true);
          setShowModalClose(true);
        } else if (res.data) {
          setModalHeader("Success");
          setModalBody(
            <Container>
              <p>
                User permissions have been successfully updated for all REDCap
                projects in the database.
              </p>
              <p>This page will now refresh in five (5) seconds.</p>
            </Container>
          );
          setModalBtnText("Close");
          setModalShow(true);
          setTimeout(refreshPage, 5000);
          setShowModalClose(true);
        } else {
          setModalBtnText("Close");
          setModalShow(true);
          // setTimeout(refreshPage, 5000);
          setShowModalClose(true);
        }
      })
      .catch((err) => {
        setModalHeader("Error");
        setModalBody(err.message);
        setModalBtnText("Close");
        setModalShow(true);
      });

    setRequestQueued(false);
    // setModalShow(false);
  };

  return (
    <div>
      <Container className={styles.container}>
        <Container className={styles.viewTitle}>
          <h1>REDCap Permissions: Dashboard</h1>
        </Container>
        <Container className={styles.functionContainer}>
          <h4>Send REDCap permission updates</h4>
          <Table striped bordered>
            <thead>
              <tr>
                <th>UT EID</th>
                <th colSpan={2}>Permission set</th>
              </tr>
            </thead>
            <tbody>{tableRowsToDisplay}</tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <div>
                    <Button
                      size="sm"
                      variant="dark"
                      className={styles.addRowBtn}
                      onClick={handleAddRow}
                    >
                      <AiOutlinePlus />
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
          <Button 
            variant="primary" 
            onClick={handleSubmit}
            // disabled={!allowChangeAccess}
          >
            Submit
          </Button>
        </Container>
      </Container>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop={showModalClose ? undefined : "static"}
      >
        <Modal.Header closeButton={showModalClose}>
          <Modal.Title>{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        {showModalClose && (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={
                requestQueued ? handleConfirm : () => setModalShow(false)
              }
            >
              {modalBtnText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;
