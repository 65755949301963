export const AppNames = {
  REDCAP_PERMS: "redcapperms",
  SCHEDULER: "scheduler",
};

export const AppTitles = {
  REDCAP_PERMS: "REDCap Permissions",
  SCHEDULER: "Seeking Training",
  SCHEDULING_TAB: "Scheduling",
  PERSONAL_TAB: "Profile",
  TRAINER_SCHEDULER: "Trainer Availability",
  SCHEDULER_BYPASS: "Scheduler Submission",
  ADMIN: "Admin",
  PROFILE: "Profile",
  LANGUAGE: "Trainer Language",
  HUB: "Central Hub",
  VOLUNTEERHUB: "Volunteer Hub",
  CHECKLIST: "Training Checklist",
  NEEDTOKNOW: "Need to Know",
  EXISTINGTRAINING: "Existing Trainings"
};

export const AppAccess = {
  DEFAULT: "Default",
  ADMIN: "Admin",
};

export const AppActions = {
  redcapPerms: {
    UPDATE_PROJECTS: "Updated projects",
    UPDATE_PROJECT_FORMS: "Updated project forms",
    UPDATE_REDCAP_PERMS: "Updated permissions for UT EIDs on REDCap",
    ADD_PROJECT: "Added project to database",
    DELETE_PROJECT: "Deleted project from database",
    ADD_PERM_SET: "Added permission set to database",
    DELETE_PERM_SET: "Deleted permission set from database",
    UPDATE_PERM_SETS: "Updated permission sets",
  },
  scheduler: {
    UPLOAD_AVAILABILITY: "uploaded availability",
    SCHEDULED: "scheduled",
    CONFIRMED: "confirmed event",
  },
};

export const AdminAccess = {
  DEFAULT: "N/A",
  ADMIN: "Admin",
};


export const LangOptions = {
  ENGL: "English",
  SPAN: "Spanish",
};