import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

// bootstrap imports
import Container from "react-bootstrap/Container";

// app-specific
import Table from "../components/Table";
import NavBar from "../components/NavBar";
import { NavigateRoutes } from "../constants/routes";

import { authorize } from "../api/Login";

// utils
import { 
  tokenIsValid,
  decode, 
  getTokenExpirationDate 
} from "../utils/token";

// import { 
//   getTraineeLogs,
//   getTrainerLogs,
//   getConfirmedTrainings
// } from "../api/Hub.js"

import { 
    getRAType,
    getCompleted,
  } from "../api/Checklist.js"


// custom page styling
import styles from "../styles/Admin.module.css";

/**
 * Page component for displaying the Admin page
 * @returns React.FC
 */
const ChecklistPage = () => {  
  // state - get user info
  const [isFieldRA, setIsFieldRA] = useState(0);

  const [completedTraining, setCompletedTraining] = useState([]);

  // state - track appropriate variables for whether the user is able to access this page
  const [authorized, setAuthorized] = useState();
  const [reauthorized, setReauthorized] = useState(false);
  const [pageDoneLoading, setPageDoneLoading] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(); // stores the timeout to navigate away when token expires
  const [authTimeout, setAuthTimeout] = useState(); // stores the timeout based on the token

  // navigate hook
  const navigate = useNavigate();

  // cookies hook
  const [cookies, setCookie] = useCookies(["token", "firebaseToken"]);

  // alias for redirect back to login page
  const redirectLogin = useCallback(
    () => {
      navigate(NavigateRoutes.LOGIN + "?redirect=HOME");},
    [navigate]
  );

  // Check that server-granted token exists and is not expired
  // and that the user has access to this specific app
  // update every time an input is entered
  useEffect(() => {
    document.title = "Project SEED Apps: Admin Panel";

    if (!cookies["token"]) {
        // redirect to the login page
        setAuthorized(false);
    } else {
        authorize(cookies["firebaseToken"]).then((res) => {
          setCookie("token", res.data.token);
          setReauthorized(true);
        });
    }

    // unmount callback
    return () => {
      // clear timeouts if user navigates away
      clearTimeout(logoutTimeout);
      clearTimeout(authTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reauthorized) {
      // parse out the token from the server
      const auth = tokenIsValid(cookies["token"]);
      setAuthorized(auth);

      const decoded = decode(cookies["token"]);

      // get the trainee logs
      getRAType(decoded)
      .then((res) => {
        const logs = res.data;
        setIsFieldRA(logs);
      })
      .catch((err) => console.log(err));
      
      getCompleted(decoded)
      .then((res) => {
        const logs = res.data;
        setCompletedTraining(logs);
      })
      .catch((err) => console.log(err));

      // set the page timeout
      const exp = getTokenExpirationDate(cookies["token"]);
      const now = new Date();
      const newTimeout = setTimeout(
          () => setAuthorized(false),
          exp - now - 10 * 1000
      );

      setAuthTimeout(newTimeout);

      setPageDoneLoading(true);
    }

  }, [cookies, reauthorized]);

  // Starts the timeout to redirect back to the login page
  useEffect(() => {
    if (!authorized && pageDoneLoading) {
      const newTimeout = setTimeout(() => redirectLogin(), 5000);
      setLogoutTimeout(newTimeout);
    }
  }, [authorized, pageDoneLoading, redirectLogin]);

  // =================================
  // -- Button handlers --
  // =================================

  /**
   * Handler for deleting a single user, shows modal to prompt user to confirm before proceeding
   * @param {object} user
   */

  /**
   * Assembles a table row with logged history of a REDCap Permissions submit request
   * @param {*} props
   * @returns Customized <tr /> component
   */

  const CheckListRow = (props) => {
    const training = props.training;
    const status = props.status;
    return (
      <tr key={props.id}>
        {/* <td>{record.timestamp}</td> */}
        <td> {training} </td>
        {
          status && (
            <td> <b>COMPLETED</b></td>
          )
        }
        {
          !status && (
            <td> <b>NOT COMPLETED</b></td>
          )
        }
        
      </tr>
    );
  };

  const CheckLists = (props) => {
    const item = props.info;

    var to_return = [];

    for (const property in item) {
      var title = ( 
      <Container className={styles.sectionContainer}>
        <Table
          tableName={property + " Information"}
          columns={[
            "Training Type",
            "Status"
          ]}
          rows={Object.keys(item[property]).map((key, index) => (
            <CheckListRow
              training={key}
              status={item[property][key]}
              id={key + "ROW"}
            />
          ))}
        />
      </Container>);
      to_return.push(title);
    }

    return to_return;
  };

  // return for the page
  return (
    <div>
      <NavBar />
      <Container className={styles.pageContainer}>
        <Container>
          <h1>Training Checklist</h1>
          <p>The following contains the information for which trainings you have completed and which ones you have yet to complete.</p>
          <p>All Trainings will be displayed in the order that you must complete them. (In that the first in the list will be the first required, the second the second etc).</p>
        </Container>
        
        {(isFieldRA === 0) && (
            <p>You are not on the Car no Car spreadsheet, please contact your scheduler or Dr. Kim to have you put on the sheet.</p>
        )}

        {
            (isFieldRA !== 0) && (
                <CheckLists info={completedTraining}/>
            )
        }
      </Container>
    </div>
  );
};

export default ChecklistPage;
