import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";

import { NavigateRoutes } from "../constants/routes";

import styles from "../styles/Login.module.css";

/**
 * Page component for the redirect after signup is completed
 * @returns React.FC
 */
const CompleteSignup = () => {
  const navigate = useNavigate();

  const redirectLogin = () => navigate(NavigateRoutes.LOGIN);

  setTimeout(() => redirectLogin(), 3000);

  useEffect(() => {
    document.title = "Project SEED Apps: Signup Complete";
  }, []);

  return (
    <Container className={styles.loginContainerWrapper}>
      <Container className={styles.headerContainer}>
        <h1>Signup Complete!</h1>
      </Container>
      <Container className={styles.loginContainer}>
        <div style={{ height: "1em" }} />
        <h3>
          You have successfully created your account on the UT Project SEED Apps
          Platform.
        </h3>
        <hr />
        <h3>Redirecting you to the login page now...</h3>
      </Container>
    </Container>
  );
};

export default CompleteSignup;
