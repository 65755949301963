import { AppAccess } from "../constants/apps";
import { decodeToken, isExpired } from "react-jwt";

export const tokenIsValid = (token, appToAuthorize = "") => {
  // decode the jwt
  let decoded = decodeToken(token);

  // check if token is expired
  let expired = isExpired(token);
  if (expired) return false;

  if (appToAuthorize === "") return !expired;

  if (appToAuthorize === "is_admin") return decoded.is_admin;

  return (
    (decoded[appToAuthorize] === AppAccess.ADMIN ||
      decoded[appToAuthorize] === AppAccess.DEFAULT) &&
    (!decoded.expiration || new Date(decoded.expiration * 1000) < new Date())
  );
};

export const getAppAccess = (token, app) => {
  // decode the jwt
  let decoded = decodeToken(token);

  return decoded[app];
};

export const userIsAdmin = (token) => {
  // decode the jwt
  let decoded = decodeToken(token);

  // check if token is expired
  let expired = isExpired(token);
  if (expired) return false;

  return decoded.is_admin;
};

export const getTokenExpirationDate = (token) => {
  const decoded = decodeToken(token);
  return new Date(decoded.exp * 1000);
};

export const decode = (token) => {
  const decoded = decodeToken(token);
  return decoded;
};
